.card {
	box-shadow: $px-card-box-shadow;
	> .list-group {
		border-top: none;
		border-bottom: none;
	}
    &.no-shadow {
        box-shadow: none;
    }
}
.card-header {
	border-bottom: 0;
    padding-bottom: 0;
    + .list-group {
    	.list-group-item {
    		&:first-child {
    			border-top: none;
    		}
    	}
    }
}
.list-group-item {
	font-size: $px-list-group-item-font-size;
}


// Card Overlay
.card-overlay {
    &:after {
        content:"";
        display: inline-block;
        vertical-align: top;
        padding-bottom:120%;
    }
}

//
// Card groups
//

.card-lg-group {
// The child selector allows nested `.card` within `.card-group`
// to display properly.
> .card {
    margin-bottom: $card-group-margin;
}
@include media-breakpoint-up(lg) {
    display: flex;
    flex-flow: row wrap;
    // The child selector allows nested `.card` within `.card-lg-group`
    // to display properly.
    > .card {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      margin-bottom: 0;

      + .card {
        margin-left: 0;
        border-left: 0;
      }

      // Handle rounded corners
      @if $enable-rounded {
        &:not(:last-child) {
          @include border-end-radius(0);

          .card-img-top,
          .card-header {
            // stylelint-disable-next-line property-blacklist
            border-top-right-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            // stylelint-disable-next-line property-blacklist
            border-bottom-right-radius: 0;
          }
        }

        &:not(:first-child) {
          @include border-start-radius(0);

          .card-img-top,
          .card-header {
            // stylelint-disable-next-line property-blacklist
            border-top-left-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            // stylelint-disable-next-line property-blacklist
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }
}


.card-md-group {
// The child selector allows nested `.card` within `.card-group`
// to display properly.
> .card {
    margin-bottom: $card-group-margin;
}
@include media-breakpoint-up(md) {
    display: flex;
    flex-flow: row wrap;
    // The child selector allows nested `.card` within `.card-lg-group`
    // to display properly.
    > .card {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      margin-bottom: 0;

      + .card {
        margin-left: 0;
        border-left: 0;
      }

      // Handle rounded corners
      @if $enable-rounded {
        &:not(:last-child) {
          @include border-end-radius(0);

          .card-img-top,
          .card-header {
            // stylelint-disable-next-line property-blacklist
            border-top-right-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            // stylelint-disable-next-line property-blacklist
            border-bottom-right-radius: 0;
          }
        }

        &:not(:first-child) {
          @include border-start-radius(0);

          .card-img-top,
          .card-header {
            // stylelint-disable-next-line property-blacklist
            border-top-left-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            // stylelint-disable-next-line property-blacklist
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }
}
