@charset "UTF-8";
@import "../css/fa-pro/css/all.css";
@import "../vendor/bootstrap/font/bootstrap-icons.css";
@import "../vendor/jarallax/jarallax.css";
@import "../vendor/magnific/magnific-popup.css";
@import "../vendor/owl-carousel/css/owl.carousel.min.css";
@import "../vendor/et-line/style.css";
@import "../fonts/fonts.css";
@import "../lib/noty/animate.css";
@import "../lib/noty/noty.css";
@import "../css/docs.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/*$px-primary: #396bea !default;
*/
/*$px-success:        #2dca8c !default;
*/
/*!
 * Bootstrap  v5.3.3 (https://getbootstrap.com/)
 * Copyright 2011-2024 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #ffffff;
  --bs-gray: #3a3a3a;
  --bs-gray-dark: #101010;
  --bs-gray-100: #f7f7ff;
  --bs-gray-200: #eff2f7;
  --bs-gray-300: #e2e8f0;
  --bs-gray-400: #cbd5e0;
  --bs-gray-500: #a0aec0;
  --bs-gray-600: #3a3a3a;
  --bs-gray-700: #333333;
  --bs-gray-800: #101010;
  --bs-gray-900: #080808;
  --bs-primary: #065D8F;
  --bs-secondary: #6c757d;
  --bs-success: #707D27;
  --bs-info: #50b5ff;
  --bs-warning: #ff9f1c;
  --bs-danger: #ff5c75;
  --bs-light: #f1f6fd;
  --bs-dark: #0f0d1d;
  --bs-primary-rgb: 6, 93, 143;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 112, 125, 39;
  --bs-info-rgb: 80, 181, 255;
  --bs-warning-rgb: 255, 159, 28;
  --bs-danger-rgb: 255, 92, 117;
  --bs-light-rgb: 241, 246, 253;
  --bs-dark-rgb: 15, 13, 29;
  --bs-primary-text-emphasis: #022539;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #2d3210;
  --bs-info-text-emphasis: #204866;
  --bs-warning-text-emphasis: #66400b;
  --bs-danger-text-emphasis: #66252f;
  --bs-light-text-emphasis: #333333;
  --bs-dark-text-emphasis: #333333;
  --bs-primary-bg-subtle: #cddfe9;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #e2e5d4;
  --bs-info-bg-subtle: #dcf0ff;
  --bs-warning-bg-subtle: #ffecd2;
  --bs-danger-bg-subtle: #ffdee3;
  --bs-light-bg-subtle: #fbfbff;
  --bs-dark-bg-subtle: #cbd5e0;
  --bs-primary-border-subtle: #9bbed2;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #c6cba9;
  --bs-info-border-subtle: #b9e1ff;
  --bs-warning-border-subtle: #ffd9a4;
  --bs-danger-border-subtle: #ffbec8;
  --bs-light-border-subtle: #eff2f7;
  --bs-dark-border-subtle: #a0aec0;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 0.93rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.7;
  --bs-body-color: #726f84;
  --bs-body-color-rgb: 114, 111, 132;
  --bs-body-bg: #ffffff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(114, 111, 132, 0.75);
  --bs-secondary-color-rgb: 114, 111, 132;
  --bs-secondary-bg: #eff2f7;
  --bs-secondary-bg-rgb: 239, 242, 247;
  --bs-tertiary-color: rgba(114, 111, 132, 0.5);
  --bs-tertiary-color-rgb: 114, 111, 132;
  --bs-tertiary-bg: #f7f7ff;
  --bs-tertiary-bg-rgb: 247, 247, 255;
  --bs-heading-color: #0f0d1d;
  --bs-link-color: #065D8F;
  --bs-link-color-rgb: 6, 93, 143;
  --bs-link-decoration: none;
  --bs-link-hover-color: #054a72;
  --bs-link-hover-color-rgb: 5, 74, 114;
  --bs-code-color: #d63384;
  --bs-highlight-color: #726f84;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #e2e8f0;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.05), 0 0.5rem 1.2rem rgba(0, 0, 0, 0.1);
  --bs-box-shadow-sm: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
  --bs-box-shadow-lg: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05), 0 1.5rem 2.2rem rgba(0, 0, 0, 0.1);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(6, 93, 143, 0.25);
  --bs-form-valid-color: #707D27;
  --bs-form-valid-border-color: #707D27;
  --bs-form-invalid-color: #ff5c75;
  --bs-form-invalid-border-color: #ff5c75;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #e2e8f0;
  --bs-body-color-rgb: 226, 232, 240;
  --bs-body-bg: #080808;
  --bs-body-bg-rgb: 8, 8, 8;
  --bs-emphasis-color: #ffffff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(226, 232, 240, 0.75);
  --bs-secondary-color-rgb: 226, 232, 240;
  --bs-secondary-bg: #101010;
  --bs-secondary-bg-rgb: 16, 16, 16;
  --bs-tertiary-color: rgba(226, 232, 240, 0.5);
  --bs-tertiary-color-rgb: 226, 232, 240;
  --bs-tertiary-bg: #0c0c0c;
  --bs-tertiary-bg-rgb: 12, 12, 12;
  --bs-primary-text-emphasis: #6a9ebc;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #a9b17d;
  --bs-info-text-emphasis: #96d3ff;
  --bs-warning-text-emphasis: #ffc577;
  --bs-danger-text-emphasis: #ff9dac;
  --bs-light-text-emphasis: #f7f7ff;
  --bs-dark-text-emphasis: #e2e8f0;
  --bs-primary-bg-subtle: #01131d;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #161908;
  --bs-info-bg-subtle: #102433;
  --bs-warning-bg-subtle: #332006;
  --bs-danger-bg-subtle: #331217;
  --bs-light-bg-subtle: #101010;
  --bs-dark-bg-subtle: #080808;
  --bs-primary-border-subtle: #043856;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #434b17;
  --bs-info-border-subtle: #306d99;
  --bs-warning-border-subtle: #995f11;
  --bs-danger-border-subtle: #993746;
  --bs-light-border-subtle: #333333;
  --bs-dark-border-subtle: #101010;
  --bs-heading-color: inherit;
  --bs-link-color: #6a9ebc;
  --bs-link-hover-color: #88b1c9;
  --bs-link-color-rgb: 106, 158, 188;
  --bs-link-hover-color-rgb: 136, 177, 201;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #e2e8f0;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #333333;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 1.3;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.3575rem + 1.29vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.325rem;
  }
}

h2, .h2 {
  font-size: calc(1.311rem + 0.732vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.86rem;
  }
}

h3, .h3 {
  font-size: calc(1.28775rem + 0.453vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.6275rem;
  }
}

h4, .h4 {
  font-size: calc(1.2645rem + 0.174vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.395rem;
  }
}

h5, .h5 {
  font-size: 1.1625rem;
}

h6, .h6 {
  font-size: 0.93rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: none;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.0695rem;
  font-weight: 400;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 600;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 600;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 600;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 600;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 600;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 600;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.display-7 {
  font-size: calc(1.325rem + 0.9vw);
  font-weight: 600;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .display-7 {
    font-size: 2rem;
  }
}

.display-8 {
  font-size: calc(1.275rem + 0.3vw);
  font-weight: 600;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .display-8 {
    font-size: 1.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.1625rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #3a3a3a;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 2rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 2rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 2.5rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 2.5rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 3rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 3rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 3.5rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 3.5rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 4rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 4rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 5rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 5rem;
}

.g-11,
.gx-11 {
  --bs-gutter-x: 6rem;
}

.g-11,
.gy-11 {
  --bs-gutter-y: 6rem;
}

.g-12,
.gx-12 {
  --bs-gutter-x: 7rem;
}

.g-12,
.gy-12 {
  --bs-gutter-y: 7rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 2rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 2rem;
  }

  .g-sm-6,
.gx-sm-6 {
    --bs-gutter-x: 2.5rem;
  }

  .g-sm-6,
.gy-sm-6 {
    --bs-gutter-y: 2.5rem;
  }

  .g-sm-7,
.gx-sm-7 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-7,
.gy-sm-7 {
    --bs-gutter-y: 3rem;
  }

  .g-sm-8,
.gx-sm-8 {
    --bs-gutter-x: 3.5rem;
  }

  .g-sm-8,
.gy-sm-8 {
    --bs-gutter-y: 3.5rem;
  }

  .g-sm-9,
.gx-sm-9 {
    --bs-gutter-x: 4rem;
  }

  .g-sm-9,
.gy-sm-9 {
    --bs-gutter-y: 4rem;
  }

  .g-sm-10,
.gx-sm-10 {
    --bs-gutter-x: 5rem;
  }

  .g-sm-10,
.gy-sm-10 {
    --bs-gutter-y: 5rem;
  }

  .g-sm-11,
.gx-sm-11 {
    --bs-gutter-x: 6rem;
  }

  .g-sm-11,
.gy-sm-11 {
    --bs-gutter-y: 6rem;
  }

  .g-sm-12,
.gx-sm-12 {
    --bs-gutter-x: 7rem;
  }

  .g-sm-12,
.gy-sm-12 {
    --bs-gutter-y: 7rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 2rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 2rem;
  }

  .g-md-6,
.gx-md-6 {
    --bs-gutter-x: 2.5rem;
  }

  .g-md-6,
.gy-md-6 {
    --bs-gutter-y: 2.5rem;
  }

  .g-md-7,
.gx-md-7 {
    --bs-gutter-x: 3rem;
  }

  .g-md-7,
.gy-md-7 {
    --bs-gutter-y: 3rem;
  }

  .g-md-8,
.gx-md-8 {
    --bs-gutter-x: 3.5rem;
  }

  .g-md-8,
.gy-md-8 {
    --bs-gutter-y: 3.5rem;
  }

  .g-md-9,
.gx-md-9 {
    --bs-gutter-x: 4rem;
  }

  .g-md-9,
.gy-md-9 {
    --bs-gutter-y: 4rem;
  }

  .g-md-10,
.gx-md-10 {
    --bs-gutter-x: 5rem;
  }

  .g-md-10,
.gy-md-10 {
    --bs-gutter-y: 5rem;
  }

  .g-md-11,
.gx-md-11 {
    --bs-gutter-x: 6rem;
  }

  .g-md-11,
.gy-md-11 {
    --bs-gutter-y: 6rem;
  }

  .g-md-12,
.gx-md-12 {
    --bs-gutter-x: 7rem;
  }

  .g-md-12,
.gy-md-12 {
    --bs-gutter-y: 7rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 2rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 2rem;
  }

  .g-lg-6,
.gx-lg-6 {
    --bs-gutter-x: 2.5rem;
  }

  .g-lg-6,
.gy-lg-6 {
    --bs-gutter-y: 2.5rem;
  }

  .g-lg-7,
.gx-lg-7 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-7,
.gy-lg-7 {
    --bs-gutter-y: 3rem;
  }

  .g-lg-8,
.gx-lg-8 {
    --bs-gutter-x: 3.5rem;
  }

  .g-lg-8,
.gy-lg-8 {
    --bs-gutter-y: 3.5rem;
  }

  .g-lg-9,
.gx-lg-9 {
    --bs-gutter-x: 4rem;
  }

  .g-lg-9,
.gy-lg-9 {
    --bs-gutter-y: 4rem;
  }

  .g-lg-10,
.gx-lg-10 {
    --bs-gutter-x: 5rem;
  }

  .g-lg-10,
.gy-lg-10 {
    --bs-gutter-y: 5rem;
  }

  .g-lg-11,
.gx-lg-11 {
    --bs-gutter-x: 6rem;
  }

  .g-lg-11,
.gy-lg-11 {
    --bs-gutter-y: 6rem;
  }

  .g-lg-12,
.gx-lg-12 {
    --bs-gutter-x: 7rem;
  }

  .g-lg-12,
.gy-lg-12 {
    --bs-gutter-y: 7rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 2rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 2rem;
  }

  .g-xl-6,
.gx-xl-6 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xl-6,
.gy-xl-6 {
    --bs-gutter-y: 2.5rem;
  }

  .g-xl-7,
.gx-xl-7 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-7,
.gy-xl-7 {
    --bs-gutter-y: 3rem;
  }

  .g-xl-8,
.gx-xl-8 {
    --bs-gutter-x: 3.5rem;
  }

  .g-xl-8,
.gy-xl-8 {
    --bs-gutter-y: 3.5rem;
  }

  .g-xl-9,
.gx-xl-9 {
    --bs-gutter-x: 4rem;
  }

  .g-xl-9,
.gy-xl-9 {
    --bs-gutter-y: 4rem;
  }

  .g-xl-10,
.gx-xl-10 {
    --bs-gutter-x: 5rem;
  }

  .g-xl-10,
.gy-xl-10 {
    --bs-gutter-y: 5rem;
  }

  .g-xl-11,
.gx-xl-11 {
    --bs-gutter-x: 6rem;
  }

  .g-xl-11,
.gy-xl-11 {
    --bs-gutter-y: 6rem;
  }

  .g-xl-12,
.gx-xl-12 {
    --bs-gutter-x: 7rem;
  }

  .g-xl-12,
.gy-xl-12 {
    --bs-gutter-y: 7rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 2rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 2rem;
  }

  .g-xxl-6,
.gx-xxl-6 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xxl-6,
.gy-xxl-6 {
    --bs-gutter-y: 2.5rem;
  }

  .g-xxl-7,
.gx-xxl-7 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-7,
.gy-xxl-7 {
    --bs-gutter-y: 3rem;
  }

  .g-xxl-8,
.gx-xxl-8 {
    --bs-gutter-x: 3.5rem;
  }

  .g-xxl-8,
.gy-xxl-8 {
    --bs-gutter-y: 3.5rem;
  }

  .g-xxl-9,
.gx-xxl-9 {
    --bs-gutter-x: 4rem;
  }

  .g-xxl-9,
.gy-xxl-9 {
    --bs-gutter-y: 4rem;
  }

  .g-xxl-10,
.gx-xxl-10 {
    --bs-gutter-x: 5rem;
  }

  .g-xxl-10,
.gy-xxl-10 {
    --bs-gutter-y: 5rem;
  }

  .g-xxl-11,
.gx-xxl-11 {
    --bs-gutter-x: 6rem;
  }

  .g-xxl-11,
.gy-xxl-11 {
    --bs-gutter-y: 6rem;
  }

  .g-xxl-12,
.gx-xxl-12 {
    --bs-gutter-x: 7rem;
  }

  .g-xxl-12,
.gy-xxl-12 {
    --bs-gutter-y: 7rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cddfe9;
  --bs-table-border-color: #a4b2ba;
  --bs-table-striped-bg: #c3d4dd;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b9c9d2;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #beced8;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #b5b6b7;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #e2e5d4;
  --bs-table-border-color: #b5b7aa;
  --bs-table-striped-bg: #d7dac9;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbcebf;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d4c4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #dcf0ff;
  --bs-table-border-color: #b0c0cc;
  --bs-table-striped-bg: #d1e4f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c6d8e6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ccdeec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #ffecd2;
  --bs-table-border-color: #ccbda8;
  --bs-table-striped-bg: #f2e0c8;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6d4bd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecdac2;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #ffdee3;
  --bs-table-border-color: #ccb2b6;
  --bs-table-striped-bg: #f2d3d8;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6c8cc;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #eccdd2;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f1f6fd;
  --bs-table-border-color: #c1c5ca;
  --bs-table-striped-bg: #e5eaf0;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d9dde4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #dfe4ea;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #ffffff;
  --bs-table-bg: #0f0d1d;
  --bs-table-border-color: #3f3d4a;
  --bs-table-striped-bg: #1b1928;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #272534;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #211f2e;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.65rem + var(--bs-border-width));
  padding-bottom: calc(0.65rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.7;
}

.col-form-label-lg {
  padding-top: calc(1rem + var(--bs-border-width));
  padding-bottom: calc(1rem + var(--bs-border-width));
  font-size: 1rem;
}

.col-form-label-sm {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 0.81375rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.65rem 1.25rem;
  font-size: 0.93rem;
  font-weight: 400;
  line-height: 1.7;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #83aec7;
  outline: 0;
  box-shadow: 0 0 0 0 transparent;
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.7em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.65rem 1.25rem;
  margin: -0.65rem -1.25rem;
  margin-inline-end: 1.25rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.65rem 0;
  margin-bottom: 0;
  line-height: 1.7;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.7em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1.25rem;
  font-size: 0.81375rem;
  border-radius: var(--bs-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.5rem 1.25rem;
  margin: -0.5rem -1.25rem;
  margin-inline-end: 1.25rem;
}

.form-control-lg {
  min-height: calc(1.7em + 2rem + calc(var(--bs-border-width) * 2));
  padding: 1rem 1.875rem;
  font-size: 1rem;
  border-radius: var(--bs-border-radius-lg);
}
.form-control-lg::file-selector-button {
  padding: 1rem 1.875rem;
  margin: -1rem -1.875rem;
  margin-inline-end: 1.875rem;
}

textarea.form-control {
  min-height: calc(1.7em + 1.3rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.7em + 1rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.7em + 2rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.7em + 1.3rem + calc(var(--bs-border-width) * 2));
  padding: 0.65rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.7em + 1rem + calc(var(--bs-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.7em + 2rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23101010' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.65rem 3.75rem 0.65rem 1.25rem;
  font-size: 0.93rem;
  font-weight: 400;
  line-height: 1.7;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 1.25rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #83aec7;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(6, 93, 143, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1.25rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.25rem;
  font-size: 0.81375rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.875rem;
  font-size: 1rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23e2e8f0' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.581rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.35em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #83aec7;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(6, 93, 143, 0.25);
}
.form-check-input:checked {
  background-color: #065D8F;
  border-color: #065D8F;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #065D8F;
  border-color: #065D8F;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2383aec7'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0 transparent;
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0 transparent;
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #065D8F;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b4cedd;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #065D8F;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b4cedd;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 1.25rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 1.25rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.625rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #3a3a3a;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.65rem 1.25rem;
  font-size: 0.93rem;
  font-weight: 400;
  line-height: 1.7;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 1rem 1.875rem;
  font-size: 1rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.5rem 1.25rem;
  font-size: 0.81375rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 5rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.81375rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.7em + 1.3rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23707D27' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.425em + 0.325rem) center;
  background-size: calc(0.85em + 0.65rem) calc(0.85em + 0.65rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.7em + 1.3rem);
  background-position: top calc(0.425em + 0.325rem) right calc(0.425em + 0.325rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23707D27' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 6.875rem;
  background-position: right 1.25rem center, center right 3.75rem;
  background-size: 16px 12px, calc(0.85em + 0.65rem) calc(0.85em + 0.65rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.7em + 1.3rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.81375rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.7em + 1.3rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff5c75'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff5c75' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.425em + 0.325rem) center;
  background-size: calc(0.85em + 0.65rem) calc(0.85em + 0.65rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.7em + 1.3rem);
  background-position: top calc(0.425em + 0.325rem) right calc(0.425em + 0.325rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff5c75'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff5c75' stroke='none'/%3e%3c/svg%3e");
  padding-right: 6.875rem;
  background-position: right 1.25rem center, center right 3.75rem;
  background-size: 16px 12px, calc(0.85em + 0.65rem) calc(0.85em + 0.65rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.7em + 1.3rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 1.25rem;
  --bs-btn-padding-y: 0.65rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.93rem;
  --bs-btn-font-weight: 500;
  --bs-btn-line-height: 1.7;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked:focus-visible + .btn {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #065D8F;
  --bs-btn-border-color: #065D8F;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #054f7a;
  --bs-btn-hover-border-color: #054a72;
  --bs-btn-focus-shadow-rgb: 43, 117, 160;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #054a72;
  --bs-btn-active-border-color: #05466b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #065D8F;
  --bs-btn-disabled-border-color: #065D8F;
}

.btn-secondary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #707D27;
  --bs-btn-border-color: #707D27;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #5f6a21;
  --bs-btn-hover-border-color: #5a641f;
  --bs-btn-focus-shadow-rgb: 133, 145, 71;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #5a641f;
  --bs-btn-active-border-color: #545e1d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #707D27;
  --bs-btn-disabled-border-color: #707D27;
}

.btn-info {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #50b5ff;
  --bs-btn-border-color: #50b5ff;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #449ad9;
  --bs-btn-hover-border-color: #4091cc;
  --bs-btn-focus-shadow-rgb: 106, 192, 255;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #4091cc;
  --bs-btn-active-border-color: #3c88bf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #50b5ff;
  --bs-btn-disabled-border-color: #50b5ff;
}

.btn-warning {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #ff9f1c;
  --bs-btn-border-color: #ff9f1c;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #d98718;
  --bs-btn-hover-border-color: #cc7f16;
  --bs-btn-focus-shadow-rgb: 255, 173, 62;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #cc7f16;
  --bs-btn-active-border-color: #bf7715;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #ff9f1c;
  --bs-btn-disabled-border-color: #ff9f1c;
}

.btn-danger {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #ff5c75;
  --bs-btn-border-color: #ff5c75;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #d94e63;
  --bs-btn-hover-border-color: #cc4a5e;
  --bs-btn-focus-shadow-rgb: 255, 116, 138;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #cc4a5e;
  --bs-btn-active-border-color: #bf4558;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #ff5c75;
  --bs-btn-disabled-border-color: #ff5c75;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f1f6fd;
  --bs-btn-border-color: #f1f6fd;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #cdd1d7;
  --bs-btn-hover-border-color: #c1c5ca;
  --bs-btn-focus-shadow-rgb: 205, 209, 215;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c1c5ca;
  --bs-btn-active-border-color: #b5b9be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f1f6fd;
  --bs-btn-disabled-border-color: #f1f6fd;
}

.btn-dark {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #0f0d1d;
  --bs-btn-border-color: #0f0d1d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #33313f;
  --bs-btn-hover-border-color: #272534;
  --bs-btn-focus-shadow-rgb: 51, 49, 63;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #3f3d4a;
  --bs-btn-active-border-color: #272534;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #0f0d1d;
  --bs-btn-disabled-border-color: #0f0d1d;
}

.btn-outline-primary {
  --bs-btn-color: #065D8F;
  --bs-btn-border-color: #065D8F;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #065D8F;
  --bs-btn-hover-border-color: #065D8F;
  --bs-btn-focus-shadow-rgb: 6, 93, 143;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #065D8F;
  --bs-btn-active-border-color: #065D8F;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #065D8F;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #065D8F;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #707D27;
  --bs-btn-border-color: #707D27;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #707D27;
  --bs-btn-hover-border-color: #707D27;
  --bs-btn-focus-shadow-rgb: 112, 125, 39;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #707D27;
  --bs-btn-active-border-color: #707D27;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #707D27;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #707D27;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #50b5ff;
  --bs-btn-border-color: #50b5ff;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #50b5ff;
  --bs-btn-hover-border-color: #50b5ff;
  --bs-btn-focus-shadow-rgb: 80, 181, 255;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #50b5ff;
  --bs-btn-active-border-color: #50b5ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #50b5ff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #50b5ff;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ff9f1c;
  --bs-btn-border-color: #ff9f1c;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #ff9f1c;
  --bs-btn-hover-border-color: #ff9f1c;
  --bs-btn-focus-shadow-rgb: 255, 159, 28;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #ff9f1c;
  --bs-btn-active-border-color: #ff9f1c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ff9f1c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ff9f1c;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #ff5c75;
  --bs-btn-border-color: #ff5c75;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #ff5c75;
  --bs-btn-hover-border-color: #ff5c75;
  --bs-btn-focus-shadow-rgb: 255, 92, 117;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #ff5c75;
  --bs-btn-active-border-color: #ff5c75;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ff5c75;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ff5c75;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f1f6fd;
  --bs-btn-border-color: #f1f6fd;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f1f6fd;
  --bs-btn-hover-border-color: #f1f6fd;
  --bs-btn-focus-shadow-rgb: 241, 246, 253;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f1f6fd;
  --bs-btn-active-border-color: #f1f6fd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f1f6fd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f1f6fd;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #0f0d1d;
  --bs-btn-border-color: #0f0d1d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #0f0d1d;
  --bs-btn-hover-border-color: #0f0d1d;
  --bs-btn-focus-shadow-rgb: 15, 13, 29;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #0f0d1d;
  --bs-btn-active-border-color: #0f0d1d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0f0d1d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0f0d1d;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #3a3a3a;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 43, 117, 160;
  text-decoration: none;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 1rem;
  --bs-btn-padding-x: 1.875rem;
  --bs-btn-font-size: 1rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1.25rem;
  --bs-btn-font-size: 0.81375rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 15rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 0.93rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: 0;
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - 0);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: #0f0d1d;
  --bs-dropdown-link-hover-color: #065D8F;
  --bs-dropdown-link-hover-bg: transparent;
  --bs-dropdown-link-active-color: #065D8F;
  --bs-dropdown-link-active-bg: transparent;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.4rem;
  --bs-dropdown-header-color: #0f0d1d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.81375rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #e2e8f0;
  --bs-dropdown-bg: #101010;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #e2e8f0;
  --bs-dropdown-link-hover-color: #ffffff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #065D8F;
  --bs-dropdown-link-active-bg: transparent;
  --bs-dropdown-link-disabled-color: #a0aec0;
  --bs-dropdown-header-color: #a0aec0;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.40625rem;
  padding-left: 1.40625rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #a0aec0;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(6, 93, 143, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: #e2e8f0;
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: transparent transparent #065D8F;
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: transparent;
  --bs-nav-tabs-link-active-border-color: transparent transparent #065D8F;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #ffffff;
  --bs-nav-pills-link-active-bg: #065D8F;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0;
  --bs-navbar-color: #0f0d1d;
  --bs-navbar-hover-color: #065D8F;
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: #065D8F;
  --bs-navbar-brand-padding-y: 0.42095rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.023rem;
  --bs-navbar-brand-color: #065D8F;
  --bs-navbar-brand-hover-color: #065D8F;
  --bs-navbar-nav-link-padding-x: 0.75rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.023rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28114, 111, 132, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: #ffffff;
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-brand-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-brand-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23ffffff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23ffffff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1.75rem;
  --bs-card-spacer-x: 1.75rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: 0;
  --bs-card-border-color: #eff2f7;
  --bs-card-border-radius: 0.3rem;
  --bs-card-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
  --bs-card-inner-border-radius: 0.3rem;
  --bs-card-cap-padding-y: 0.875rem;
  --bs-card-cap-padding-x: 1.75rem;
  --bs-card-cap-bg: transparent;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: #eff2f7;
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #0f0d1d;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23726f84' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23022539' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #065D8F;
  --bs-accordion-active-bg: #ffffff;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 0.93rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type > .accordion-header .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type > .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush > .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush > .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush > .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush > .accordion-item > .accordion-header .accordion-button, .accordion-flush > .accordion-item > .accordion-header .accordion-button.collapsed {
  border-radius: 0;
}
.accordion-flush > .accordion-item > .accordion-collapse {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236a9ebc'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236a9ebc'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: transparent;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #3a3a3a;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 0.93rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(6, 93, 143, 0.25);
  --bs-pagination-active-color: #ffffff;
  --bs-pagination-active-bg: #065D8F;
  --bs-pagination-active-border-color: #065D8F;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.023rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.81375rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #ffffff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.6975rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #ffffff;
  --bs-progress-bar-bg: #065D8F;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: #eff2f7;
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: #a0aec0;
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #0f0d1d;
  --bs-list-group-active-bg: #f7f7ff;
  --bs-list-group-active-border-color: #eff2f7;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(6, 93, 143, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.7;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow);
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
.modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
.modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
.modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
.modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
.modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.81375rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.81375rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 0.93rem;
  --bs-popover-header-color: #0f0d1d;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #ffffff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")*/;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")*/;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #ffffff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.7;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-offcanvas-padding-y)) calc(-0.5 * var(--bs-offcanvas-padding-x)) calc(-0.5 * var(--bs-offcanvas-padding-y)) auto;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(5, 74, 114, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(5, 74, 114, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(90, 100, 31, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(90, 100, 31, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(64, 145, 204, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(64, 145, 204, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(204, 127, 22, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(204, 127, 22, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(204, 74, 94, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(204, 74, 94, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(244, 248, 253, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(244, 248, 253, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(12, 10, 23, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(12, 10, 23, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-0 {
  border-width: 0 !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-6 {
  border-width: 6 !important;
}

.border-7 {
  border-width: 7px !important;
}

.border-8 {
  border-width: 8px !important;
}

.border-9 {
  border-width: 9px !important;
}

.border-10 {
  border-width: 10px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.m-6 {
  margin: 2.5rem !important;
}

.m-7 {
  margin: 3rem !important;
}

.m-8 {
  margin: 3.5rem !important;
}

.m-9 {
  margin: 4rem !important;
}

.m-10 {
  margin: 5rem !important;
}

.m-11 {
  margin: 6rem !important;
}

.m-12 {
  margin: 7rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-6 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-7 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-8 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}

.mx-9 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-10 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-11 {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}

.mx-12 {
  margin-right: 7rem !important;
  margin-left: 7rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-6 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-7 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-8 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-9 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-10 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-11 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-12 {
  margin-top: 7rem !important;
  margin-bottom: 7rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 2rem !important;
}

.mt-6 {
  margin-top: 2.5rem !important;
}

.mt-7 {
  margin-top: 3rem !important;
}

.mt-8 {
  margin-top: 3.5rem !important;
}

.mt-9 {
  margin-top: 4rem !important;
}

.mt-10 {
  margin-top: 5rem !important;
}

.mt-11 {
  margin-top: 6rem !important;
}

.mt-12 {
  margin-top: 7rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 2rem !important;
}

.me-6 {
  margin-right: 2.5rem !important;
}

.me-7 {
  margin-right: 3rem !important;
}

.me-8 {
  margin-right: 3.5rem !important;
}

.me-9 {
  margin-right: 4rem !important;
}

.me-10 {
  margin-right: 5rem !important;
}

.me-11 {
  margin-right: 6rem !important;
}

.me-12 {
  margin-right: 7rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2rem !important;
}

.mb-6 {
  margin-bottom: 2.5rem !important;
}

.mb-7 {
  margin-bottom: 3rem !important;
}

.mb-8 {
  margin-bottom: 3.5rem !important;
}

.mb-9 {
  margin-bottom: 4rem !important;
}

.mb-10 {
  margin-bottom: 5rem !important;
}

.mb-11 {
  margin-bottom: 6rem !important;
}

.mb-12 {
  margin-bottom: 7rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 2rem !important;
}

.ms-6 {
  margin-left: 2.5rem !important;
}

.ms-7 {
  margin-left: 3rem !important;
}

.ms-8 {
  margin-left: 3.5rem !important;
}

.ms-9 {
  margin-left: 4rem !important;
}

.ms-10 {
  margin-left: 5rem !important;
}

.ms-11 {
  margin-left: 6rem !important;
}

.ms-12 {
  margin-left: 7rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -2rem !important;
}

.m-n6 {
  margin: -2.5rem !important;
}

.m-n7 {
  margin: -3rem !important;
}

.m-n8 {
  margin: -3.5rem !important;
}

.m-n9 {
  margin: -4rem !important;
}

.m-n10 {
  margin: -5rem !important;
}

.m-n11 {
  margin: -6rem !important;
}

.m-n12 {
  margin: -7rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}

.mx-n6 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.mx-n7 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n8 {
  margin-right: -3.5rem !important;
  margin-left: -3.5rem !important;
}

.mx-n9 {
  margin-right: -4rem !important;
  margin-left: -4rem !important;
}

.mx-n10 {
  margin-right: -5rem !important;
  margin-left: -5rem !important;
}

.mx-n11 {
  margin-right: -6rem !important;
  margin-left: -6rem !important;
}

.mx-n12 {
  margin-right: -7rem !important;
  margin-left: -7rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-n6 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n7 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n8 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.my-n9 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.my-n10 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.my-n11 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.my-n12 {
  margin-top: -7rem !important;
  margin-bottom: -7rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -2rem !important;
}

.mt-n6 {
  margin-top: -2.5rem !important;
}

.mt-n7 {
  margin-top: -3rem !important;
}

.mt-n8 {
  margin-top: -3.5rem !important;
}

.mt-n9 {
  margin-top: -4rem !important;
}

.mt-n10 {
  margin-top: -5rem !important;
}

.mt-n11 {
  margin-top: -6rem !important;
}

.mt-n12 {
  margin-top: -7rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -2rem !important;
}

.me-n6 {
  margin-right: -2.5rem !important;
}

.me-n7 {
  margin-right: -3rem !important;
}

.me-n8 {
  margin-right: -3.5rem !important;
}

.me-n9 {
  margin-right: -4rem !important;
}

.me-n10 {
  margin-right: -5rem !important;
}

.me-n11 {
  margin-right: -6rem !important;
}

.me-n12 {
  margin-right: -7rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -2rem !important;
}

.mb-n6 {
  margin-bottom: -2.5rem !important;
}

.mb-n7 {
  margin-bottom: -3rem !important;
}

.mb-n8 {
  margin-bottom: -3.5rem !important;
}

.mb-n9 {
  margin-bottom: -4rem !important;
}

.mb-n10 {
  margin-bottom: -5rem !important;
}

.mb-n11 {
  margin-bottom: -6rem !important;
}

.mb-n12 {
  margin-bottom: -7rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -2rem !important;
}

.ms-n6 {
  margin-left: -2.5rem !important;
}

.ms-n7 {
  margin-left: -3rem !important;
}

.ms-n8 {
  margin-left: -3.5rem !important;
}

.ms-n9 {
  margin-left: -4rem !important;
}

.ms-n10 {
  margin-left: -5rem !important;
}

.ms-n11 {
  margin-left: -6rem !important;
}

.ms-n12 {
  margin-left: -7rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.p-6 {
  padding: 2.5rem !important;
}

.p-7 {
  padding: 3rem !important;
}

.p-8 {
  padding: 3.5rem !important;
}

.p-9 {
  padding: 4rem !important;
}

.p-10 {
  padding: 5rem !important;
}

.p-11 {
  padding: 6rem !important;
}

.p-12 {
  padding: 7rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-6 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-7 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-8 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.px-9 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-10 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.px-11 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

.px-12 {
  padding-right: 7rem !important;
  padding-left: 7rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-6 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-7 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-8 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-9 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-10 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-11 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-12 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 2rem !important;
}

.pt-6 {
  padding-top: 2.5rem !important;
}

.pt-7 {
  padding-top: 3rem !important;
}

.pt-8 {
  padding-top: 3.5rem !important;
}

.pt-9 {
  padding-top: 4rem !important;
}

.pt-10 {
  padding-top: 5rem !important;
}

.pt-11 {
  padding-top: 6rem !important;
}

.pt-12 {
  padding-top: 7rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 2rem !important;
}

.pe-6 {
  padding-right: 2.5rem !important;
}

.pe-7 {
  padding-right: 3rem !important;
}

.pe-8 {
  padding-right: 3.5rem !important;
}

.pe-9 {
  padding-right: 4rem !important;
}

.pe-10 {
  padding-right: 5rem !important;
}

.pe-11 {
  padding-right: 6rem !important;
}

.pe-12 {
  padding-right: 7rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 2rem !important;
}

.pb-6 {
  padding-bottom: 2.5rem !important;
}

.pb-7 {
  padding-bottom: 3rem !important;
}

.pb-8 {
  padding-bottom: 3.5rem !important;
}

.pb-9 {
  padding-bottom: 4rem !important;
}

.pb-10 {
  padding-bottom: 5rem !important;
}

.pb-11 {
  padding-bottom: 6rem !important;
}

.pb-12 {
  padding-bottom: 7rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 2rem !important;
}

.ps-6 {
  padding-left: 2.5rem !important;
}

.ps-7 {
  padding-left: 3rem !important;
}

.ps-8 {
  padding-left: 3.5rem !important;
}

.ps-9 {
  padding-left: 4rem !important;
}

.ps-10 {
  padding-left: 5rem !important;
}

.ps-11 {
  padding-left: 6rem !important;
}

.ps-12 {
  padding-left: 7rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 2rem !important;
}

.gap-6 {
  gap: 2.5rem !important;
}

.gap-7 {
  gap: 3rem !important;
}

.gap-8 {
  gap: 3.5rem !important;
}

.gap-9 {
  gap: 4rem !important;
}

.gap-10 {
  gap: 5rem !important;
}

.gap-11 {
  gap: 6rem !important;
}

.gap-12 {
  gap: 7rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 2rem !important;
}

.row-gap-6 {
  row-gap: 2.5rem !important;
}

.row-gap-7 {
  row-gap: 3rem !important;
}

.row-gap-8 {
  row-gap: 3.5rem !important;
}

.row-gap-9 {
  row-gap: 4rem !important;
}

.row-gap-10 {
  row-gap: 5rem !important;
}

.row-gap-11 {
  row-gap: 6rem !important;
}

.row-gap-12 {
  row-gap: 7rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 2rem !important;
}

.column-gap-6 {
  column-gap: 2.5rem !important;
}

.column-gap-7 {
  column-gap: 3rem !important;
}

.column-gap-8 {
  column-gap: 3.5rem !important;
}

.column-gap-9 {
  column-gap: 4rem !important;
}

.column-gap-10 {
  column-gap: 5rem !important;
}

.column-gap-11 {
  column-gap: 6rem !important;
}

.column-gap-12 {
  column-gap: 7rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.3575rem + 1.29vw) !important;
}

.fs-2 {
  font-size: calc(1.311rem + 0.732vw) !important;
}

.fs-3 {
  font-size: calc(1.28775rem + 0.453vw) !important;
}

.fs-4 {
  font-size: calc(1.2645rem + 0.174vw) !important;
}

.fs-5 {
  font-size: 1.1625rem !important;
}

.fs-6 {
  font-size: 0.93rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.7 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .object-fit-sm-contain {
    object-fit: contain !important;
  }

  .object-fit-sm-cover {
    object-fit: cover !important;
  }

  .object-fit-sm-fill {
    object-fit: fill !important;
  }

  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }

  .object-fit-sm-none {
    object-fit: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-inline-grid {
    display: inline-grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 2rem !important;
  }

  .m-sm-6 {
    margin: 2.5rem !important;
  }

  .m-sm-7 {
    margin: 3rem !important;
  }

  .m-sm-8 {
    margin: 3.5rem !important;
  }

  .m-sm-9 {
    margin: 4rem !important;
  }

  .m-sm-10 {
    margin: 5rem !important;
  }

  .m-sm-11 {
    margin: 6rem !important;
  }

  .m-sm-12 {
    margin: 7rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-sm-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-sm-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-sm-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-sm-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-sm-11 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-sm-12 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-sm-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-sm-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-sm-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-sm-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-sm-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-sm-12 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 2rem !important;
  }

  .mt-sm-6 {
    margin-top: 2.5rem !important;
  }

  .mt-sm-7 {
    margin-top: 3rem !important;
  }

  .mt-sm-8 {
    margin-top: 3.5rem !important;
  }

  .mt-sm-9 {
    margin-top: 4rem !important;
  }

  .mt-sm-10 {
    margin-top: 5rem !important;
  }

  .mt-sm-11 {
    margin-top: 6rem !important;
  }

  .mt-sm-12 {
    margin-top: 7rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 2rem !important;
  }

  .me-sm-6 {
    margin-right: 2.5rem !important;
  }

  .me-sm-7 {
    margin-right: 3rem !important;
  }

  .me-sm-8 {
    margin-right: 3.5rem !important;
  }

  .me-sm-9 {
    margin-right: 4rem !important;
  }

  .me-sm-10 {
    margin-right: 5rem !important;
  }

  .me-sm-11 {
    margin-right: 6rem !important;
  }

  .me-sm-12 {
    margin-right: 7rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 2rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 3.5rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 5rem !important;
  }

  .mb-sm-11 {
    margin-bottom: 6rem !important;
  }

  .mb-sm-12 {
    margin-bottom: 7rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 2rem !important;
  }

  .ms-sm-6 {
    margin-left: 2.5rem !important;
  }

  .ms-sm-7 {
    margin-left: 3rem !important;
  }

  .ms-sm-8 {
    margin-left: 3.5rem !important;
  }

  .ms-sm-9 {
    margin-left: 4rem !important;
  }

  .ms-sm-10 {
    margin-left: 5rem !important;
  }

  .ms-sm-11 {
    margin-left: 6rem !important;
  }

  .ms-sm-12 {
    margin-left: 7rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -2rem !important;
  }

  .m-sm-n6 {
    margin: -2.5rem !important;
  }

  .m-sm-n7 {
    margin: -3rem !important;
  }

  .m-sm-n8 {
    margin: -3.5rem !important;
  }

  .m-sm-n9 {
    margin: -4rem !important;
  }

  .m-sm-n10 {
    margin: -5rem !important;
  }

  .m-sm-n11 {
    margin: -6rem !important;
  }

  .m-sm-n12 {
    margin: -7rem !important;
  }

  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-sm-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-sm-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-sm-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-sm-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-sm-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-sm-n10 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .mx-sm-n11 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-sm-n12 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }

  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-sm-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-sm-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-sm-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-sm-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-sm-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-sm-n11 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-sm-n12 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -1rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n5 {
    margin-top: -2rem !important;
  }

  .mt-sm-n6 {
    margin-top: -2.5rem !important;
  }

  .mt-sm-n7 {
    margin-top: -3rem !important;
  }

  .mt-sm-n8 {
    margin-top: -3.5rem !important;
  }

  .mt-sm-n9 {
    margin-top: -4rem !important;
  }

  .mt-sm-n10 {
    margin-top: -5rem !important;
  }

  .mt-sm-n11 {
    margin-top: -6rem !important;
  }

  .mt-sm-n12 {
    margin-top: -7rem !important;
  }

  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -1rem !important;
  }

  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n5 {
    margin-right: -2rem !important;
  }

  .me-sm-n6 {
    margin-right: -2.5rem !important;
  }

  .me-sm-n7 {
    margin-right: -3rem !important;
  }

  .me-sm-n8 {
    margin-right: -3.5rem !important;
  }

  .me-sm-n9 {
    margin-right: -4rem !important;
  }

  .me-sm-n10 {
    margin-right: -5rem !important;
  }

  .me-sm-n11 {
    margin-right: -6rem !important;
  }

  .me-sm-n12 {
    margin-right: -7rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-sm-n6 {
    margin-bottom: -2.5rem !important;
  }

  .mb-sm-n7 {
    margin-bottom: -3rem !important;
  }

  .mb-sm-n8 {
    margin-bottom: -3.5rem !important;
  }

  .mb-sm-n9 {
    margin-bottom: -4rem !important;
  }

  .mb-sm-n10 {
    margin-bottom: -5rem !important;
  }

  .mb-sm-n11 {
    margin-bottom: -6rem !important;
  }

  .mb-sm-n12 {
    margin-bottom: -7rem !important;
  }

  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -1rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n5 {
    margin-left: -2rem !important;
  }

  .ms-sm-n6 {
    margin-left: -2.5rem !important;
  }

  .ms-sm-n7 {
    margin-left: -3rem !important;
  }

  .ms-sm-n8 {
    margin-left: -3.5rem !important;
  }

  .ms-sm-n9 {
    margin-left: -4rem !important;
  }

  .ms-sm-n10 {
    margin-left: -5rem !important;
  }

  .ms-sm-n11 {
    margin-left: -6rem !important;
  }

  .ms-sm-n12 {
    margin-left: -7rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 2rem !important;
  }

  .p-sm-6 {
    padding: 2.5rem !important;
  }

  .p-sm-7 {
    padding: 3rem !important;
  }

  .p-sm-8 {
    padding: 3.5rem !important;
  }

  .p-sm-9 {
    padding: 4rem !important;
  }

  .p-sm-10 {
    padding: 5rem !important;
  }

  .p-sm-11 {
    padding: 6rem !important;
  }

  .p-sm-12 {
    padding: 7rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-sm-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-sm-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-sm-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-sm-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-sm-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-sm-11 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-sm-12 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-sm-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-sm-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-sm-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-sm-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-sm-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-sm-12 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 2rem !important;
  }

  .pt-sm-6 {
    padding-top: 2.5rem !important;
  }

  .pt-sm-7 {
    padding-top: 3rem !important;
  }

  .pt-sm-8 {
    padding-top: 3.5rem !important;
  }

  .pt-sm-9 {
    padding-top: 4rem !important;
  }

  .pt-sm-10 {
    padding-top: 5rem !important;
  }

  .pt-sm-11 {
    padding-top: 6rem !important;
  }

  .pt-sm-12 {
    padding-top: 7rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 2rem !important;
  }

  .pe-sm-6 {
    padding-right: 2.5rem !important;
  }

  .pe-sm-7 {
    padding-right: 3rem !important;
  }

  .pe-sm-8 {
    padding-right: 3.5rem !important;
  }

  .pe-sm-9 {
    padding-right: 4rem !important;
  }

  .pe-sm-10 {
    padding-right: 5rem !important;
  }

  .pe-sm-11 {
    padding-right: 6rem !important;
  }

  .pe-sm-12 {
    padding-right: 7rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 2rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 2.5rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 3.5rem !important;
  }

  .pb-sm-9 {
    padding-bottom: 4rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 5rem !important;
  }

  .pb-sm-11 {
    padding-bottom: 6rem !important;
  }

  .pb-sm-12 {
    padding-bottom: 7rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 2rem !important;
  }

  .ps-sm-6 {
    padding-left: 2.5rem !important;
  }

  .ps-sm-7 {
    padding-left: 3rem !important;
  }

  .ps-sm-8 {
    padding-left: 3.5rem !important;
  }

  .ps-sm-9 {
    padding-left: 4rem !important;
  }

  .ps-sm-10 {
    padding-left: 5rem !important;
  }

  .ps-sm-11 {
    padding-left: 6rem !important;
  }

  .ps-sm-12 {
    padding-left: 7rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 2rem !important;
  }

  .gap-sm-6 {
    gap: 2.5rem !important;
  }

  .gap-sm-7 {
    gap: 3rem !important;
  }

  .gap-sm-8 {
    gap: 3.5rem !important;
  }

  .gap-sm-9 {
    gap: 4rem !important;
  }

  .gap-sm-10 {
    gap: 5rem !important;
  }

  .gap-sm-11 {
    gap: 6rem !important;
  }

  .gap-sm-12 {
    gap: 7rem !important;
  }

  .row-gap-sm-0 {
    row-gap: 0 !important;
  }

  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }

  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-sm-5 {
    row-gap: 2rem !important;
  }

  .row-gap-sm-6 {
    row-gap: 2.5rem !important;
  }

  .row-gap-sm-7 {
    row-gap: 3rem !important;
  }

  .row-gap-sm-8 {
    row-gap: 3.5rem !important;
  }

  .row-gap-sm-9 {
    row-gap: 4rem !important;
  }

  .row-gap-sm-10 {
    row-gap: 5rem !important;
  }

  .row-gap-sm-11 {
    row-gap: 6rem !important;
  }

  .row-gap-sm-12 {
    row-gap: 7rem !important;
  }

  .column-gap-sm-0 {
    column-gap: 0 !important;
  }

  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }

  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-sm-5 {
    column-gap: 2rem !important;
  }

  .column-gap-sm-6 {
    column-gap: 2.5rem !important;
  }

  .column-gap-sm-7 {
    column-gap: 3rem !important;
  }

  .column-gap-sm-8 {
    column-gap: 3.5rem !important;
  }

  .column-gap-sm-9 {
    column-gap: 4rem !important;
  }

  .column-gap-sm-10 {
    column-gap: 5rem !important;
  }

  .column-gap-sm-11 {
    column-gap: 6rem !important;
  }

  .column-gap-sm-12 {
    column-gap: 7rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .object-fit-md-contain {
    object-fit: contain !important;
  }

  .object-fit-md-cover {
    object-fit: cover !important;
  }

  .object-fit-md-fill {
    object-fit: fill !important;
  }

  .object-fit-md-scale {
    object-fit: scale-down !important;
  }

  .object-fit-md-none {
    object-fit: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-inline-grid {
    display: inline-grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 2rem !important;
  }

  .m-md-6 {
    margin: 2.5rem !important;
  }

  .m-md-7 {
    margin: 3rem !important;
  }

  .m-md-8 {
    margin: 3.5rem !important;
  }

  .m-md-9 {
    margin: 4rem !important;
  }

  .m-md-10 {
    margin: 5rem !important;
  }

  .m-md-11 {
    margin: 6rem !important;
  }

  .m-md-12 {
    margin: 7rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-md-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-md-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-md-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-md-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-md-11 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-md-12 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-md-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-md-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-md-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-md-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-md-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-md-12 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 2rem !important;
  }

  .mt-md-6 {
    margin-top: 2.5rem !important;
  }

  .mt-md-7 {
    margin-top: 3rem !important;
  }

  .mt-md-8 {
    margin-top: 3.5rem !important;
  }

  .mt-md-9 {
    margin-top: 4rem !important;
  }

  .mt-md-10 {
    margin-top: 5rem !important;
  }

  .mt-md-11 {
    margin-top: 6rem !important;
  }

  .mt-md-12 {
    margin-top: 7rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 2rem !important;
  }

  .me-md-6 {
    margin-right: 2.5rem !important;
  }

  .me-md-7 {
    margin-right: 3rem !important;
  }

  .me-md-8 {
    margin-right: 3.5rem !important;
  }

  .me-md-9 {
    margin-right: 4rem !important;
  }

  .me-md-10 {
    margin-right: 5rem !important;
  }

  .me-md-11 {
    margin-right: 6rem !important;
  }

  .me-md-12 {
    margin-right: 7rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 2rem !important;
  }

  .mb-md-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-md-7 {
    margin-bottom: 3rem !important;
  }

  .mb-md-8 {
    margin-bottom: 3.5rem !important;
  }

  .mb-md-9 {
    margin-bottom: 4rem !important;
  }

  .mb-md-10 {
    margin-bottom: 5rem !important;
  }

  .mb-md-11 {
    margin-bottom: 6rem !important;
  }

  .mb-md-12 {
    margin-bottom: 7rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 2rem !important;
  }

  .ms-md-6 {
    margin-left: 2.5rem !important;
  }

  .ms-md-7 {
    margin-left: 3rem !important;
  }

  .ms-md-8 {
    margin-left: 3.5rem !important;
  }

  .ms-md-9 {
    margin-left: 4rem !important;
  }

  .ms-md-10 {
    margin-left: 5rem !important;
  }

  .ms-md-11 {
    margin-left: 6rem !important;
  }

  .ms-md-12 {
    margin-left: 7rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -2rem !important;
  }

  .m-md-n6 {
    margin: -2.5rem !important;
  }

  .m-md-n7 {
    margin: -3rem !important;
  }

  .m-md-n8 {
    margin: -3.5rem !important;
  }

  .m-md-n9 {
    margin: -4rem !important;
  }

  .m-md-n10 {
    margin: -5rem !important;
  }

  .m-md-n11 {
    margin: -6rem !important;
  }

  .m-md-n12 {
    margin: -7rem !important;
  }

  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-md-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-md-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-md-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-md-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-md-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-md-n10 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .mx-md-n11 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-md-n12 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }

  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-md-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-md-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-md-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-md-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-md-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-md-n11 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-md-n12 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -1rem !important;
  }

  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n5 {
    margin-top: -2rem !important;
  }

  .mt-md-n6 {
    margin-top: -2.5rem !important;
  }

  .mt-md-n7 {
    margin-top: -3rem !important;
  }

  .mt-md-n8 {
    margin-top: -3.5rem !important;
  }

  .mt-md-n9 {
    margin-top: -4rem !important;
  }

  .mt-md-n10 {
    margin-top: -5rem !important;
  }

  .mt-md-n11 {
    margin-top: -6rem !important;
  }

  .mt-md-n12 {
    margin-top: -7rem !important;
  }

  .me-md-n1 {
    margin-right: -0.25rem !important;
  }

  .me-md-n2 {
    margin-right: -0.5rem !important;
  }

  .me-md-n3 {
    margin-right: -1rem !important;
  }

  .me-md-n4 {
    margin-right: -1.5rem !important;
  }

  .me-md-n5 {
    margin-right: -2rem !important;
  }

  .me-md-n6 {
    margin-right: -2.5rem !important;
  }

  .me-md-n7 {
    margin-right: -3rem !important;
  }

  .me-md-n8 {
    margin-right: -3.5rem !important;
  }

  .me-md-n9 {
    margin-right: -4rem !important;
  }

  .me-md-n10 {
    margin-right: -5rem !important;
  }

  .me-md-n11 {
    margin-right: -6rem !important;
  }

  .me-md-n12 {
    margin-right: -7rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-md-n6 {
    margin-bottom: -2.5rem !important;
  }

  .mb-md-n7 {
    margin-bottom: -3rem !important;
  }

  .mb-md-n8 {
    margin-bottom: -3.5rem !important;
  }

  .mb-md-n9 {
    margin-bottom: -4rem !important;
  }

  .mb-md-n10 {
    margin-bottom: -5rem !important;
  }

  .mb-md-n11 {
    margin-bottom: -6rem !important;
  }

  .mb-md-n12 {
    margin-bottom: -7rem !important;
  }

  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -1rem !important;
  }

  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n5 {
    margin-left: -2rem !important;
  }

  .ms-md-n6 {
    margin-left: -2.5rem !important;
  }

  .ms-md-n7 {
    margin-left: -3rem !important;
  }

  .ms-md-n8 {
    margin-left: -3.5rem !important;
  }

  .ms-md-n9 {
    margin-left: -4rem !important;
  }

  .ms-md-n10 {
    margin-left: -5rem !important;
  }

  .ms-md-n11 {
    margin-left: -6rem !important;
  }

  .ms-md-n12 {
    margin-left: -7rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 2rem !important;
  }

  .p-md-6 {
    padding: 2.5rem !important;
  }

  .p-md-7 {
    padding: 3rem !important;
  }

  .p-md-8 {
    padding: 3.5rem !important;
  }

  .p-md-9 {
    padding: 4rem !important;
  }

  .p-md-10 {
    padding: 5rem !important;
  }

  .p-md-11 {
    padding: 6rem !important;
  }

  .p-md-12 {
    padding: 7rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-md-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-md-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-md-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-md-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-md-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-md-11 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-md-12 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-md-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-md-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-md-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-md-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-md-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-md-12 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 2rem !important;
  }

  .pt-md-6 {
    padding-top: 2.5rem !important;
  }

  .pt-md-7 {
    padding-top: 3rem !important;
  }

  .pt-md-8 {
    padding-top: 3.5rem !important;
  }

  .pt-md-9 {
    padding-top: 4rem !important;
  }

  .pt-md-10 {
    padding-top: 5rem !important;
  }

  .pt-md-11 {
    padding-top: 6rem !important;
  }

  .pt-md-12 {
    padding-top: 7rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 2rem !important;
  }

  .pe-md-6 {
    padding-right: 2.5rem !important;
  }

  .pe-md-7 {
    padding-right: 3rem !important;
  }

  .pe-md-8 {
    padding-right: 3.5rem !important;
  }

  .pe-md-9 {
    padding-right: 4rem !important;
  }

  .pe-md-10 {
    padding-right: 5rem !important;
  }

  .pe-md-11 {
    padding-right: 6rem !important;
  }

  .pe-md-12 {
    padding-right: 7rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 2rem !important;
  }

  .pb-md-6 {
    padding-bottom: 2.5rem !important;
  }

  .pb-md-7 {
    padding-bottom: 3rem !important;
  }

  .pb-md-8 {
    padding-bottom: 3.5rem !important;
  }

  .pb-md-9 {
    padding-bottom: 4rem !important;
  }

  .pb-md-10 {
    padding-bottom: 5rem !important;
  }

  .pb-md-11 {
    padding-bottom: 6rem !important;
  }

  .pb-md-12 {
    padding-bottom: 7rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 2rem !important;
  }

  .ps-md-6 {
    padding-left: 2.5rem !important;
  }

  .ps-md-7 {
    padding-left: 3rem !important;
  }

  .ps-md-8 {
    padding-left: 3.5rem !important;
  }

  .ps-md-9 {
    padding-left: 4rem !important;
  }

  .ps-md-10 {
    padding-left: 5rem !important;
  }

  .ps-md-11 {
    padding-left: 6rem !important;
  }

  .ps-md-12 {
    padding-left: 7rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 2rem !important;
  }

  .gap-md-6 {
    gap: 2.5rem !important;
  }

  .gap-md-7 {
    gap: 3rem !important;
  }

  .gap-md-8 {
    gap: 3.5rem !important;
  }

  .gap-md-9 {
    gap: 4rem !important;
  }

  .gap-md-10 {
    gap: 5rem !important;
  }

  .gap-md-11 {
    gap: 6rem !important;
  }

  .gap-md-12 {
    gap: 7rem !important;
  }

  .row-gap-md-0 {
    row-gap: 0 !important;
  }

  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-md-3 {
    row-gap: 1rem !important;
  }

  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-md-5 {
    row-gap: 2rem !important;
  }

  .row-gap-md-6 {
    row-gap: 2.5rem !important;
  }

  .row-gap-md-7 {
    row-gap: 3rem !important;
  }

  .row-gap-md-8 {
    row-gap: 3.5rem !important;
  }

  .row-gap-md-9 {
    row-gap: 4rem !important;
  }

  .row-gap-md-10 {
    row-gap: 5rem !important;
  }

  .row-gap-md-11 {
    row-gap: 6rem !important;
  }

  .row-gap-md-12 {
    row-gap: 7rem !important;
  }

  .column-gap-md-0 {
    column-gap: 0 !important;
  }

  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-md-3 {
    column-gap: 1rem !important;
  }

  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-md-5 {
    column-gap: 2rem !important;
  }

  .column-gap-md-6 {
    column-gap: 2.5rem !important;
  }

  .column-gap-md-7 {
    column-gap: 3rem !important;
  }

  .column-gap-md-8 {
    column-gap: 3.5rem !important;
  }

  .column-gap-md-9 {
    column-gap: 4rem !important;
  }

  .column-gap-md-10 {
    column-gap: 5rem !important;
  }

  .column-gap-md-11 {
    column-gap: 6rem !important;
  }

  .column-gap-md-12 {
    column-gap: 7rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .object-fit-lg-contain {
    object-fit: contain !important;
  }

  .object-fit-lg-cover {
    object-fit: cover !important;
  }

  .object-fit-lg-fill {
    object-fit: fill !important;
  }

  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }

  .object-fit-lg-none {
    object-fit: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-inline-grid {
    display: inline-grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 2rem !important;
  }

  .m-lg-6 {
    margin: 2.5rem !important;
  }

  .m-lg-7 {
    margin: 3rem !important;
  }

  .m-lg-8 {
    margin: 3.5rem !important;
  }

  .m-lg-9 {
    margin: 4rem !important;
  }

  .m-lg-10 {
    margin: 5rem !important;
  }

  .m-lg-11 {
    margin: 6rem !important;
  }

  .m-lg-12 {
    margin: 7rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-lg-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-lg-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-lg-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-lg-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-lg-11 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-lg-12 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-lg-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-lg-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-lg-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-lg-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-lg-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-lg-12 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 2rem !important;
  }

  .mt-lg-6 {
    margin-top: 2.5rem !important;
  }

  .mt-lg-7 {
    margin-top: 3rem !important;
  }

  .mt-lg-8 {
    margin-top: 3.5rem !important;
  }

  .mt-lg-9 {
    margin-top: 4rem !important;
  }

  .mt-lg-10 {
    margin-top: 5rem !important;
  }

  .mt-lg-11 {
    margin-top: 6rem !important;
  }

  .mt-lg-12 {
    margin-top: 7rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 2rem !important;
  }

  .me-lg-6 {
    margin-right: 2.5rem !important;
  }

  .me-lg-7 {
    margin-right: 3rem !important;
  }

  .me-lg-8 {
    margin-right: 3.5rem !important;
  }

  .me-lg-9 {
    margin-right: 4rem !important;
  }

  .me-lg-10 {
    margin-right: 5rem !important;
  }

  .me-lg-11 {
    margin-right: 6rem !important;
  }

  .me-lg-12 {
    margin-right: 7rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 2rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 3.5rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 5rem !important;
  }

  .mb-lg-11 {
    margin-bottom: 6rem !important;
  }

  .mb-lg-12 {
    margin-bottom: 7rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 2rem !important;
  }

  .ms-lg-6 {
    margin-left: 2.5rem !important;
  }

  .ms-lg-7 {
    margin-left: 3rem !important;
  }

  .ms-lg-8 {
    margin-left: 3.5rem !important;
  }

  .ms-lg-9 {
    margin-left: 4rem !important;
  }

  .ms-lg-10 {
    margin-left: 5rem !important;
  }

  .ms-lg-11 {
    margin-left: 6rem !important;
  }

  .ms-lg-12 {
    margin-left: 7rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -2rem !important;
  }

  .m-lg-n6 {
    margin: -2.5rem !important;
  }

  .m-lg-n7 {
    margin: -3rem !important;
  }

  .m-lg-n8 {
    margin: -3.5rem !important;
  }

  .m-lg-n9 {
    margin: -4rem !important;
  }

  .m-lg-n10 {
    margin: -5rem !important;
  }

  .m-lg-n11 {
    margin: -6rem !important;
  }

  .m-lg-n12 {
    margin: -7rem !important;
  }

  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-lg-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-lg-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-lg-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-lg-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-lg-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-lg-n10 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .mx-lg-n11 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-lg-n12 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }

  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-lg-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-lg-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-lg-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-lg-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-lg-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-lg-n11 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-lg-n12 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -1rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n5 {
    margin-top: -2rem !important;
  }

  .mt-lg-n6 {
    margin-top: -2.5rem !important;
  }

  .mt-lg-n7 {
    margin-top: -3rem !important;
  }

  .mt-lg-n8 {
    margin-top: -3.5rem !important;
  }

  .mt-lg-n9 {
    margin-top: -4rem !important;
  }

  .mt-lg-n10 {
    margin-top: -5rem !important;
  }

  .mt-lg-n11 {
    margin-top: -6rem !important;
  }

  .mt-lg-n12 {
    margin-top: -7rem !important;
  }

  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -1rem !important;
  }

  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n5 {
    margin-right: -2rem !important;
  }

  .me-lg-n6 {
    margin-right: -2.5rem !important;
  }

  .me-lg-n7 {
    margin-right: -3rem !important;
  }

  .me-lg-n8 {
    margin-right: -3.5rem !important;
  }

  .me-lg-n9 {
    margin-right: -4rem !important;
  }

  .me-lg-n10 {
    margin-right: -5rem !important;
  }

  .me-lg-n11 {
    margin-right: -6rem !important;
  }

  .me-lg-n12 {
    margin-right: -7rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-lg-n6 {
    margin-bottom: -2.5rem !important;
  }

  .mb-lg-n7 {
    margin-bottom: -3rem !important;
  }

  .mb-lg-n8 {
    margin-bottom: -3.5rem !important;
  }

  .mb-lg-n9 {
    margin-bottom: -4rem !important;
  }

  .mb-lg-n10 {
    margin-bottom: -5rem !important;
  }

  .mb-lg-n11 {
    margin-bottom: -6rem !important;
  }

  .mb-lg-n12 {
    margin-bottom: -7rem !important;
  }

  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -1rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n5 {
    margin-left: -2rem !important;
  }

  .ms-lg-n6 {
    margin-left: -2.5rem !important;
  }

  .ms-lg-n7 {
    margin-left: -3rem !important;
  }

  .ms-lg-n8 {
    margin-left: -3.5rem !important;
  }

  .ms-lg-n9 {
    margin-left: -4rem !important;
  }

  .ms-lg-n10 {
    margin-left: -5rem !important;
  }

  .ms-lg-n11 {
    margin-left: -6rem !important;
  }

  .ms-lg-n12 {
    margin-left: -7rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 2rem !important;
  }

  .p-lg-6 {
    padding: 2.5rem !important;
  }

  .p-lg-7 {
    padding: 3rem !important;
  }

  .p-lg-8 {
    padding: 3.5rem !important;
  }

  .p-lg-9 {
    padding: 4rem !important;
  }

  .p-lg-10 {
    padding: 5rem !important;
  }

  .p-lg-11 {
    padding: 6rem !important;
  }

  .p-lg-12 {
    padding: 7rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-lg-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-lg-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-lg-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-lg-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-lg-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-lg-11 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-lg-12 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-lg-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-lg-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-lg-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-lg-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-lg-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-lg-12 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 2rem !important;
  }

  .pt-lg-6 {
    padding-top: 2.5rem !important;
  }

  .pt-lg-7 {
    padding-top: 3rem !important;
  }

  .pt-lg-8 {
    padding-top: 3.5rem !important;
  }

  .pt-lg-9 {
    padding-top: 4rem !important;
  }

  .pt-lg-10 {
    padding-top: 5rem !important;
  }

  .pt-lg-11 {
    padding-top: 6rem !important;
  }

  .pt-lg-12 {
    padding-top: 7rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 2rem !important;
  }

  .pe-lg-6 {
    padding-right: 2.5rem !important;
  }

  .pe-lg-7 {
    padding-right: 3rem !important;
  }

  .pe-lg-8 {
    padding-right: 3.5rem !important;
  }

  .pe-lg-9 {
    padding-right: 4rem !important;
  }

  .pe-lg-10 {
    padding-right: 5rem !important;
  }

  .pe-lg-11 {
    padding-right: 6rem !important;
  }

  .pe-lg-12 {
    padding-right: 7rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 2rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 2.5rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 3.5rem !important;
  }

  .pb-lg-9 {
    padding-bottom: 4rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 5rem !important;
  }

  .pb-lg-11 {
    padding-bottom: 6rem !important;
  }

  .pb-lg-12 {
    padding-bottom: 7rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 2rem !important;
  }

  .ps-lg-6 {
    padding-left: 2.5rem !important;
  }

  .ps-lg-7 {
    padding-left: 3rem !important;
  }

  .ps-lg-8 {
    padding-left: 3.5rem !important;
  }

  .ps-lg-9 {
    padding-left: 4rem !important;
  }

  .ps-lg-10 {
    padding-left: 5rem !important;
  }

  .ps-lg-11 {
    padding-left: 6rem !important;
  }

  .ps-lg-12 {
    padding-left: 7rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 2rem !important;
  }

  .gap-lg-6 {
    gap: 2.5rem !important;
  }

  .gap-lg-7 {
    gap: 3rem !important;
  }

  .gap-lg-8 {
    gap: 3.5rem !important;
  }

  .gap-lg-9 {
    gap: 4rem !important;
  }

  .gap-lg-10 {
    gap: 5rem !important;
  }

  .gap-lg-11 {
    gap: 6rem !important;
  }

  .gap-lg-12 {
    gap: 7rem !important;
  }

  .row-gap-lg-0 {
    row-gap: 0 !important;
  }

  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }

  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-lg-5 {
    row-gap: 2rem !important;
  }

  .row-gap-lg-6 {
    row-gap: 2.5rem !important;
  }

  .row-gap-lg-7 {
    row-gap: 3rem !important;
  }

  .row-gap-lg-8 {
    row-gap: 3.5rem !important;
  }

  .row-gap-lg-9 {
    row-gap: 4rem !important;
  }

  .row-gap-lg-10 {
    row-gap: 5rem !important;
  }

  .row-gap-lg-11 {
    row-gap: 6rem !important;
  }

  .row-gap-lg-12 {
    row-gap: 7rem !important;
  }

  .column-gap-lg-0 {
    column-gap: 0 !important;
  }

  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }

  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-lg-5 {
    column-gap: 2rem !important;
  }

  .column-gap-lg-6 {
    column-gap: 2.5rem !important;
  }

  .column-gap-lg-7 {
    column-gap: 3rem !important;
  }

  .column-gap-lg-8 {
    column-gap: 3.5rem !important;
  }

  .column-gap-lg-9 {
    column-gap: 4rem !important;
  }

  .column-gap-lg-10 {
    column-gap: 5rem !important;
  }

  .column-gap-lg-11 {
    column-gap: 6rem !important;
  }

  .column-gap-lg-12 {
    column-gap: 7rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .object-fit-xl-contain {
    object-fit: contain !important;
  }

  .object-fit-xl-cover {
    object-fit: cover !important;
  }

  .object-fit-xl-fill {
    object-fit: fill !important;
  }

  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xl-none {
    object-fit: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-inline-grid {
    display: inline-grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 2rem !important;
  }

  .m-xl-6 {
    margin: 2.5rem !important;
  }

  .m-xl-7 {
    margin: 3rem !important;
  }

  .m-xl-8 {
    margin: 3.5rem !important;
  }

  .m-xl-9 {
    margin: 4rem !important;
  }

  .m-xl-10 {
    margin: 5rem !important;
  }

  .m-xl-11 {
    margin: 6rem !important;
  }

  .m-xl-12 {
    margin: 7rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xl-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xl-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xl-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xl-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xl-11 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-xl-12 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xl-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xl-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xl-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xl-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xl-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-xl-12 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 2rem !important;
  }

  .mt-xl-6 {
    margin-top: 2.5rem !important;
  }

  .mt-xl-7 {
    margin-top: 3rem !important;
  }

  .mt-xl-8 {
    margin-top: 3.5rem !important;
  }

  .mt-xl-9 {
    margin-top: 4rem !important;
  }

  .mt-xl-10 {
    margin-top: 5rem !important;
  }

  .mt-xl-11 {
    margin-top: 6rem !important;
  }

  .mt-xl-12 {
    margin-top: 7rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 2rem !important;
  }

  .me-xl-6 {
    margin-right: 2.5rem !important;
  }

  .me-xl-7 {
    margin-right: 3rem !important;
  }

  .me-xl-8 {
    margin-right: 3.5rem !important;
  }

  .me-xl-9 {
    margin-right: 4rem !important;
  }

  .me-xl-10 {
    margin-right: 5rem !important;
  }

  .me-xl-11 {
    margin-right: 6rem !important;
  }

  .me-xl-12 {
    margin-right: 7rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 2rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 5rem !important;
  }

  .mb-xl-11 {
    margin-bottom: 6rem !important;
  }

  .mb-xl-12 {
    margin-bottom: 7rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 2rem !important;
  }

  .ms-xl-6 {
    margin-left: 2.5rem !important;
  }

  .ms-xl-7 {
    margin-left: 3rem !important;
  }

  .ms-xl-8 {
    margin-left: 3.5rem !important;
  }

  .ms-xl-9 {
    margin-left: 4rem !important;
  }

  .ms-xl-10 {
    margin-left: 5rem !important;
  }

  .ms-xl-11 {
    margin-left: 6rem !important;
  }

  .ms-xl-12 {
    margin-left: 7rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -2rem !important;
  }

  .m-xl-n6 {
    margin: -2.5rem !important;
  }

  .m-xl-n7 {
    margin: -3rem !important;
  }

  .m-xl-n8 {
    margin: -3.5rem !important;
  }

  .m-xl-n9 {
    margin: -4rem !important;
  }

  .m-xl-n10 {
    margin: -5rem !important;
  }

  .m-xl-n11 {
    margin: -6rem !important;
  }

  .m-xl-n12 {
    margin: -7rem !important;
  }

  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-xl-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-xl-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xl-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-xl-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-xl-n10 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .mx-xl-n11 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-xl-n12 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }

  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xl-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xl-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xl-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-xl-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xl-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-xl-n11 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-xl-n12 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n5 {
    margin-top: -2rem !important;
  }

  .mt-xl-n6 {
    margin-top: -2.5rem !important;
  }

  .mt-xl-n7 {
    margin-top: -3rem !important;
  }

  .mt-xl-n8 {
    margin-top: -3.5rem !important;
  }

  .mt-xl-n9 {
    margin-top: -4rem !important;
  }

  .mt-xl-n10 {
    margin-top: -5rem !important;
  }

  .mt-xl-n11 {
    margin-top: -6rem !important;
  }

  .mt-xl-n12 {
    margin-top: -7rem !important;
  }

  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -1rem !important;
  }

  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n5 {
    margin-right: -2rem !important;
  }

  .me-xl-n6 {
    margin-right: -2.5rem !important;
  }

  .me-xl-n7 {
    margin-right: -3rem !important;
  }

  .me-xl-n8 {
    margin-right: -3.5rem !important;
  }

  .me-xl-n9 {
    margin-right: -4rem !important;
  }

  .me-xl-n10 {
    margin-right: -5rem !important;
  }

  .me-xl-n11 {
    margin-right: -6rem !important;
  }

  .me-xl-n12 {
    margin-right: -7rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-xl-n6 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xl-n7 {
    margin-bottom: -3rem !important;
  }

  .mb-xl-n8 {
    margin-bottom: -3.5rem !important;
  }

  .mb-xl-n9 {
    margin-bottom: -4rem !important;
  }

  .mb-xl-n10 {
    margin-bottom: -5rem !important;
  }

  .mb-xl-n11 {
    margin-bottom: -6rem !important;
  }

  .mb-xl-n12 {
    margin-bottom: -7rem !important;
  }

  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n5 {
    margin-left: -2rem !important;
  }

  .ms-xl-n6 {
    margin-left: -2.5rem !important;
  }

  .ms-xl-n7 {
    margin-left: -3rem !important;
  }

  .ms-xl-n8 {
    margin-left: -3.5rem !important;
  }

  .ms-xl-n9 {
    margin-left: -4rem !important;
  }

  .ms-xl-n10 {
    margin-left: -5rem !important;
  }

  .ms-xl-n11 {
    margin-left: -6rem !important;
  }

  .ms-xl-n12 {
    margin-left: -7rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 2rem !important;
  }

  .p-xl-6 {
    padding: 2.5rem !important;
  }

  .p-xl-7 {
    padding: 3rem !important;
  }

  .p-xl-8 {
    padding: 3.5rem !important;
  }

  .p-xl-9 {
    padding: 4rem !important;
  }

  .p-xl-10 {
    padding: 5rem !important;
  }

  .p-xl-11 {
    padding: 6rem !important;
  }

  .p-xl-12 {
    padding: 7rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xl-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xl-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xl-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xl-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xl-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-xl-11 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-xl-12 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xl-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xl-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xl-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xl-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-xl-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-xl-12 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 2rem !important;
  }

  .pt-xl-6 {
    padding-top: 2.5rem !important;
  }

  .pt-xl-7 {
    padding-top: 3rem !important;
  }

  .pt-xl-8 {
    padding-top: 3.5rem !important;
  }

  .pt-xl-9 {
    padding-top: 4rem !important;
  }

  .pt-xl-10 {
    padding-top: 5rem !important;
  }

  .pt-xl-11 {
    padding-top: 6rem !important;
  }

  .pt-xl-12 {
    padding-top: 7rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 2rem !important;
  }

  .pe-xl-6 {
    padding-right: 2.5rem !important;
  }

  .pe-xl-7 {
    padding-right: 3rem !important;
  }

  .pe-xl-8 {
    padding-right: 3.5rem !important;
  }

  .pe-xl-9 {
    padding-right: 4rem !important;
  }

  .pe-xl-10 {
    padding-right: 5rem !important;
  }

  .pe-xl-11 {
    padding-right: 6rem !important;
  }

  .pe-xl-12 {
    padding-right: 7rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 2rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xl-9 {
    padding-bottom: 4rem !important;
  }

  .pb-xl-10 {
    padding-bottom: 5rem !important;
  }

  .pb-xl-11 {
    padding-bottom: 6rem !important;
  }

  .pb-xl-12 {
    padding-bottom: 7rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 2rem !important;
  }

  .ps-xl-6 {
    padding-left: 2.5rem !important;
  }

  .ps-xl-7 {
    padding-left: 3rem !important;
  }

  .ps-xl-8 {
    padding-left: 3.5rem !important;
  }

  .ps-xl-9 {
    padding-left: 4rem !important;
  }

  .ps-xl-10 {
    padding-left: 5rem !important;
  }

  .ps-xl-11 {
    padding-left: 6rem !important;
  }

  .ps-xl-12 {
    padding-left: 7rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 2rem !important;
  }

  .gap-xl-6 {
    gap: 2.5rem !important;
  }

  .gap-xl-7 {
    gap: 3rem !important;
  }

  .gap-xl-8 {
    gap: 3.5rem !important;
  }

  .gap-xl-9 {
    gap: 4rem !important;
  }

  .gap-xl-10 {
    gap: 5rem !important;
  }

  .gap-xl-11 {
    gap: 6rem !important;
  }

  .gap-xl-12 {
    gap: 7rem !important;
  }

  .row-gap-xl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xl-5 {
    row-gap: 2rem !important;
  }

  .row-gap-xl-6 {
    row-gap: 2.5rem !important;
  }

  .row-gap-xl-7 {
    row-gap: 3rem !important;
  }

  .row-gap-xl-8 {
    row-gap: 3.5rem !important;
  }

  .row-gap-xl-9 {
    row-gap: 4rem !important;
  }

  .row-gap-xl-10 {
    row-gap: 5rem !important;
  }

  .row-gap-xl-11 {
    row-gap: 6rem !important;
  }

  .row-gap-xl-12 {
    row-gap: 7rem !important;
  }

  .column-gap-xl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }

  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xl-5 {
    column-gap: 2rem !important;
  }

  .column-gap-xl-6 {
    column-gap: 2.5rem !important;
  }

  .column-gap-xl-7 {
    column-gap: 3rem !important;
  }

  .column-gap-xl-8 {
    column-gap: 3.5rem !important;
  }

  .column-gap-xl-9 {
    column-gap: 4rem !important;
  }

  .column-gap-xl-10 {
    column-gap: 5rem !important;
  }

  .column-gap-xl-11 {
    column-gap: 6rem !important;
  }

  .column-gap-xl-12 {
    column-gap: 7rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .object-fit-xxl-contain {
    object-fit: contain !important;
  }

  .object-fit-xxl-cover {
    object-fit: cover !important;
  }

  .object-fit-xxl-fill {
    object-fit: fill !important;
  }

  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xxl-none {
    object-fit: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-inline-grid {
    display: inline-grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 2rem !important;
  }

  .m-xxl-6 {
    margin: 2.5rem !important;
  }

  .m-xxl-7 {
    margin: 3rem !important;
  }

  .m-xxl-8 {
    margin: 3.5rem !important;
  }

  .m-xxl-9 {
    margin: 4rem !important;
  }

  .m-xxl-10 {
    margin: 5rem !important;
  }

  .m-xxl-11 {
    margin: 6rem !important;
  }

  .m-xxl-12 {
    margin: 7rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xxl-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xxl-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xxl-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xxl-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xxl-11 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-xxl-12 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xxl-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xxl-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xxl-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xxl-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xxl-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-xxl-12 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 2rem !important;
  }

  .mt-xxl-6 {
    margin-top: 2.5rem !important;
  }

  .mt-xxl-7 {
    margin-top: 3rem !important;
  }

  .mt-xxl-8 {
    margin-top: 3.5rem !important;
  }

  .mt-xxl-9 {
    margin-top: 4rem !important;
  }

  .mt-xxl-10 {
    margin-top: 5rem !important;
  }

  .mt-xxl-11 {
    margin-top: 6rem !important;
  }

  .mt-xxl-12 {
    margin-top: 7rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 2rem !important;
  }

  .me-xxl-6 {
    margin-right: 2.5rem !important;
  }

  .me-xxl-7 {
    margin-right: 3rem !important;
  }

  .me-xxl-8 {
    margin-right: 3.5rem !important;
  }

  .me-xxl-9 {
    margin-right: 4rem !important;
  }

  .me-xxl-10 {
    margin-right: 5rem !important;
  }

  .me-xxl-11 {
    margin-right: 6rem !important;
  }

  .me-xxl-12 {
    margin-right: 7rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 2rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xxl-9 {
    margin-bottom: 4rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 5rem !important;
  }

  .mb-xxl-11 {
    margin-bottom: 6rem !important;
  }

  .mb-xxl-12 {
    margin-bottom: 7rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 2rem !important;
  }

  .ms-xxl-6 {
    margin-left: 2.5rem !important;
  }

  .ms-xxl-7 {
    margin-left: 3rem !important;
  }

  .ms-xxl-8 {
    margin-left: 3.5rem !important;
  }

  .ms-xxl-9 {
    margin-left: 4rem !important;
  }

  .ms-xxl-10 {
    margin-left: 5rem !important;
  }

  .ms-xxl-11 {
    margin-left: 6rem !important;
  }

  .ms-xxl-12 {
    margin-left: 7rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -2rem !important;
  }

  .m-xxl-n6 {
    margin: -2.5rem !important;
  }

  .m-xxl-n7 {
    margin: -3rem !important;
  }

  .m-xxl-n8 {
    margin: -3.5rem !important;
  }

  .m-xxl-n9 {
    margin: -4rem !important;
  }

  .m-xxl-n10 {
    margin: -5rem !important;
  }

  .m-xxl-n11 {
    margin: -6rem !important;
  }

  .m-xxl-n12 {
    margin: -7rem !important;
  }

  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xxl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-xxl-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-xxl-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xxl-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-xxl-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-xxl-n10 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .mx-xxl-n11 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-xxl-n12 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }

  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xxl-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xxl-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xxl-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-xxl-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xxl-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-xxl-n11 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-xxl-n12 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -2rem !important;
  }

  .mt-xxl-n6 {
    margin-top: -2.5rem !important;
  }

  .mt-xxl-n7 {
    margin-top: -3rem !important;
  }

  .mt-xxl-n8 {
    margin-top: -3.5rem !important;
  }

  .mt-xxl-n9 {
    margin-top: -4rem !important;
  }

  .mt-xxl-n10 {
    margin-top: -5rem !important;
  }

  .mt-xxl-n11 {
    margin-top: -6rem !important;
  }

  .mt-xxl-n12 {
    margin-top: -7rem !important;
  }

  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -1rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n5 {
    margin-right: -2rem !important;
  }

  .me-xxl-n6 {
    margin-right: -2.5rem !important;
  }

  .me-xxl-n7 {
    margin-right: -3rem !important;
  }

  .me-xxl-n8 {
    margin-right: -3.5rem !important;
  }

  .me-xxl-n9 {
    margin-right: -4rem !important;
  }

  .me-xxl-n10 {
    margin-right: -5rem !important;
  }

  .me-xxl-n11 {
    margin-right: -6rem !important;
  }

  .me-xxl-n12 {
    margin-right: -7rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-xxl-n6 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xxl-n7 {
    margin-bottom: -3rem !important;
  }

  .mb-xxl-n8 {
    margin-bottom: -3.5rem !important;
  }

  .mb-xxl-n9 {
    margin-bottom: -4rem !important;
  }

  .mb-xxl-n10 {
    margin-bottom: -5rem !important;
  }

  .mb-xxl-n11 {
    margin-bottom: -6rem !important;
  }

  .mb-xxl-n12 {
    margin-bottom: -7rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -2rem !important;
  }

  .ms-xxl-n6 {
    margin-left: -2.5rem !important;
  }

  .ms-xxl-n7 {
    margin-left: -3rem !important;
  }

  .ms-xxl-n8 {
    margin-left: -3.5rem !important;
  }

  .ms-xxl-n9 {
    margin-left: -4rem !important;
  }

  .ms-xxl-n10 {
    margin-left: -5rem !important;
  }

  .ms-xxl-n11 {
    margin-left: -6rem !important;
  }

  .ms-xxl-n12 {
    margin-left: -7rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 2rem !important;
  }

  .p-xxl-6 {
    padding: 2.5rem !important;
  }

  .p-xxl-7 {
    padding: 3rem !important;
  }

  .p-xxl-8 {
    padding: 3.5rem !important;
  }

  .p-xxl-9 {
    padding: 4rem !important;
  }

  .p-xxl-10 {
    padding: 5rem !important;
  }

  .p-xxl-11 {
    padding: 6rem !important;
  }

  .p-xxl-12 {
    padding: 7rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xxl-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xxl-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xxl-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xxl-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xxl-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-xxl-11 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-xxl-12 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xxl-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xxl-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xxl-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xxl-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-xxl-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-xxl-12 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 2rem !important;
  }

  .pt-xxl-6 {
    padding-top: 2.5rem !important;
  }

  .pt-xxl-7 {
    padding-top: 3rem !important;
  }

  .pt-xxl-8 {
    padding-top: 3.5rem !important;
  }

  .pt-xxl-9 {
    padding-top: 4rem !important;
  }

  .pt-xxl-10 {
    padding-top: 5rem !important;
  }

  .pt-xxl-11 {
    padding-top: 6rem !important;
  }

  .pt-xxl-12 {
    padding-top: 7rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 2rem !important;
  }

  .pe-xxl-6 {
    padding-right: 2.5rem !important;
  }

  .pe-xxl-7 {
    padding-right: 3rem !important;
  }

  .pe-xxl-8 {
    padding-right: 3.5rem !important;
  }

  .pe-xxl-9 {
    padding-right: 4rem !important;
  }

  .pe-xxl-10 {
    padding-right: 5rem !important;
  }

  .pe-xxl-11 {
    padding-right: 6rem !important;
  }

  .pe-xxl-12 {
    padding-right: 7rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 2rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xxl-9 {
    padding-bottom: 4rem !important;
  }

  .pb-xxl-10 {
    padding-bottom: 5rem !important;
  }

  .pb-xxl-11 {
    padding-bottom: 6rem !important;
  }

  .pb-xxl-12 {
    padding-bottom: 7rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 2rem !important;
  }

  .ps-xxl-6 {
    padding-left: 2.5rem !important;
  }

  .ps-xxl-7 {
    padding-left: 3rem !important;
  }

  .ps-xxl-8 {
    padding-left: 3.5rem !important;
  }

  .ps-xxl-9 {
    padding-left: 4rem !important;
  }

  .ps-xxl-10 {
    padding-left: 5rem !important;
  }

  .ps-xxl-11 {
    padding-left: 6rem !important;
  }

  .ps-xxl-12 {
    padding-left: 7rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 2rem !important;
  }

  .gap-xxl-6 {
    gap: 2.5rem !important;
  }

  .gap-xxl-7 {
    gap: 3rem !important;
  }

  .gap-xxl-8 {
    gap: 3.5rem !important;
  }

  .gap-xxl-9 {
    gap: 4rem !important;
  }

  .gap-xxl-10 {
    gap: 5rem !important;
  }

  .gap-xxl-11 {
    gap: 6rem !important;
  }

  .gap-xxl-12 {
    gap: 7rem !important;
  }

  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xxl-5 {
    row-gap: 2rem !important;
  }

  .row-gap-xxl-6 {
    row-gap: 2.5rem !important;
  }

  .row-gap-xxl-7 {
    row-gap: 3rem !important;
  }

  .row-gap-xxl-8 {
    row-gap: 3.5rem !important;
  }

  .row-gap-xxl-9 {
    row-gap: 4rem !important;
  }

  .row-gap-xxl-10 {
    row-gap: 5rem !important;
  }

  .row-gap-xxl-11 {
    row-gap: 6rem !important;
  }

  .row-gap-xxl-12 {
    row-gap: 7rem !important;
  }

  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }

  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xxl-5 {
    column-gap: 2rem !important;
  }

  .column-gap-xxl-6 {
    column-gap: 2.5rem !important;
  }

  .column-gap-xxl-7 {
    column-gap: 3rem !important;
  }

  .column-gap-xxl-8 {
    column-gap: 3.5rem !important;
  }

  .column-gap-xxl-9 {
    column-gap: 4rem !important;
  }

  .column-gap-xxl-10 {
    column-gap: 5rem !important;
  }

  .column-gap-xxl-11 {
    column-gap: 6rem !important;
  }

  .column-gap-xxl-12 {
    column-gap: 7rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.325rem !important;
  }

  .fs-2 {
    font-size: 1.86rem !important;
  }

  .fs-3 {
    font-size: 1.6275rem !important;
  }

  .fs-4 {
    font-size: 1.395rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-inline-grid {
    display: inline-grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #ffffff;
  z-index: 99999;
}
.preloader .spinner-border {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.display-1,
.display-2,
.display-3,
.display-4 {
  color: #0f0d1d;
  letter-spacing: -0.02em;
}
.white-text .display-1,
.white-text .display-2,
.white-text .display-3,
.white-text .display-4 {
  color: #ffffff;
}

h1, .h1 {
  margin-bottom: 1rem;
  line-height: 1.2;
}

@media (min-width: 768px) {
  .display-1,
.display-2,
.display-3,
.display-4 {
    margin-bottom: 1.5rem;
  }
}

.text-white h1, .text-white h2, .text-white h3, .text-white h4, .text-white h5, .text-white h6,
.text-white .h1, .text-white .h2, .text-white .h3, .text-white .h4, .text-white .h5, .text-white .h6 {
  color: #ffffff;
}

.white-link a {
  color: rgba(255, 255, 255, 0.6);
}
.white-link a:hover {
  color: #ffffff;
}

.dark-link a {
  color: #726f84;
}
.dark-link a:hover {
  color: #0f0d1d;
}

mark,
.mark {
  color: inherit;
  padding: 0;
  background: 0 0;
  background-image: linear-gradient(120deg, rgba(6, 93, 143, 0.4) 0, rgba(6, 93, 143, 0.4) 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.3em;
  background-position: 0 80%;
  position: relative;
}

.line-height {
  line-height: 1.5;
}

@media (max-width: 767.98px) {
  .lead {
    font-size: 1rem;
  }
}
/* Font wight
--------------------*/
.font-w-100 {
  font-weight: 100;
}

.font-w-200 {
  font-weight: 200;
}

.font-w-300 {
  font-weight: 300;
}

.font-w-400 {
  font-weight: 400;
}

.font-w-500 {
  font-weight: 500;
}

.font-w-600 {
  font-weight: 600;
}

.font-w-700 {
  font-weight: 700;
}

.font-w-800 {
  font-weight: 800;
}

.font-w-900 {
  font-weight: 900;
}

/* Letter spacing
-------------------------------*/
.letter-spacing-1 {
  letter-spacing: 1px;
}

.letter-spacing-2 {
  letter-spacing: 2px;
}

.letter-spacing-3 {
  letter-spacing: 3px;
}

.letter-spacing-4 {
  letter-spacing: 4px;
}

.letter-spacing-5 {
  letter-spacing: 5px;
}

.letter-spacing-6 {
  letter-spacing: 6px;
}

.letter-spacing-7 {
  letter-spacing: 7px;
}

.letter-spacing-8 {
  letter-spacing: 8px;
}

.letter-spacing-9 {
  letter-spacing: 9px;
}

.letter-spacing-10 {
  letter-spacing: 10px;
}

.after-k:after {
  content: "K";
}

.after-p:after {
  content: "%";
}

.after-plus:after {
  content: "+";
}

button {
  outline: none !important;
}

.btn .btn--text:not(:last-child) {
  margin-right: 0.75em;
}
.btn .btn--text:not(:first-child) {
  margin-left: 0.75em;
}

.link-effect {
  position: relative;
  padding: 0;
  border: none;
  background: none;
}
.link-effect:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: auto;
  width: 100%;
  height: 1px;
  background: currentColor;
  transition: ease all 0.35s;
}
.link-effect:hover:after {
  width: 0;
  right: 0;
  left: auto;
}

.btn-primary-light {
  color: #065D8F;
  background-color: rgba(6, 93, 143, 0.2);
}
.btn-primary-light:hover {
  color: #ffffff;
  background-color: #065D8F;
}

.btn-secondary-light {
  color: #6c757d;
  background-color: rgba(108, 117, 125, 0.2);
}
.btn-secondary-light:hover {
  color: #ffffff;
  background-color: #6c757d;
}

.btn-success-light {
  color: #707D27;
  background-color: rgba(112, 125, 39, 0.2);
}
.btn-success-light:hover {
  color: #ffffff;
  background-color: #707D27;
}

.btn-info-light {
  color: #50b5ff;
  background-color: rgba(80, 181, 255, 0.2);
}
.btn-info-light:hover {
  color: #ffffff;
  background-color: #50b5ff;
}

.btn-warning-light {
  color: #ff9f1c;
  background-color: rgba(255, 159, 28, 0.2);
}
.btn-warning-light:hover {
  color: #ffffff;
  background-color: #ff9f1c;
}

.btn-danger-light {
  color: #ff5c75;
  background-color: rgba(255, 92, 117, 0.2);
}
.btn-danger-light:hover {
  color: #ffffff;
  background-color: #ff5c75;
}

.btn-light-light {
  color: #f1f6fd;
  background-color: rgba(241, 246, 253, 0.2);
}
.btn-light-light:hover {
  color: #ffffff;
  background-color: #f1f6fd;
}

.btn-black-light {
  color: #000;
  background-color: rgba(0, 0, 0, 0.2);
}
.btn-black-light:hover {
  color: #ffffff;
  background-color: #000;
}

.btn-dark-light {
  color: #0f0d1d;
  background-color: rgba(15, 13, 29, 0.2);
}
.btn-dark-light:hover {
  color: #ffffff;
  background-color: #0f0d1d;
}

.section {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
@media (min-width: 768px) {
  .section {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
@media (min-width: 992px) {
  .section {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
}

.section-heading {
  margin-bottom: 2.5rem;
}
@media (min-width: 768px) {
  .section-heading {
    margin-bottom: 2rem;
  }
}
@media (min-width: 992px) {
  .section-heading {
    margin-bottom: 3rem;
  }
}

.min-vh-50 {
  min-height: 50vh;
}

.min-vh-85 {
  min-height: 85vh;
}

.min-vh-65 {
  min-height: 65vh;
}

.min-h-150px {
  min-height: 150px;
}

.min-h-250px {
  min-height: 250px;
}

.min-h-350px {
  min-height: 350px;
}

.w-5px {
  width: 5px;
}

.w-10px {
  width: 10px;
}

.w-15px {
  width: 15px;
}

.w-25px {
  width: 25px;
}

.w-30px {
  width: 30px;
}

.w-35px {
  width: 35px;
}

.w-40px {
  width: 40px;
}

.w-45px {
  width: 45px;
}

.w-50px {
  width: 50px;
}

.w-55px {
  width: 55px;
}

.w-60px {
  width: 60px;
}

.w-65px {
  width: 65px;
}

.w-75px {
  width: 75px;
}

.w-80px {
  width: 80px;
}

.w-85px {
  width: 85px;
}

.w-90px {
  width: 90px;
}

.w-95px {
  width: 95px;
}

.w-100px {
  width: 100px;
}

.w-autopx {
  width: autopx;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-1px {
  height: 1px;
}

.h-2px {
  height: 2px;
}

.h-3px {
  height: 3px;
}

.h-4px {
  height: 4px;
}

.h-5px {
  height: 5px;
}

.h-6px {
  height: 6px;
}

.h-7px {
  height: 7px;
}

.h-8px {
  height: 8px;
}

.h-9px {
  height: 9px;
}

@media (min-width: 576px) {
  .w-sm-5 {
    width: 5% !important;
  }

  .w-sm-10 {
    width: 10% !important;
  }

  .w-sm-15 {
    width: 15% !important;
  }

  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-30 {
    width: 30% !important;
  }

  .w-sm-35 {
    width: 35% !important;
  }

  .w-sm-40 {
    width: 40% !important;
  }

  .w-sm-45 {
    width: 45% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-55 {
    width: 55% !important;
  }

  .w-sm-60 {
    width: 60% !important;
  }

  .w-sm-65 {
    width: 65% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-80 {
    width: 80% !important;
  }

  .w-sm-85 {
    width: 85% !important;
  }

  .w-sm-90 {
    width: 90% !important;
  }

  .w-sm-95 {
    width: 95% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  .w-sm-auto {
    width: auto !important;
  }
}
@media (min-width: 768px) {
  .w-md-5 {
    width: 5% !important;
  }

  .w-md-10 {
    width: 10% !important;
  }

  .w-md-15 {
    width: 15% !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .w-md-30 {
    width: 30% !important;
  }

  .w-md-35 {
    width: 35% !important;
  }

  .w-md-40 {
    width: 40% !important;
  }

  .w-md-45 {
    width: 45% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-55 {
    width: 55% !important;
  }

  .w-md-60 {
    width: 60% !important;
  }

  .w-md-65 {
    width: 65% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-80 {
    width: 80% !important;
  }

  .w-md-85 {
    width: 85% !important;
  }

  .w-md-90 {
    width: 90% !important;
  }

  .w-md-95 {
    width: 95% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  .w-md-auto {
    width: auto !important;
  }
}
@media (min-width: 992px) {
  .w-lg-5 {
    width: 5% !important;
  }

  .w-lg-10 {
    width: 10% !important;
  }

  .w-lg-15 {
    width: 15% !important;
  }

  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-30 {
    width: 30% !important;
  }

  .w-lg-35 {
    width: 35% !important;
  }

  .w-lg-40 {
    width: 40% !important;
  }

  .w-lg-45 {
    width: 45% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-55 {
    width: 55% !important;
  }

  .w-lg-60 {
    width: 60% !important;
  }

  .w-lg-65 {
    width: 65% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-80 {
    width: 80% !important;
  }

  .w-lg-85 {
    width: 85% !important;
  }

  .w-lg-90 {
    width: 90% !important;
  }

  .w-lg-95 {
    width: 95% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }

  .w-lg-auto {
    width: auto !important;
  }
}
@media (min-width: 1200px) {
  .w-xl-5 {
    width: 5% !important;
  }

  .w-xl-10 {
    width: 10% !important;
  }

  .w-xl-15 {
    width: 15% !important;
  }

  .w-xl-25 {
    width: 25% !important;
  }

  .w-xl-30 {
    width: 30% !important;
  }

  .w-xl-35 {
    width: 35% !important;
  }

  .w-xl-40 {
    width: 40% !important;
  }

  .w-xl-45 {
    width: 45% !important;
  }

  .w-xl-50 {
    width: 50% !important;
  }

  .w-xl-55 {
    width: 55% !important;
  }

  .w-xl-60 {
    width: 60% !important;
  }

  .w-xl-65 {
    width: 65% !important;
  }

  .w-xl-75 {
    width: 75% !important;
  }

  .w-xl-80 {
    width: 80% !important;
  }

  .w-xl-85 {
    width: 85% !important;
  }

  .w-xl-90 {
    width: 90% !important;
  }

  .w-xl-95 {
    width: 95% !important;
  }

  .w-xl-100 {
    width: 100% !important;
  }

  .w-xl-auto {
    width: auto !important;
  }
}
.card {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
}
.card > .list-group {
  border-top: none;
  border-bottom: none;
}
.card.no-shadow {
  box-shadow: none;
}

.card-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: none;
}

.list-group-item {
  font-size: 0.85rem;
}

.card-overlay:after {
  content: "";
  display: inline-block;
  vertical-align: top;
  padding-bottom: 120%;
}

.card-lg-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 992px) {
  .card-lg-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-lg-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-lg-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-lg-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-lg-group > .card:not(:last-child) .card-img-top,
.card-lg-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-lg-group > .card:not(:last-child) .card-img-bottom,
.card-lg-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-lg-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-lg-group > .card:not(:first-child) .card-img-top,
.card-lg-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-lg-group > .card:not(:first-child) .card-img-bottom,
.card-lg-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-md-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 768px) {
  .card-md-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-md-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-md-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-md-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-md-group > .card:not(:last-child) .card-img-top,
.card-md-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-md-group > .card:not(:last-child) .card-img-bottom,
.card-md-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-md-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-md-group > .card:not(:first-child) .card-img-top,
.card-md-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-md-group > .card:not(:first-child) .card-img-bottom,
.card-md-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.nav-tabs .nav-link {
  color: #726f84;
}

.nav-link {
  color: #5a5b75;
}
.nav-link.active {
  color: #101010;
}
.nav-link:hover {
  color: #101010;
}

.breadcrumb:not(.breadcrumb-no-border) {
  border: 1px solid #e2e8f0;
}

.text-gray-100 {
  color: #f7f7ff;
}

.text-gray-200 {
  color: #eff2f7;
}

.text-gray-300 {
  color: #e2e8f0;
}

.text-gray-400 {
  color: #cbd5e0;
}

.text-gray-500 {
  color: #a0aec0;
}

.text-gray-600 {
  color: #3a3a3a;
}

.text-gray-700 {
  color: #333333;
}

.text-gray-800 {
  color: #101010;
}

.text-gray-900 {
  color: #080808;
}

.text-white-65 {
  color: rgba(255, 255, 255, 0.65) !important;
}

/*	SVG fill
---------------------------*/
.svg_icon [fill]:not([fill=none]) {
  fill: currentColor;
}

.svg_img polygon,
.svg_img path,
.svg_img circle {
  fill: currentColor;
}

.bg-cover {
  background-size: cover;
}

.bg-right-center {
  background-position: center right;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-center {
  background-position: center;
}

.bg-black {
  background: #000;
}

.bg-gray-100 {
  background-color: #f7f7ff !important;
}

.bg-gray-200 {
  background-color: #eff2f7 !important;
}

.bg-gray-300 {
  background-color: #e2e8f0 !important;
}

.bg-gray-400 {
  background-color: #cbd5e0 !important;
}

.bg-gray-500 {
  background-color: #a0aec0 !important;
}

.bg-gray-600 {
  background-color: #3a3a3a !important;
}

.bg-gray-700 {
  background-color: #333333 !important;
}

.bg-gray-800 {
  background-color: #101010 !important;
}

.bg-gray-900 {
  background-color: #080808 !important;
}

.bg-primary-light {
  background-color: rgba(6, 93, 143, 0.2);
}

.bg-secondary-light {
  background-color: rgba(108, 117, 125, 0.2);
}

.bg-success-light {
  background-color: rgba(112, 125, 39, 0.2);
}

.bg-info-light {
  background-color: rgba(80, 181, 255, 0.2);
}

.bg-warning-light {
  background-color: rgba(255, 159, 28, 0.2);
}

.bg-danger-light {
  background-color: rgba(255, 92, 117, 0.2);
}

.bg-light-light {
  background-color: rgba(241, 246, 253, 0.2);
}

.bg-black-light {
  background-color: rgba(0, 0, 0, 0.2);
}

.bg-dark-light {
  background-color: rgba(15, 13, 29, 0.2);
}

.primary-after:after {
  background-color: #065D8F;
}

.secondary-after:after {
  background-color: #6c757d;
}

.success-after:after {
  background-color: #707D27;
}

.info-after:after {
  background-color: #50b5ff;
}

.warning-after:after {
  background-color: #ff9f1c;
}

.danger-after:after {
  background-color: #ff5c75;
}

.light-after:after {
  background-color: #f1f6fd;
}

.black-after:after {
  background-color: #000;
}

.dark-after:after {
  background-color: #0f0d1d;
}

.bg-primary-gradient {
  background: linear-gradient(180deg, #065D8F 50%, #043d5e 100%) repeat-x !important;
}

.bg-secondary-gradient {
  background: linear-gradient(180deg, #6c757d 50%, #545b62 100%) repeat-x !important;
}

.bg-success-gradient {
  background: linear-gradient(180deg, #707D27 50%, #4d561b 100%) repeat-x !important;
}

.bg-info-gradient {
  background: linear-gradient(180deg, #50b5ff 50%, #1d9fff 100%) repeat-x !important;
}

.bg-warning-gradient {
  background: linear-gradient(180deg, #ff9f1c 50%, #e88600 100%) repeat-x !important;
}

.bg-danger-gradient {
  background: linear-gradient(180deg, #ff5c75 50%, #ff294a 100%) repeat-x !important;
}

.bg-light-gradient {
  background: linear-gradient(180deg, #f1f6fd 50%, #c4d9f7 100%) repeat-x !important;
}

.bg-black-gradient {
  background: linear-gradient(180deg, #000 50%, black 100%) repeat-x !important;
}

.bg-dark-gradient {
  background: linear-gradient(180deg, #0f0d1d 50%, black 100%) repeat-x !important;
}

.bg-primary-gradient-opacity {
  background: linear-gradient(180deg, rgba(6, 93, 143, 0.2) 30%, #043d5e 100%) repeat-x !important;
}

.bg-secondary-gradient-opacity {
  background: linear-gradient(180deg, rgba(108, 117, 125, 0.2) 30%, #545b62 100%) repeat-x !important;
}

.bg-success-gradient-opacity {
  background: linear-gradient(180deg, rgba(112, 125, 39, 0.2) 30%, #4d561b 100%) repeat-x !important;
}

.bg-info-gradient-opacity {
  background: linear-gradient(180deg, rgba(80, 181, 255, 0.2) 30%, #1d9fff 100%) repeat-x !important;
}

.bg-warning-gradient-opacity {
  background: linear-gradient(180deg, rgba(255, 159, 28, 0.2) 30%, #e88600 100%) repeat-x !important;
}

.bg-danger-gradient-opacity {
  background: linear-gradient(180deg, rgba(255, 92, 117, 0.2) 30%, #ff294a 100%) repeat-x !important;
}

.bg-light-gradient-opacity {
  background: linear-gradient(180deg, rgba(241, 246, 253, 0.2) 30%, #c4d9f7 100%) repeat-x !important;
}

.bg-black-gradient-opacity {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 30%, black 100%) repeat-x !important;
}

.bg-dark-gradient-opacity {
  background: linear-gradient(180deg, rgba(15, 13, 29, 0.2) 30%, black 100%) repeat-x !important;
}

/* particles-box
---------------------------*/
.particles-box {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  opacity: 0.2;
  pointer-events: none;
}

/* Effect Section
------------------------*/
.effect-section {
  position: relative;
  overflow: hidden;
}
.effect-section .svg-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}
.effect-section .svg-top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}
.effect-section .svg-effect-1 {
  position: absolute;
  bottom: 0;
  left: 0;
  pointer-events: none;
  right: 0;
  width: 60vw;
}

.divider-right {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  pointer-events: none;
}
.divider-right svg {
  position: absolute;
  top: -50%;
  bottom: -50%;
  left: 0;
  height: 200%;
}

.divider-left {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  pointer-events: none;
  text-align: right;
}
.divider-left svg {
  position: absolute;
  top: -50%;
  bottom: -50%;
  right: 0;
  height: 200%;
}

.effect-radius-bg {
  position: absolute;
  top: 58%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
}
.effect-radius-bg > div {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 23rem;
  height: 23rem;
  border-radius: 100%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(227, 221, 246, 0.1)), color-stop(65%, rgba(227, 221, 246, 0.2)), to(rgba(227, 221, 246, 0.1)));
  background-image: -webkit-linear-gradient(top, rgba(227, 221, 246, 0.1) 0%, rgba(227, 221, 246, 0.2) 65%, rgba(227, 221, 246, 0.1) 100%);
  background-image: -o-linear-gradient(top, rgba(227, 221, 246, 0.1) 0%, rgba(227, 221, 246, 0.2) 65%, rgba(227, 221, 246, 0.1) 100%);
  background-image: linear-gradient(-180deg, rgba(227, 221, 246, 0.1) 0%, rgba(227, 221, 246, 0.2) 65%, rgba(227, 221, 246, 0.1) 100%);
  z-index: -1;
}
.effect-radius-bg .radius-1 {
  -webkit-transform: translate(-50%, -50%) scale(0.67);
  -ms-transform: translate(-50%, -50%) scale(0.67);
  transform: translate(-50%, -50%) scale(0.67);
}
.effect-radius-bg .radius-2 {
  -webkit-transform: translate(-50%, -50%) scale(1.05);
  -ms-transform: translate(-50%, -50%) scale(1.05);
  transform: translate(-50%, -50%) scale(1.05);
}
.effect-radius-bg .radius-3 {
  -webkit-transform: translate(-50%, -50%) scale(1.63);
  -ms-transform: translate(-50%, -50%) scale(1.63);
  transform: translate(-50%, -50%) scale(1.63);
}
.effect-radius-bg .radius-4 {
  -webkit-transform: translate(-50%, -50%) scale(2.1);
  -ms-transform: translate(-50%, -50%) scale(2.1);
  transform: translate(-50%, -50%) scale(2.1);
}
.effect-radius-bg .radius-x {
  will-change: transform;
  -webkit-animation: pulsate 3s infinite;
  animation: pulsate 3s infinite;
}

@-webkit-keyframes pulsate {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(0.5);
    transform: translate(-50%, -50%) scale(0.5);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(4);
    transform: translate(-50%, -50%) scale(4);
  }
}
@keyframes pulsate {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(0.5);
    transform: translate(-50%, -50%) scale(0.5);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(4);
    transform: translate(-50%, -50%) scale(4);
  }
}
/* After 50
------------------------*/
.after-50 {
  position: relative;
}
.after-50:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 2px;
}
.text-center .after-50:after {
  right: 0;
  margin: auto;
}

/* mouse
------------------------*/
.mouse {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
  margin: auto;
  display: inline-block;
  width: 26px;
  height: 46px;
  border-radius: 10rem;
  border: 2px solid currentColor;
}
.mouse:after {
  content: "";
  translate: -50%, 0;
  position: absolute;
  display: block;
  top: 25%;
  left: 50%;
  width: 6px;
  height: 6px;
  background: currentColor;
  border-radius: 50%;
  -webkit-animation: mouse 2s linear infinite;
  -moz-animation: mouse 2s linear infinite;
  animation: mouse 2s linear infinite;
}

@keyframes mouse {
  0% {
    opacity: 1;
    translate: -50%, 0;
  }
  100% {
    opacity: 0;
    translate: -50%, 20px;
  }
}
/* Phone Frame
------------------------*/
.phone-frame {
  position: relative;
  z-index: 3;
  display: inline-block;
}
.phone-frame .pf-inner {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 72%;
  height: 84%;
  margin: 10% 0 0 14%;
  border-radius: 5%;
}

@media (min-width: 992px) {
  .sticky-lg-top-header {
    top: 100px;
  }
}
@keyframes pulse-border {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}
.after-shadow {
  z-index: 1;
  position: relative;
}
.after-shadow:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
  pointer-events: none;
  background: currentColor;
  z-index: -1;
}

img {
  max-width: 100%;
}

.object-fit {
  -o-object-fit: cover;
  object-fit: cover;
}

@media (min-width: 992px) {
  .img-lg-120 {
    max-width: 120%;
  }

  .img-lg-140 {
    max-width: 140%;
  }
}
@media (min-width: 768px) {
  .img-md-120 {
    max-width: 120%;
  }

  .img-md-140 {
    max-width: 140%;
  }
}
.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  border-radius: 0.375rem;
  width: 3rem;
  height: 3rem;
}
.icon svg,
.icon i {
  font-size: 1.125rem;
}
.icon .svg_icon {
  width: 1.85rem;
  height: 1.85rem;
}
.icon-sm {
  width: 2rem;
  height: 2rem;
}
.icon-sm svg,
.icon-sm i {
  font-size: 0.75rem;
}
.icon-sm .svg_icon {
  width: 0.85rem;
  height: 0.85rem;
}
.icon-xl {
  width: 5rem;
  height: 5rem;
}
.icon-xl svg,
.icon-xl i {
  font-size: 2.25rem;
}
.icon-xl .svg_icon {
  width: 3rem;
  height: 3rem;
}
.icon-lg {
  width: 4rem;
  height: 4rem;
}
.icon-lg svg,
.icon-lg i {
  font-size: 1.75rem;
}
.icon-lg .svg_icon {
  width: 2.25rem;
  height: 2.25rem;
}
.icon-xs {
  width: 1rem;
  height: 1rem;
}
.icon-xs svg,
.icon-xs i {
  font-size: 0.5rem;
}
.icon-xs .svg_icon {
  width: 0.75rem;
  height: 0.75rem;
}

.only-icon {
  display: inline-flex;
}
.only-icon svg,
.only-icon i {
  font-size: 2.2rem;
}
.only-icon .svg_icon {
  width: 2rem;
  height: 2rem;
}
.only-icon-sm svg,
.only-icon-sm i {
  font-size: 1rem;
}
.only-icon-sm .svg_icon {
  width: 1rem;
  height: 1rem;
}
.only-icon-xl svg,
.only-icon-xl i {
  font-size: 2.25rem;
}
.only-icon-xl .svg_icon {
  width: 4.5rem;
  height: 4.5rem;
}
.only-icon-lg svg,
.only-icon-lg i {
  font-size: 3rem;
}
.only-icon-lg .svg_icon {
  width: 3.5rem;
  height: 3.5rem;
}

.icon-primary {
  color: #ffffff;
  background-color: #065D8F;
}

.icon-secondary {
  color: #ffffff;
  background-color: #6c757d;
}

.icon-success {
  color: #ffffff;
  background-color: #707D27;
}

.icon-info {
  color: #ffffff;
  background-color: #50b5ff;
}

.icon-warning {
  color: #ffffff;
  background-color: #ff9f1c;
}

.icon-danger {
  color: #ffffff;
  background-color: #ff5c75;
}

.icon-light {
  color: #0f0d1d;
  background-color: #f1f6fd;
}

.icon-black {
  color: #ffffff;
  background-color: #000;
}

.icon-dark {
  color: #ffffff;
  background-color: #0f0d1d;
}

a.icon-primary:hover {
  color: #ffffff;
  background-color: #043d5e;
}

a.icon-secondary:hover {
  color: #ffffff;
  background-color: #545b62;
}

a.icon-success:hover {
  color: #ffffff;
  background-color: #4d561b;
}

a.icon-info:hover {
  color: #ffffff;
  background-color: #1d9fff;
}

a.icon-warning:hover {
  color: #ffffff;
  background-color: #e88600;
}

a.icon-danger:hover {
  color: #ffffff;
  background-color: #ff294a;
}

a.icon-light:hover {
  color: #0f0d1d;
  background-color: #c4d9f7;
}

a.icon-black:hover {
  color: #ffffff;
  background-color: black;
}

a.icon-dark:hover {
  color: #ffffff;
  background-color: black;
}

.icon-primary-light {
  color: #065D8F;
  background-color: rgba(6, 93, 143, 0.2);
}

.icon-secondary-light {
  color: #6c757d;
  background-color: rgba(108, 117, 125, 0.2);
}

.icon-success-light {
  color: #707D27;
  background-color: rgba(112, 125, 39, 0.2);
}

.icon-info-light {
  color: #50b5ff;
  background-color: rgba(80, 181, 255, 0.2);
}

.icon-warning-light {
  color: #ff9f1c;
  background-color: rgba(255, 159, 28, 0.2);
}

.icon-danger-light {
  color: #ff5c75;
  background-color: rgba(255, 92, 117, 0.2);
}

.icon-light-light {
  color: #f1f6fd;
  background-color: rgba(241, 246, 253, 0.2);
}

.icon-black-light {
  color: #000;
  background-color: rgba(0, 0, 0, 0.2);
}

.icon-dark-light {
  color: #0f0d1d;
  background-color: rgba(15, 13, 29, 0.2);
}

.feather {
  width: 1em;
  height: 1em;
  fill: none;
  stroke: currentcolor;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.badge-primary-light {
  background: rgba(6, 93, 143, 0.2);
  color: #065D8F;
}

.badge-secondary-light {
  background: rgba(108, 117, 125, 0.2);
  color: #6c757d;
}

.badge-success-light {
  background: rgba(112, 125, 39, 0.2);
  color: #707D27;
}

.badge-info-light {
  background: rgba(80, 181, 255, 0.2);
  color: #50b5ff;
}

.badge-warning-light {
  background: rgba(255, 159, 28, 0.2);
  color: #ff9f1c;
}

.badge-danger-light {
  background: rgba(255, 92, 117, 0.2);
  color: #ff5c75;
}

.badge-light-light {
  background: rgba(241, 246, 253, 0.2);
  color: #f1f6fd;
}

.badge-black-light {
  background: rgba(0, 0, 0, 0.2);
  color: #000;
}

.badge-dark-light {
  background: rgba(15, 13, 29, 0.2);
  color: #0f0d1d;
}

.border-style {
  position: relative;
}
.border-style:after {
  content: "";
  display: block;
  width: 80%;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  height: 1px;
  background: radial-gradient(ellipse at center, #e2e8f0 0, rgba(255, 255, 255, 0) 75%);
}
.border-style.bottom:after {
  bottom: 0;
}
.border-style.top:after {
  top: 0;
}
.border-style.light:after {
  background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.2) 0, rgba(31, 45, 61, 0) 75%);
}

.separated {
  overflow: hidden;
}
.separated > * {
  box-shadow: -1px -1px 0px 0px #d9e2ef;
}

.divider {
  height: 1px;
  background: #000;
  display: block;
  width: 100%;
  margin: 0 auto;
}

.divider-light {
  height: 1px;
  background: #ffffff;
  display: block;
  width: 100%;
  margin: 0 auto;
}

.list-type-01 {
  margin: 0;
  padding: 0;
  list-style: none;
}
.list-type-01 li {
  position: relative;
  padding: 2px 0 2px 25px;
}
.list-type-01 li:after {
  content: "";
  width: 6px;
  height: 12px;
  position: absolute;
  top: 5px;
  left: 6px;
  color: #ffffff;
  border-right: 2px solid currentColor;
  border-bottom: 2px solid currentColor;
  transform: rotate(45deg);
}
.list-type-01 li:before {
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  top: 6px;
  left: 0;
  background: #065D8F;
  border-radius: 50%;
}
.list-type-01.white li:after {
  color: #065D8F;
}
.list-type-01.white li:before {
  background: #ffffff;
}

.list-type-02 {
  margin: 0;
  padding: 0;
  list-style: none;
}
.list-type-02 li {
  position: relative;
  padding: 3px 0 3px 20px;
}
.list-type-02 li:after {
  content: "";
  width: 5px;
  height: 9px;
  position: absolute;
  top: 10px;
  left: 3px;
  color: #065D8F;
  border-right: 2px solid currentColor;
  border-bottom: 2px solid currentColor;
  transform: rotate(45deg);
}
.list-type-02.white li:after {
  color: #ffffff;
}

.list-type-03 {
  margin: 0;
  padding: 0;
  list-style: none;
}
.list-type-03 li {
  position: relative;
  padding: 7px 0 8px 25px;
}
.list-type-03 li:after {
  content: "";
  width: 6px;
  height: 12px;
  position: absolute;
  top: 10px;
  left: 6px;
  color: #ffffff;
  border-right: 2px solid currentColor;
  border-bottom: 2px solid currentColor;
  transform: rotate(45deg);
}
.list-type-03 li:before {
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  top: 11px;
  left: 0;
  background: #065D8F;
  border-radius: 50%;
}
.list-type-03.white li:after {
  color: #0f0d1d;
}
.list-type-03.white li:before {
  background: #ffffff;
}

.list-type-04 {
  margin: 0;
  padding: 0;
  list-style: none;
}
.list-type-04 li {
  position: relative;
  padding: 9px 0 9px 25px;
}
.list-type-04 li + li {
  border-top: 1px solid #ddd;
}
.list-type-04 li i {
  position: absolute;
  top: 10;
  left: 0;
}

.list-type-05 {
  margin: 0;
  padding: 0;
  list-style: none;
}
.list-type-05 li {
  position: relative;
  padding: 9px 0;
}
.list-type-05 li + li {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

/* Mask
------------------------*/
.mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.mask-90 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 10%;
}

.mask-80 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 20%;
}

.mask-75 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 25%;
}

.mask-65 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 35%;
}

.mask-50 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 50%;
}

.mask-40 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 40%;
}

@media (min-width: 992px) {
  .mask-50vw {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
    width: calc(50vw + 15px);
  }
  .no-gutters .mask-50vw {
    width: 50vw;
  }
  .mask-50vw.left {
    right: -15px;
    left: auto;
  }
  .mask-50vw.right {
    left: -15px;
  }
}
@media (max-width: 991.98px) {
  .mask-50vw {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -15px;
    right: -15px;
    z-index: -1;
  }
}
.opacity-1 {
  opacity: 0.1;
}

.opacity-2 {
  opacity: 0.2;
}

.opacity-3 {
  opacity: 0.3;
}

.opacity-4 {
  opacity: 0.4;
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-6 {
  opacity: 0.6;
}

.opacity-7 {
  opacity: 0.7;
}

.opacity-8 {
  opacity: 0.8;
}

.opacity-9 {
  opacity: 0.9;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.z-index-5 {
  z-index: 5;
}

.z-index-6 {
  z-index: 6;
}

.z-index-7 {
  z-index: 7;
}

.z-index-8 {
  z-index: 8;
}

.z-index-9 {
  z-index: 9;
}

.z-index--1 {
  z-index: -1;
}

.z-index--2 {
  z-index: -2;
}

.owl-dots {
  text-align: center;
}
.owl-main-slider .owl-dots {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  font-size: 0px;
}
.owl-dots .owl-dot {
  display: inline-block;
  vertical-align: top;
  width: 15px;
  height: 15px;
  background: #ffffff;
  border: 1px solid #065D8F;
  transition: ease all 0.55s;
  border-radius: 50%;
  margin: 0 5px;
}
.owl-carousel-white .owl-dots .owl-dot {
  border-color: #ffffff;
  background: none;
}
.owl-dots .owl-dot.active {
  background: #065D8F;
}
.owl-carousel-white .owl-dots .owl-dot.active {
  background: #ffffff;
}

.owl-carousel .owl-item img {
  width: auto;
  max-width: 100%;
}

.owl-no-overflow .owl-stage-outer {
  overflow: inherit;
}

.owl-nav .owl-next,
.owl-nav .owl-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 25px;
  height: 40px;
  margin: auto;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #0f0d1d;
  background: #ffffff;
}
.owl-nav .owl-next i,
.owl-nav .owl-prev i {
  line-height: inherit;
}
.owl-nav-arrow-bottom .owl-nav .owl-next,
.owl-nav-arrow-bottom .owl-nav .owl-prev {
  position: relative;
  display: inline-block;
  margin-right: 6px;
  width: 30px;
  height: 30px;
  background: #0f0d1d;
  color: #ffffff;
  border-radius: 50%;
  padding: 0;
  line-height: 30px;
  font-size: 13px;
}
.owl-nav-arrow-bottom .owl-nav .owl-next:hover,
.owl-nav-arrow-bottom .owl-nav .owl-prev:hover {
  background: #065D8F;
}
.owl-nav-rounded .owl-nav .owl-next,
.owl-nav-rounded .owl-nav .owl-prev {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  font-size: 22px;
}
.owl-nav-rounded-sm .owl-nav .owl-next,
.owl-nav-rounded-sm .owl-nav .owl-prev {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 12px;
}
.owl-nav-dark .owl-nav .owl-next,
.owl-nav-dark .owl-nav .owl-prev {
  color: #ffffff;
  background: #0f0d1d;
}
.owl-nav-arrow-only .owl-nav .owl-next,
.owl-nav-arrow-only .owl-nav .owl-prev {
  background: none;
  box-shadow: none;
  font-size: 28px;
}
.owl-nav .owl-next {
  right: 0;
}
.owl-nav-rounded .owl-nav .owl-next {
  right: 20px;
}
.owl-nav .owl-prev {
  left: 0;
}
.owl-nav-rounded .owl-nav .owl-prev {
  left: 20px;
}

/*---------------------------
 Portfolio Start
------------------------------*/
.portfolio-content .grid-item {
  float: left;
}

/*Portfolio Filter*/
.portfolio-filter-01 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.portfolio-filter-01 .filter li {
  cursor: pointer;
  margin: 0 15px;
  color: #0f0d1d;
  position: relative;
  padding: 5px 0;
  font-weight: 500;
  font-size: 16px;
  line-height: normal;
}
.portfolio-filter-01 .filter li:after {
  content: "";
  width: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 2px;
  background: #065D8F;
  position: absolute;
  transition: ease all 0.55s;
}
.portfolio-filter-01 .filter li:last-child {
  margin-right: 0;
}
.portfolio-filter-01 .filter li:first-child {
  margin-left: 0;
}
.portfolio-filter-01 .filter li.active:after {
  width: 100%;
}

.page-heading {
  padding-top: 13rem;
  padding-bottom: 6rem;
  background-size: cover;
  position: relative;
}
.header-height + main .page-heading {
  padding-top: 6rem;
}
.page-heading .container {
  position: relative;
  z-index: 1;
}
.page-heading .breadcrumb {
  border: none;
  padding: 0;
}
.page-heading .breadcrumb.light .breadcrumb-item {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
}
.page-heading .breadcrumb.light .breadcrumb-item:before {
  color: rgba(255, 255, 255, 0.2);
}
.page-heading .breadcrumb.light .breadcrumb-item a {
  color: #ffffff;
}

article h4, article .h4,
article h5,
article .h5 {
  margin-bottom: 1.5rem;
}
article h4:not(:first-child), article .h4:not(:first-child),
article h5:not(:first-child),
article .h5:not(:first-child) {
  margin-top: 2rem;
}
article p {
  line-height: 1.9;
}
article figure {
  margin: 3rem 0;
}
article figure + h4, article figure + .h4,
article figure + h5,
article figure + .h5 {
  margin-top: 0 !important;
}
article blockquote {
  border-left: 5px solid #e2e8f0;
  padding-left: 2rem;
  margin-top: 2rem;
  margin-bottom: 3rem;
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
article blockquote + h4, article blockquote + .h4,
article blockquote + h5,
article blockquote + .h5 {
  margin-top: 0 !important;
}

.laptop-screen {
  position: relative;
}
.laptop-screen .laptop-screen-inner {
  position: absolute;
  width: 79.3%;
  left: 10.5%;
  top: 7.6%;
  height: 80.5%;
  border: 1px solid #e2e8f0;
  border-radius: 10px;
  overflow: hidden;
}
.laptop-screen .laptop-screen-inner img {
  -o-object-fit: cover;
  object-fit: cover;
}

.mobile-screen {
  position: relative;
  border: 10px solid #0f0d1d;
  border-radius: 28px;
}
.mobile-screen .mobile-hidden {
  opacity: 0;
  visibility: hidden;
}
.mobile-screen .mobile-screen-in {
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  overflow: hidden;
  border-radius: 18px;
}
.mobile-screen .mobile-screen-in img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.avatar {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  font-weight: 600;
  height: 3.125rem;
  width: 3.125rem;
  overflow: hidden;
}
.avatar-rounded {
  border-radius: 0.375rem;
}
.avatar-circle {
  border-radius: 50%;
}
.avatar-sm {
  height: 2.4375rem;
  width: 2.4375rem;
}
.avatar-lg {
  height: 4rem;
  width: 4rem;
}
.avatar-xl {
  height: 6rem;
  width: 6rem;
}
.avatar-xxl {
  height: 9rem;
  width: 9rem;
}
.avatar-group .avatar {
  margin-left: -1.25rem;
}
.avatar-group .avatar:hover {
  z-index: 1;
}
.avatar-parent {
  display: inline-block;
  position: relative;
}
.avatar-child {
  position: absolute;
  bottom: -0.45rem;
  border: 2px solid #ffffff;
  left: 0;
  right: 0;
  margin: auto;
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 50%;
}

.form-group {
  margin-bottom: 1rem;
}

.rd-mailform .has-error .invalid-feedback {
  display: block;
}
.rd-mailform .snackbars {
  border: 1px solid #eff2f7;
  background: #eff2f7;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  display: none;
}
.rd-mailform .snackbars.active {
  display: block;
}
.rd-mailform .snackbars.error {
  border: 1px solid #ff768b;
  background: #ff667e;
  color: #ff5c75;
}
.rd-mailform .snackbars.success {
  border: 1px solid #81902d;
  background: #778529;
  color: #707D27;
}
.rd-mailform .snackbars p {
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;
}
.rd-mailform .snackbars p .s-icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
}

.media {
  display: flex;
}
.media .media-body {
  flex: 1;
}

.hover-top {
  transition: ease-in-out all 0.35s;
  position: relative;
  top: 0;
}
.hover-top:hover {
  top: -10px;
}

.hover-top-in .hover-top--in {
  transition: ease-in-out all 0.35s;
  position: relative;
  top: 0;
}
.hover-top-in:hover .hover-top--in {
  top: -10px;
}

.theme-hover-bg:hover {
  background: #065D8F !important;
}
.theme-hover-bg:hover * {
  color: #ffffff;
}
.theme-hover-bg:hover .text-primary {
  color: #ffffff !important;
}

.hover-box-01 {
  position: relative;
}
.hover-box-01 .hover-box-in {
  position: absolute;
  bottom: 0;
  top: auto;
  left: 0;
  right: 0;
  height: 0;
  transition: ease all 0.35s;
}
.hover-box-01:hover .hover-box-in {
  height: 100%;
  bottom: auto;
  top: 0;
}

.hover-box-02 {
  position: relative;
  overflow: hidden;
}
.hover-box-02 .hover-box-in {
  opacity: 0;
  transition: ease all 0.35s;
  bottom: -20px;
  left: 0;
  right: 0;
  position: absolute;
  margin: 10px;
  padding: 15px;
}
.hover-box-02 .icon {
  position: absolute;
  top: -20px;
  right: 0;
  margin: 10px;
  opacity: 0;
  transition: ease all 0.35s;
}
.hover-box-02:hover .hover-box-in {
  bottom: 0;
  opacity: 1;
}
.hover-box-02:hover .icon {
  top: 0;
  opacity: 1;
}

.flip-box {
  position: relative;
  overflow: hidden;
}
.flip-box .flip-box-img {
  transition: ease all 0.35s;
  transform: rotateY(0deg);
}
.flip-box .flip-box-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: ease all 0.35s;
  transform: rotateY(180deg);
}
.flip-box:hover .flip-box-img {
  transform: rotateY(180deg);
  opacity: 0;
}
.flip-box:hover .flip-box-content {
  opacity: 1;
  transform: rotateY(0deg);
}

.line-hover {
  position: relative;
  overflow: hidden;
}
.line-hover:before, .line-hover:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  transition: ease all 0.3s;
  pointer-events: none;
}
.line-hover:before {
  top: 0;
  left: 0;
  border-left: 5px solid #065D8F;
  border-top: 5px solid #065D8F;
}
.line-hover:after {
  bottom: 0;
  right: 0;
  border-right: 5px solid #065D8F;
  border-bottom: 5px solid #065D8F;
}
.line-hover:hover:after, .line-hover:hover:before {
  opacity: 1;
  width: 100%;
  height: 100%;
}

.client-hover {
  position: relative;
}
.client-hover .client-bg:after {
  content: "";
  padding-bottom: 100%;
  display: inline-block;
  vertical-align: top;
}
.client-hover .client-info {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.client-hover .client-info p {
  opacity: 0;
  margin-bottom: 0;
  height: 0;
  transition: ease all 0.35s;
}
.client-hover:hover .client-info p {
  height: 100px;
  opacity: 1;
}

.feature-hover-1 {
  position: relative;
  overflow: hidden;
}
.feature-hover-1 .feature-content {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  padding: 10%;
  opacity: 0;
  transition: ease all 0.35s;
}
.feature-hover-1:hover .feature-content {
  opacity: 1;
  left: 0;
}

.feature-hover-2 {
  position: relative;
  overflow: hidden;
}
.feature-hover-2 .feature-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.feature-hover-2 .feature-content .btn-bar {
  position: relative;
  margin-bottom: -50px;
  opacity: 0;
  transition: ease all 0.35s;
}
.feature-hover-2:hover .feature-content .btn-bar {
  opacity: 1;
  margin-bottom: 0;
}

.feature-hover-3 {
  position: relative;
  overflow: hidden;
}
.feature-hover-3 .feature-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 8%;
  padding-top: 12%;
  margin-bottom: -30px;
  opacity: 0;
  background: rgba(15, 13, 29, 0.6);
  transition: ease all 0.35s;
}
.feature-hover-3:hover .feature-content {
  opacity: 1;
  padding-top: 8%;
}

.feature-box-1 {
  position: relative;
}
.feature-box-1:not(.last):after {
  content: "";
  position: absolute;
  top: 24%;
  width: 60px;
  right: -30px;
  height: 1px;
  background: #000;
  opacity: 0.2;
  z-index: 1;
}
@media (max-width: 991.98px) {
  .feature-box-1[class*=col-lg]:nth-child(2n+2):after {
    opacity: 0;
  }
}
@media (max-width: 767.98px) {
  .feature-box-1[class*=col-md]:after {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .feature-box-1[class*=col-sm]:after {
    display: none;
  }
}

.skill-lt:not(:last-child) {
  margin-bottom: 30px;
}
.skill-lt h6, .skill-lt .h6 {
  font-size: 16px;
  margin: 0 0 10px;
}
.skill-lt .skill-bar {
  position: relative;
  background: #eee;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
.skill-lt .skill-bar .skill-bar-in {
  width: 0px;
  transition: cubic-bezier(0.4, 0, 1, 1) all 0.95s;
  height: 5px;
  position: relative;
}
.skill-lt .skill-bar .skill-bar-in span {
  position: absolute;
  right: 0;
  top: -30px;
  font-size: 11px;
  background: #0f0d1d;
  border-radius: 3px 3px 0 3px;
  padding: 2px 7px;
  font-weight: 600;
  color: #ffffff;
}
.skill-lt .skill-bar .skill-bar-in span:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 6px solid #0f0d1d;
  border-left: 8px solid transparent;
  position: absolute;
  right: 6px;
  bottom: -6px;
}
.skill-lt.md .skill-bar .skill-bar-in {
  height: 8px;
}
.skill-lt.lg .skill-bar .skill-bar-in {
  height: 10px;
}
.skill-lt.light .skill-bar {
  background: rgba(0, 0, 0, 0.5);
}

.our-team .team-img {
  position: relative;
}
.our-team .team-img img {
  transform: scale(1);
  transition: ease all 0.33s;
}
.our-team .team-desc {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 1;
  transition: ease all 0.33s;
}
.our-team:hover .team-img:after {
  opacity: 0.6;
}
.our-team:hover .team-img img {
  transform: scale(1.1);
}
.our-team:hover .team-desc {
  opacity: 1;
}

.our-team-01 .team-img {
  overflow: hidden;
  position: relative;
}
.our-team-01 .team-icon {
  background: #ffffff;
  position: absolute;
  padding: 8px 10px;
  bottom: 10px;
  left: -60px;
  transition: ease all 0.35s;
}
.our-team-01:hover .team-icon {
  left: 10px;
}

/*------------------
*   Tab style 01
-------------------*/
.tab-style-1 .nav {
  border-bottom: 1px solid #eff2f7;
}
@media (max-width: 767.98px) {
  .tab-style-1 .nav {
    margin-bottom: 15px;
  }
}
.tab-style-1 .nav-item {
  margin: 0 10px;
}
@media (max-width: 767.98px) {
  .tab-style-1 .nav-item {
    margin: 0 5px;
  }
}
.tab-style-1 .nav-item a {
  color: #0f0d1d;
  position: relative;
  padding: 12px 15px;
  text-align: center;
  border-bottom: none;
  width: 100%;
  display: block;
}
@media (max-width: 767.98px) {
  .tab-style-1 .nav-item a {
    padding: 10px 12px;
  }
}
.tab-style-1 .nav-item a:after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 0;
  height: 1px;
  background: #065D8F;
  transition: ease-in-out all 0.55s;
}
.tab-style-1 .nav-item a.active {
  color: #065D8F;
}
.tab-style-1 .nav-item a.active:after {
  width: 100%;
}
@media (min-width: 768px) {
  .tab-style-1 .nav-item .icon {
    margin-bottom: 8px;
  }
}
.tab-style-1 .nav-item .icon svg {
  height: 38px;
  width: 38px;
}
@media (max-width: 767.98px) {
  .tab-style-1 .nav-item .icon svg {
    height: 30px;
    width: 30px;
  }
}
.tab-style-1 .nav-item span {
  font-weight: 700;
  font-size: 16px;
  width: 100%;
  display: block;
}
@media (max-width: 767.98px) {
  .tab-style-1 .nav-item span {
    display: none;
  }
}
.tab-style-1 .tab-pane {
  padding-top: 20px;
}

/*------------------
*   Tab style 02
-------------------*/
.tab-style-2 .nav {
  margin-bottom: 15px;
  border: none;
}
.tab-style-2 .nav .nav-item {
  margin-right: 18px;
}
.tab-style-2 .nav .nav-item a {
  padding: 8px 0;
  font-weight: 600;
  color: #0f0d1d;
  position: relative;
  display: block;
}
.tab-style-2 .nav .nav-item a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0px;
  transition: ease-in-out all 0.55s;
  height: 2px;
  background: #065D8F;
}
.tab-style-2 .nav .nav-item a.active {
  color: #065D8F;
}
.tab-style-2 .nav .nav-item a.active:after {
  width: 100%;
}

/*------------------
*   Tab style 03
-------------------*/
.tab-style-3 .nav {
  background: #ffffff;
  border: none;
}
.tab-style-3 .nav a {
  display: block;
  padding: 15px 10px 18px;
  line-height: normal;
  position: relative;
  color: #726f84;
}
.tab-style-3 .nav a:after, .tab-style-3 .nav a:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transition: ease-in-out all 0.25s;
  margin: auto;
}
.tab-style-3 .nav a:after {
  width: 0px;
  height: 3px;
  background: #065D8F;
}
.tab-style-3 .nav a:before {
  width: 20px;
  background: #ffffff;
  border-radius: 50%;
  z-index: 1;
  height: 20px;
  bottom: -8px;
  box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.05), 0 0.5rem 1.2rem rgba(0, 0, 0, 0.1);
}
.tab-style-3 .nav a.active {
  color: #065D8F;
}
.tab-style-3 .nav a.active:after {
  width: 100%;
}
.tab-style-3 .nav .tab-icon {
  margin-bottom: 10px;
}
.tab-style-3 .nav .tab-icon i {
  font-size: 32px;
}
.tab-style-3 .nav .tab-icon svg {
  width: 35px;
  height: 35px;
}
.tab-style-3 .nav span {
  font-size: 16px;
  font-weight: 500;
  color: #0f0d1d;
}
@media (max-width: 767.98px) {
  .tab-style-3 .nav span {
    display: none;
  }
}
.tab-style-3 .tab-content {
  padding-top: 30px;
}

/*------------------
*   Tab style 04
-------------------*/
.tab-style-4 .nav {
  margin-bottom: 15px;
  border-bottom: 2px solid #eff2f7;
}
.tab-style-4 .nav .nav-item .tb-icon {
  display: inline-block;
  vertical-align: middle;
  font-size: 30px;
  color: #065D8F;
}
.tab-style-4 .nav .nav-item span {
  margin-left: 10px;
  vertical-align: middle;
}
@media (max-width: 575.98px) {
  .tab-style-4 .nav .nav-item span {
    display: none;
  }
}
.tab-style-4 .nav .nav-item a {
  padding: 15px 0;
  font-weight: 600;
  color: #0f0d1d;
  position: relative;
  display: block;
}
.tab-style-4 .nav .nav-item a:after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: auto;
  right: 0;
  width: 0px;
  transition: ease-in-out all 0.55s;
  height: 3px;
  background: #065D8F;
}
.tab-style-4 .nav .nav-item a.active:after {
  left: 0;
  right: auto;
  width: 100%;
}

/*------------------
*   Tab style 05
-------------------*/
.tab-style-5 .nav-tabs {
  border: none;
}
.tab-style-5 .nav-tabs .nav-item {
  margin: 5px;
}
.tab-style-5 .nav-tabs .nav-item > a {
  position: relative;
  padding: 15px 10px;
  font-weight: 600;
  color: #0f0d1d;
  border: none;
  border-radius: 10px;
  background: #ffffff;
  border: 1px solid #eff2f7;
  background: #ffffff;
}
.tab-style-5 .nav-tabs .nav-item > a.active {
  color: #ffffff;
  background: #065D8F;
}

/*------------------
*   Tab style 05
-------------------*/
.tab-style-6 .nav-tabs {
  border: none;
}
.tab-style-6 .nav-tabs .nav-item:not(:last-child) {
  margin-bottom: 20px;
}
.tab-style-6 .nav-tabs .nav-item h6, .tab-style-6 .nav-tabs .nav-item .h6 {
  font-weight: 700;
  font-size: 18px;
  color: #0f0d1d;
}
.tab-style-6 .nav-tabs .nav-item p {
  margin: 0;
}
.tab-style-6 .nav-tabs .nav-item > a {
  position: relative;
  padding: 20px;
  color: #726f84;
  border: none;
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid #eff2f7;
}
.tab-style-6 .nav-tabs .nav-item > a [class*=only-icon] {
  color: #065D8F;
}
.tab-style-6 .nav-tabs .nav-item > a.active {
  color: rgba(255, 255, 255, 0.8);
  background: #065D8F;
}
.tab-style-6 .nav-tabs .nav-item > a.active [class*=only-icon] {
  color: #ffffff;
}
.tab-style-6 .nav-tabs .nav-item > a.active h6, .tab-style-6 .nav-tabs .nav-item > a.active .h6 {
  color: #ffffff;
}

.shadow-sm-hover:hover {
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.15) !important;
}

.shadow-hover:hover {
  box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.05), 0 0.625rem 1.5rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg-hover:hover {
  box-shadow: 0 0.5rem 1.2rem rgba(0, 0, 0, 0.1), 0 2rem 3rem rgba(0, 0, 0, 0.15) !important;
}

.dropdown-header {
  font-weight: 700;
  font-size: 0.95rem;
}

.dropdown-toggle:after {
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  font-size: 0.5rem;
  margin-left: 0.35rem;
  font-weight: 900;
  vertical-align: middle;
  border: none;
}

.after-percentage:after {
  content: "%";
}

.pie_chart_in {
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.pie_chart_in .middle {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/*:not(.toast-header):not(.alert)>.btn-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 1030;
    display: inline-flex;
}*/
.modal.px-modal-right .px-modal-vertical {
  transform: translateX(100%);
  height: 100%;
  max-width: 400px;
  margin: 0 0 0 auto;
  transition: transform 0.3s ease-out;
}
.modal.px-modal-right .px-modal-vertical .modal-content {
  height: inherit;
  overflow-y: auto;
  border: none;
  border-radius: 0;
}
.modal.px-modal-right.show {
  padding-right: 0 !important;
}
.modal.px-modal-right.show .px-modal-vertical {
  transform: translateX(0);
}

.divider-title {
  width: 100%;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}
.divider-title:after, .divider-title:before {
  background: #e2e8f0;
  content: "";
  display: inline-block;
  height: 1px;
  margin: 0 16px 0 -100%;
  vertical-align: top;
  width: 50%;
}
.divider-title:after {
  margin: 0 -100% 0 16px;
}
.divider-title.white:after, .divider-title.white:before {
  background: rgba(255, 255, 255, 0.1);
}

.count-down-01 {
  display: flex;
  flex-wrap: wrap;
}
.count-down-01 > div {
  display: flex;
  margin-right: 15px;
  flex-direction: column;
  background: #ffffff;
  padding: 10px;
  border-radius: 5px;
  min-width: 120px;
}
.count-down-01 .count {
  font-size: 24px;
  color: #065D8F;
  font-weight: 600;
}
.count-down-01 .title {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.count-down-02 {
  display: flex;
  flex-wrap: wrap;
}
.count-down-02 > div {
  text-align: center;
  background: #f7f7ff;
  line-height: 1;
  padding: 10px 0;
  margin: 3px 7px 3px 0px;
}
.count-down-02 .count {
  font-size: 18px;
  color: #0f0d1d;
  display: inline-block;
  vertical-align: top;
  font-weight: 600;
  width: 100%;
}
.count-down-02 .title {
  font-size: 12px;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
}
body p {
  color: #101010 !important;
}
body label {
  color: #101010 !important;
  font-weight: 500 !important;
}

thead {
  color: #043B5C;
  font-weight: 700;
}
thead th {
  color: #043B5C;
}

.text-validation {
  font-size: 10px;
  color: firebrick;
}

/***COVID MESSAGE ***/
.h-350 {
  height: 350px !important;
  overflow: hidden;
  margin-bottom: 10px;
}

.mb-25 {
  margin-bottom: 25px;
}

/*------------------------------------
  Go To v1
------------------------------------*/
[class*=u-go-to] {
  display: none;
}

.u-go-to-v1 {
  width: 3.57143rem;
  height: 3.57143rem;
  display: block;
  background-color: rgba(255, 255, 255, 0.7);
  color: #a49da6;
  border-radius: 50%;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  transition: 0.3s ease-out;
  z-index: 11;
}

.u-go-to-v1 i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.u-go-to-v1:hover, .u-go-to-v1:focus:hover {
  text-decoration: none;
  color: #fff;
  background-color: #cecece;
}

.u-go-to-v1:focus {
  text-decoration: none;
  color: #a49da6;
  background-color: rgba(255, 255, 255, 0.7);
}

@media (max-width: 575px) {
  .u-go-to-v1 {
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8);
  }
}
/*------------------------------------
Tables v3
------------------------------------*/
.dataTables_scroll {
  border-bottom: 1px solid #e1eaea;
}

.dataTables_scroll [class*=u-table--v3] {
  margin-bottom: -1px;
}

.dataTables_scroll .mCSB_outside + .mCSB_scrollTools {
  top: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #e1eaea;
  margin-bottom: -1px;
}

.dataTables_scroll .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 8px;
  background-color: #bac9c9;
}

.dataTables_scroll .mCSB_scrollTools .mCSB_draggerRail {
  display: none;
}

.dataTables_scroll td:last-child,
.dataTables_scroll th:last-child {
  padding-right: 34px;
}

.dataTables_scrollHead,
.dataTables_scrollFoot {
  overflow: visible !important;
}

[class*=u-table--v3] {
  margin-bottom: 0;
}

[class*=u-table--v3] th,
[class*=u-table--v3] td {
  color: inherit;
  vertical-align: middle;
  padding: 1rem;
  border-top-color: #e1eaea !important;
  border-bottom-color: #e1eaea !important;
  border-left-color: #e1eaea !important;
  border-right-color: #e1eaea !important;
}

[class*=u-table--v3] th:first-child, [class*=u-table--v3] th.g-first-child,
[class*=u-table--v3] td:first-child,
[class*=u-table--v3] td.g-first-child {
  border-left: 1px solid;
}

[class*=u-table--v3] th:last-child, [class*=u-table--v3] th.g-last-child,
[class*=u-table--v3] td:last-child,
[class*=u-table--v3] td.g-last-child {
  border-right: 1px solid;
}

[class*=u-table--v3] thead th {
  font-weight: 400;
  border-bottom: none;
}

[class*=u-table--v3] td {
  font-weight: 300;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

[class*=u-table--v3] .opened + tr {
  background-color: #f5f9f9;
}

[class*=u-table--v3] tr:last-child td {
  border-bottom: 1px solid;
}

[class*=u-table--v3] .thead-light th,
[class*=u-table--v3] .thead-light td,
[class*=u-table--v3] .tfoot-light th,
[class*=u-table--v3] .tfoot-light td {
  color: #53585e;
  background-color: #f5f9f9;
  border-color: #f5f9f9;
}

[class*=u-table--v3].table-hover tbody tr:hover {
  background-color: rgba(245, 249, 249, 0.4);
}

[class*=u-table--v3].table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #f5f9f9;
}

[class*=u-table--v3] tfoot.u-tfoot-after-thead {
  display: table-header-group;
}

.u-table--v3--bordered th,
.u-table--v3--bordered td {
  border-left: 1px solid;
}

.u-table--v3--borderless th,
.u-table--v3--borderless td {
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border: none;
}

.u-table--v3--borderless th:first-child, .u-table--v3--borderless th.g-first-child,
.u-table--v3--borderless td:first-child,
.u-table--v3--borderless td.g-first-child {
  border-left: none;
}

.u-table--v3--borderless th:last-child, .u-table--v3--borderless th.g-last-child,
.u-table--v3--borderless td:last-child,
.u-table--v3--borderless td.g-last-child {
  border-right: none;
}

.u-table--v3--borderless tr:last-child td {
  border-bottom: none;
}

.u-table--v3--row-border-bottom td {
  border-bottom: 1px solid;
  border-bottom-color: #e1eaea !important;
}

.u-table--v3--row-border-bottom tr:last-child td {
  border-bottom: 1px solid;
  border-bottom-color: #e1eaea !important;
}

/*------------------------------------
  Tables v4
------------------------------------*/
[class*=u-table--v4] {
  margin-bottom: 0;
}

[class*=u-table--v4] th,
[class*=u-table--v4] td {
  color: inherit;
  vertical-align: middle;
  padding: 1.42857rem;
  border: none;
}

[class*=u-table--v4] th:first-child, [class*=u-table--v4] th.g-first-child,
[class*=u-table--v4] td:first-child,
[class*=u-table--v4] td.g-first-child {
  text-align: center;
}

[class*=u-table--v4] th {
  font-weight: 400;
  border-bottom: none !important;
}

[class*=u-table--v4] td {
  background-color: #f5f9f9;
  font-weight: 300;
  border-bottom: 2px solid #fff;
  padding-top: 1.14286rem;
  padding-bottom: 1.14286rem;
}

.u-table--v4--bordered th,
.u-table--v4--bordered td {
  border-left: 1px solid;
}

.icon-up-position {
  display: inline-block;
  position: fixed;
  bottom: 15px;
  left: 15px;
}

#skip a {
  display: block;
  position: absolute;
  left: -999px;
  top: -999px;
}

#skip a:focus {
  left: 0;
  top: 0;
  padding: 3px;
  background: #ffc;
  border: 1px solid #990000;
}

/*Logo show hide on scroll
-----------------------------------*/
.header-dark .logo-dark {
  display: none;
}

.fixed-header .navbar {
  background-color: #ffffff;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05), 0 1.5rem 2.2rem rgba(0, 0, 0, 0.1);
}
.fixed-header .logo-light {
  display: none;
}
.fixed-header .logo-dark {
  display: inline-flex;
}

.header-border-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.header-light .header-border-bottom {
  border-bottom: 1px solid rgba(15, 13, 29, 0.1);
}

/* Hamburger Menu
-----------------------------------*/
.px-nav-hamburger .btn-close {
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 1;
}
.px-nav-hamburger .hamburger-in {
  padding: 110px 15% 50px;
}
.px-nav-hamburger .hamburger-bottom {
  border-top: 1px solid rgba(15, 13, 29, 0.05);
  padding-top: 30px;
  text-align: center;
}
.px-nav-hamburger .hamburger-bottom a {
  font-weight: 600;
  color: #0f0d1d;
  text-decoration: none;
}
.px-nav-hamburger .nav .nav-item {
  position: relative;
}
.px-nav-hamburger .nav .nav-item + .nav-item {
  border-top: 1px solid rgba(15, 13, 29, 0.05);
}
.px-nav-hamburger .nav .nav-item .nav-link {
  font-weight: 600;
  padding: 12px 0;
}
.px-nav-hamburger .nav .nav-item .nav-link:hover {
  color: #065D8F;
}
.px-nav-hamburger .nav .nav-item .dropdown-toggle {
  position: absolute;
  top: 10px;
  right: 0;
  width: 25px;
  height: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #0f0d1d;
}
.px-nav-hamburger .nav .nav-item .dropdown-toggle:after {
  font-size: 1rem;
  content: "\f4fe";
  font-family: bootstrap-icons !important;
}
.px-nav-hamburger .nav .nav-item .list-unstyled {
  padding: 5px 0 15px 10px;
}
.px-nav-hamburger .nav .nav-item .list-unstyled li {
  padding: 3px 0;
}
.px-nav-hamburger .nav .nav-item .list-unstyled li a {
  font-size: 13px;
  color: #726f84;
}
.px-nav-hamburger .nav .nav-item .list-unstyled li a:hover {
  color: #0f0d1d;
}

/* Header Search
-----------------------------------*/
.px-search-full {
  z-index: 9999;
}
.px-search-full .search-close {
  position: fixed;
  top: 15px;
  right: 15px;
  width: 40px;
  height: 40px;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  color: #ffffff;
  background: #0f0d1d;
  border-radius: 50%;
}
.px-search-full .search-close:hover {
  background: #065D8F;
}

/* Header Header
-----------------------------------*/
.fixed-header .header-top {
  visibility: hidden;
  pointer-events: none;
}

/* Header Social
-----------------------------------*/
.header-social .h-social-link {
  padding: 0 10px;
  font-size: 14px;
  vertical-align: middle;
  color: #ffffff;
}
.header-social .h-social-link:hover {
  color: rgba(255, 255, 255, 0.6);
}
.fixed-header .header-social .h-social-link {
  color: #0f0d1d;
}
.fixed-header .header-social .h-social-link:hover {
  color: rgba(15, 13, 29, 0.6);
}
.header-light .header-social .h-social-link {
  color: #0f0d1d;
}
.header-light .header-social .h-social-link:hover {
  color: rgba(15, 13, 29, 0.6);
}

.navbar-dark .nav .nav-link {
  color: #ffffff;
}

.navbar-light .nav .nav-link {
  color: #0f0d1d;
}

/*Header link and dropdown
-----------------------------------*/
.header-main {
  transition: ease-out top 0.15s;
}
.header-main .navbar-toggler {
  border: none;
  outline: none;
  box-shadow: none;
  padding: 0;
}
.header-main .navbar-toggler .navbar-toggler-icon {
  width: 1.8em;
  height: 1.8em;
}
.header-main .navbar-nav > .nav-item {
  margin: 0 5px;
}
.header-main .navbar-nav > .nav-item > .nav-link {
  font-weight: 500;
  text-transform: none;
  font-size: 1rem;
}
.header-main .navbar-nav > .nav-item.dropdown > .nav-link:after {
  display: none;
}
.header-main .navbar-nav > .nav-item .mob-menu {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 52px;
  text-align: center;
  line-height: 52px;
  color: #0f0d1d;
}
.header-main .navbar-nav > .nav-item .mob-menu:after {
  display: none;
}
.header-main .navbar-nav > .nav-item .mob-menu:before {
  content: "\f078";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  font-size: 11px;
  color: currentColor;
}
.header-main .navbar-nav > .nav-item .mob-menu.open:before {
  content: "\f077";
  color: #065D8F;
}

/* Toggle bg color
-----------------------------------*/
/*.header-light {
	&.header-toggle {
		.navbar {
			background: $white;
		}
	}
}*/
/*.header-dark {
	&.header-toggle {
		&:not(.fixed-header) {
			.navbar {
				background: $dark;
			}
		}
	}
}*/
/* Dopdown menu 5 level
-----------------------------------*/
/*Responsive
-----------------------------------*/
/* Mobile Menu
-----------------------------------*/
/*Menu Hover & Header Fluid Space 
-----------------------------------*/
.header-main {
  /*Top spacing for mobile menu 
  -----------------------------------*/
}
@media (min-width: 1400px) {
  .header-main .navbar-expand-xxl .dropdown-mega-menu {
    width: 100%;
    background: #ffffff;
  }
  .header-main .navbar-expand-xxl > .container {
    position: relative;
  }
  .header-main .navbar-expand-xxl .dropdown-menu-md {
    min-width: 22rem;
  }
  .header-main .navbar-expand-xxl .dropdown-menu-lg {
    min-width: 35rem;
  }
  .header-main .navbar-expand-xxl .dropdown-menu-xl {
    min-width: 42rem;
  }
  .header-main .navbar-expand-xxl .navbar-nav > .nav-item {
    /*Submenu Mixin*/
  }
  .header-main .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link {
    line-height: 60px;
  }
  .header-main .navbar-expand-xxl .navbar-nav > .nav-item.dropdown > .nav-link:after {
    content: "\f078";
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    color: currentColor;
    margin-left: 8px;
    display: inline-block;
    font-size: 11px;
    border: none;
  }
  .header-main .navbar-expand-xxl .navbar-nav > .nav-item.dropdown > .dropdown-menu {
    left: 50%;
    transform: translate(-50%, 0);
    margin: 0;
  }
  .header-main .navbar-expand-xxl .navbar-nav > .nav-item.dropdown > .dropdown-menu.left {
    left: 0;
    transform: translate(0, 0);
  }
  .header-main .navbar-expand-xxl .navbar-nav > .nav-item.dropdown > .dropdown-menu.right {
    left: auto;
    right: 0;
    transform: translate(0, 0);
  }
  .header-main .navbar-expand-xxl .navbar-nav > .nav-item.dropdown > .dropdown-menu > li {
    position: relative;
  }
  .header-main .navbar-expand-xxl .navbar-nav > .nav-item.dropdown .dropdown-menu .dropdown {
    position: relative;
  }
  .header-main .navbar-expand-xxl .navbar-nav > .nav-item.dropdown .dropdown-menu .dropdown > .dropdown-item {
    position: relative;
  }
  .header-main .navbar-expand-xxl .navbar-nav > .nav-item.dropdown .dropdown-menu .dropdown > .dropdown-item:after {
    content: "\f054";
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    color: currentColor;
    margin-left: 8px;
    display: inline-block;
    font-size: 11px;
    border: none;
    position: absolute;
    top: 8px;
    right: 10px;
  }
  .header-main .navbar-expand-xxl .navbar-nav > .nav-item.dropdown .dropdown-menu > .dropdown:hover > .dropdown-menu-sub {
    display: block;
  }
  .header-main .navbar-expand-xxl .navbar-nav > .nav-item.dropdown .dropdown-menu > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub {
    display: block;
  }
  .header-main .navbar-expand-xxl .navbar-nav > .nav-item.dropdown .dropdown-menu > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub {
    display: block;
  }
  .header-main .navbar-expand-xxl .navbar-nav > .nav-item.dropdown .dropdown-menu > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub {
    display: block;
  }
  .header-main .navbar-expand-xxl .navbar-nav > .nav-item.dropdown .dropdown-menu-sub {
    position: absolute;
    top: 0;
    left: 100%;
  }
  .header-main .navbar-expand-xxl .navbar-nav > .nav-item.dropdown .dropdown-menu-sub.right {
    left: auto;
    right: 100%;
  }
  .header-main .navbar-expand-xxl .navbar-nav > .nav-item.dropdown:hover > .dropdown-menu {
    display: block;
  }
  .header-main .navbar-expand-xxl .navbar-nav > .nav-item.dropdown .mob-menu {
    display: none;
  }
  .header-main .navbar-expand-xxl .navbar-nav > .nav-item.dropdown-full {
    position: static;
  }
  .header-main .navbar-expand-xxl .navbar-nav > .nav-item .dropdown-menu {
    display: none;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05), 0 1.5rem 2.2rem rgba(0, 0, 0, 0.1);
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0;
  }
  .header-main.header-fluid .navbar-expand-xxl {
    padding-left: 35px;
    padding-right: 35px;
  }
}
@media (min-width: 1200px) {
  .header-main .navbar-expand-xl .dropdown-mega-menu {
    width: 100%;
    background: #ffffff;
  }
  .header-main .navbar-expand-xl > .container {
    position: relative;
  }
  .header-main .navbar-expand-xl .dropdown-menu-md {
    min-width: 22rem;
  }
  .header-main .navbar-expand-xl .dropdown-menu-lg {
    min-width: 35rem;
  }
  .header-main .navbar-expand-xl .dropdown-menu-xl {
    min-width: 42rem;
  }
  .header-main .navbar-expand-xl .navbar-nav > .nav-item {
    /*Submenu Mixin*/
  }
  .header-main .navbar-expand-xl .navbar-nav > .nav-item > .nav-link {
    line-height: 60px;
  }
  .header-main .navbar-expand-xl .navbar-nav > .nav-item.dropdown > .nav-link:after {
    content: "\f078";
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    color: currentColor;
    margin-left: 8px;
    display: inline-block;
    font-size: 11px;
    border: none;
  }
  .header-main .navbar-expand-xl .navbar-nav > .nav-item.dropdown > .dropdown-menu {
    left: 50%;
    transform: translate(-50%, 0);
    margin: 0;
  }
  .header-main .navbar-expand-xl .navbar-nav > .nav-item.dropdown > .dropdown-menu.left {
    left: 0;
    transform: translate(0, 0);
  }
  .header-main .navbar-expand-xl .navbar-nav > .nav-item.dropdown > .dropdown-menu.right {
    left: auto;
    right: 0;
    transform: translate(0, 0);
  }
  .header-main .navbar-expand-xl .navbar-nav > .nav-item.dropdown > .dropdown-menu > li {
    position: relative;
  }
  .header-main .navbar-expand-xl .navbar-nav > .nav-item.dropdown .dropdown-menu .dropdown {
    position: relative;
  }
  .header-main .navbar-expand-xl .navbar-nav > .nav-item.dropdown .dropdown-menu .dropdown > .dropdown-item {
    position: relative;
  }
  .header-main .navbar-expand-xl .navbar-nav > .nav-item.dropdown .dropdown-menu .dropdown > .dropdown-item:after {
    content: "\f054";
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    color: currentColor;
    margin-left: 8px;
    display: inline-block;
    font-size: 11px;
    border: none;
    position: absolute;
    top: 8px;
    right: 10px;
  }
  .header-main .navbar-expand-xl .navbar-nav > .nav-item.dropdown .dropdown-menu > .dropdown:hover > .dropdown-menu-sub {
    display: block;
  }
  .header-main .navbar-expand-xl .navbar-nav > .nav-item.dropdown .dropdown-menu > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub {
    display: block;
  }
  .header-main .navbar-expand-xl .navbar-nav > .nav-item.dropdown .dropdown-menu > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub {
    display: block;
  }
  .header-main .navbar-expand-xl .navbar-nav > .nav-item.dropdown .dropdown-menu > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub {
    display: block;
  }
  .header-main .navbar-expand-xl .navbar-nav > .nav-item.dropdown .dropdown-menu-sub {
    position: absolute;
    top: 0;
    left: 100%;
  }
  .header-main .navbar-expand-xl .navbar-nav > .nav-item.dropdown .dropdown-menu-sub.right {
    left: auto;
    right: 100%;
  }
  .header-main .navbar-expand-xl .navbar-nav > .nav-item.dropdown:hover > .dropdown-menu {
    display: block;
  }
  .header-main .navbar-expand-xl .navbar-nav > .nav-item.dropdown .mob-menu {
    display: none;
  }
  .header-main .navbar-expand-xl .navbar-nav > .nav-item.dropdown-full {
    position: static;
  }
  .header-main .navbar-expand-xl .navbar-nav > .nav-item .dropdown-menu {
    display: none;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05), 0 1.5rem 2.2rem rgba(0, 0, 0, 0.1);
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0;
  }
  .header-main.header-fluid .navbar-expand-xl {
    padding-left: 35px;
    padding-right: 35px;
  }
}
@media (min-width: 992px) {
  .header-main .navbar-expand-lg .dropdown-mega-menu {
    width: 100%;
    background: #ffffff;
  }
  .header-main .navbar-expand-lg > .container {
    position: relative;
  }
  .header-main .navbar-expand-lg .dropdown-menu-md {
    min-width: 22rem;
  }
  .header-main .navbar-expand-lg .dropdown-menu-lg {
    min-width: 35rem;
  }
  .header-main .navbar-expand-lg .dropdown-menu-xl {
    min-width: 42rem;
  }
  .header-main .navbar-expand-lg .navbar-nav > .nav-item {
    /*Submenu Mixin*/
  }
  .header-main .navbar-expand-lg .navbar-nav > .nav-item > .nav-link {
    line-height: 60px;
  }
  .header-main .navbar-expand-lg .navbar-nav > .nav-item.dropdown > .nav-link:after {
    content: "\f078";
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    color: currentColor;
    margin-left: 8px;
    display: inline-block;
    font-size: 11px;
    border: none;
  }
  .header-main .navbar-expand-lg .navbar-nav > .nav-item.dropdown > .dropdown-menu {
    left: 50%;
    transform: translate(-50%, 0);
    margin: 0;
  }
  .header-main .navbar-expand-lg .navbar-nav > .nav-item.dropdown > .dropdown-menu.left {
    left: 0;
    transform: translate(0, 0);
  }
  .header-main .navbar-expand-lg .navbar-nav > .nav-item.dropdown > .dropdown-menu.right {
    left: auto;
    right: 0;
    transform: translate(0, 0);
  }
  .header-main .navbar-expand-lg .navbar-nav > .nav-item.dropdown > .dropdown-menu > li {
    position: relative;
  }
  .header-main .navbar-expand-lg .navbar-nav > .nav-item.dropdown .dropdown-menu .dropdown {
    position: relative;
  }
  .header-main .navbar-expand-lg .navbar-nav > .nav-item.dropdown .dropdown-menu .dropdown > .dropdown-item {
    position: relative;
  }
  .header-main .navbar-expand-lg .navbar-nav > .nav-item.dropdown .dropdown-menu .dropdown > .dropdown-item:after {
    content: "\f054";
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    color: currentColor;
    margin-left: 8px;
    display: inline-block;
    font-size: 11px;
    border: none;
    position: absolute;
    top: 8px;
    right: 10px;
  }
  .header-main .navbar-expand-lg .navbar-nav > .nav-item.dropdown .dropdown-menu > .dropdown:hover > .dropdown-menu-sub {
    display: block;
  }
  .header-main .navbar-expand-lg .navbar-nav > .nav-item.dropdown .dropdown-menu > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub {
    display: block;
  }
  .header-main .navbar-expand-lg .navbar-nav > .nav-item.dropdown .dropdown-menu > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub {
    display: block;
  }
  .header-main .navbar-expand-lg .navbar-nav > .nav-item.dropdown .dropdown-menu > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub {
    display: block;
  }
  .header-main .navbar-expand-lg .navbar-nav > .nav-item.dropdown .dropdown-menu-sub {
    position: absolute;
    top: 0;
    left: 100%;
  }
  .header-main .navbar-expand-lg .navbar-nav > .nav-item.dropdown .dropdown-menu-sub.right {
    left: auto;
    right: 100%;
  }
  .header-main .navbar-expand-lg .navbar-nav > .nav-item.dropdown:hover > .dropdown-menu {
    display: block;
  }
  .header-main .navbar-expand-lg .navbar-nav > .nav-item.dropdown .mob-menu {
    display: none;
  }
  .header-main .navbar-expand-lg .navbar-nav > .nav-item.dropdown-full {
    position: static;
  }
  .header-main .navbar-expand-lg .navbar-nav > .nav-item .dropdown-menu {
    display: none;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05), 0 1.5rem 2.2rem rgba(0, 0, 0, 0.1);
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0;
  }
  .header-main.header-fluid .navbar-expand-lg {
    padding-left: 35px;
    padding-right: 35px;
  }
}
@media (min-width: 768px) {
  .header-main .navbar-expand-md .dropdown-mega-menu {
    width: 100%;
    background: #ffffff;
  }
  .header-main .navbar-expand-md > .container {
    position: relative;
  }
  .header-main .navbar-expand-md .dropdown-menu-md {
    min-width: 22rem;
  }
  .header-main .navbar-expand-md .dropdown-menu-lg {
    min-width: 35rem;
  }
  .header-main .navbar-expand-md .dropdown-menu-xl {
    min-width: 42rem;
  }
  .header-main .navbar-expand-md .navbar-nav > .nav-item {
    /*Submenu Mixin*/
  }
  .header-main .navbar-expand-md .navbar-nav > .nav-item > .nav-link {
    line-height: 60px;
  }
  .header-main .navbar-expand-md .navbar-nav > .nav-item.dropdown > .nav-link:after {
    content: "\f078";
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    color: currentColor;
    margin-left: 8px;
    display: inline-block;
    font-size: 11px;
    border: none;
  }
  .header-main .navbar-expand-md .navbar-nav > .nav-item.dropdown > .dropdown-menu {
    left: 50%;
    transform: translate(-50%, 0);
    margin: 0;
  }
  .header-main .navbar-expand-md .navbar-nav > .nav-item.dropdown > .dropdown-menu.left {
    left: 0;
    transform: translate(0, 0);
  }
  .header-main .navbar-expand-md .navbar-nav > .nav-item.dropdown > .dropdown-menu.right {
    left: auto;
    right: 0;
    transform: translate(0, 0);
  }
  .header-main .navbar-expand-md .navbar-nav > .nav-item.dropdown > .dropdown-menu > li {
    position: relative;
  }
  .header-main .navbar-expand-md .navbar-nav > .nav-item.dropdown .dropdown-menu .dropdown {
    position: relative;
  }
  .header-main .navbar-expand-md .navbar-nav > .nav-item.dropdown .dropdown-menu .dropdown > .dropdown-item {
    position: relative;
  }
  .header-main .navbar-expand-md .navbar-nav > .nav-item.dropdown .dropdown-menu .dropdown > .dropdown-item:after {
    content: "\f054";
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    color: currentColor;
    margin-left: 8px;
    display: inline-block;
    font-size: 11px;
    border: none;
    position: absolute;
    top: 8px;
    right: 10px;
  }
  .header-main .navbar-expand-md .navbar-nav > .nav-item.dropdown .dropdown-menu > .dropdown:hover > .dropdown-menu-sub {
    display: block;
  }
  .header-main .navbar-expand-md .navbar-nav > .nav-item.dropdown .dropdown-menu > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub {
    display: block;
  }
  .header-main .navbar-expand-md .navbar-nav > .nav-item.dropdown .dropdown-menu > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub {
    display: block;
  }
  .header-main .navbar-expand-md .navbar-nav > .nav-item.dropdown .dropdown-menu > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub {
    display: block;
  }
  .header-main .navbar-expand-md .navbar-nav > .nav-item.dropdown .dropdown-menu-sub {
    position: absolute;
    top: 0;
    left: 100%;
  }
  .header-main .navbar-expand-md .navbar-nav > .nav-item.dropdown .dropdown-menu-sub.right {
    left: auto;
    right: 100%;
  }
  .header-main .navbar-expand-md .navbar-nav > .nav-item.dropdown:hover > .dropdown-menu {
    display: block;
  }
  .header-main .navbar-expand-md .navbar-nav > .nav-item.dropdown .mob-menu {
    display: none;
  }
  .header-main .navbar-expand-md .navbar-nav > .nav-item.dropdown-full {
    position: static;
  }
  .header-main .navbar-expand-md .navbar-nav > .nav-item .dropdown-menu {
    display: none;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05), 0 1.5rem 2.2rem rgba(0, 0, 0, 0.1);
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0;
  }
  .header-main.header-fluid .navbar-expand-md {
    padding-left: 35px;
    padding-right: 35px;
  }
}
@media (min-width: 576px) {
  .header-main .navbar-expand-sm .dropdown-mega-menu {
    width: 100%;
    background: #ffffff;
  }
  .header-main .navbar-expand-sm > .container {
    position: relative;
  }
  .header-main .navbar-expand-sm .dropdown-menu-md {
    min-width: 22rem;
  }
  .header-main .navbar-expand-sm .dropdown-menu-lg {
    min-width: 35rem;
  }
  .header-main .navbar-expand-sm .dropdown-menu-xl {
    min-width: 42rem;
  }
  .header-main .navbar-expand-sm .navbar-nav > .nav-item {
    /*Submenu Mixin*/
  }
  .header-main .navbar-expand-sm .navbar-nav > .nav-item > .nav-link {
    line-height: 60px;
  }
  .header-main .navbar-expand-sm .navbar-nav > .nav-item.dropdown > .nav-link:after {
    content: "\f078";
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    color: currentColor;
    margin-left: 8px;
    display: inline-block;
    font-size: 11px;
    border: none;
  }
  .header-main .navbar-expand-sm .navbar-nav > .nav-item.dropdown > .dropdown-menu {
    left: 50%;
    transform: translate(-50%, 0);
    margin: 0;
  }
  .header-main .navbar-expand-sm .navbar-nav > .nav-item.dropdown > .dropdown-menu.left {
    left: 0;
    transform: translate(0, 0);
  }
  .header-main .navbar-expand-sm .navbar-nav > .nav-item.dropdown > .dropdown-menu.right {
    left: auto;
    right: 0;
    transform: translate(0, 0);
  }
  .header-main .navbar-expand-sm .navbar-nav > .nav-item.dropdown > .dropdown-menu > li {
    position: relative;
  }
  .header-main .navbar-expand-sm .navbar-nav > .nav-item.dropdown .dropdown-menu .dropdown {
    position: relative;
  }
  .header-main .navbar-expand-sm .navbar-nav > .nav-item.dropdown .dropdown-menu .dropdown > .dropdown-item {
    position: relative;
  }
  .header-main .navbar-expand-sm .navbar-nav > .nav-item.dropdown .dropdown-menu .dropdown > .dropdown-item:after {
    content: "\f054";
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    color: currentColor;
    margin-left: 8px;
    display: inline-block;
    font-size: 11px;
    border: none;
    position: absolute;
    top: 8px;
    right: 10px;
  }
  .header-main .navbar-expand-sm .navbar-nav > .nav-item.dropdown .dropdown-menu > .dropdown:hover > .dropdown-menu-sub {
    display: block;
  }
  .header-main .navbar-expand-sm .navbar-nav > .nav-item.dropdown .dropdown-menu > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub {
    display: block;
  }
  .header-main .navbar-expand-sm .navbar-nav > .nav-item.dropdown .dropdown-menu > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub {
    display: block;
  }
  .header-main .navbar-expand-sm .navbar-nav > .nav-item.dropdown .dropdown-menu > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub {
    display: block;
  }
  .header-main .navbar-expand-sm .navbar-nav > .nav-item.dropdown .dropdown-menu-sub {
    position: absolute;
    top: 0;
    left: 100%;
  }
  .header-main .navbar-expand-sm .navbar-nav > .nav-item.dropdown .dropdown-menu-sub.right {
    left: auto;
    right: 100%;
  }
  .header-main .navbar-expand-sm .navbar-nav > .nav-item.dropdown:hover > .dropdown-menu {
    display: block;
  }
  .header-main .navbar-expand-sm .navbar-nav > .nav-item.dropdown .mob-menu {
    display: none;
  }
  .header-main .navbar-expand-sm .navbar-nav > .nav-item.dropdown-full {
    position: static;
  }
  .header-main .navbar-expand-sm .navbar-nav > .nav-item .dropdown-menu {
    display: none;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05), 0 1.5rem 2.2rem rgba(0, 0, 0, 0.1);
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0;
  }
  .header-main.header-fluid .navbar-expand-sm {
    padding-left: 35px;
    padding-right: 35px;
  }
}
.header-main .navbar-expand-xs .dropdown-mega-menu {
  width: 100%;
  background: #ffffff;
}
.header-main .navbar-expand-xs > .container {
  position: relative;
}
.header-main .navbar-expand-xs .dropdown-menu-md {
  min-width: 22rem;
}
.header-main .navbar-expand-xs .dropdown-menu-lg {
  min-width: 35rem;
}
.header-main .navbar-expand-xs .dropdown-menu-xl {
  min-width: 42rem;
}
.header-main .navbar-expand-xs .navbar-nav > .nav-item {
  /*Submenu Mixin*/
}
.header-main .navbar-expand-xs .navbar-nav > .nav-item > .nav-link {
  line-height: 60px;
}
.header-main .navbar-expand-xs .navbar-nav > .nav-item.dropdown > .nav-link:after {
  content: "\f078";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  color: currentColor;
  margin-left: 8px;
  display: inline-block;
  font-size: 11px;
  border: none;
}
.header-main .navbar-expand-xs .navbar-nav > .nav-item.dropdown > .dropdown-menu {
  left: 50%;
  transform: translate(-50%, 0);
  margin: 0;
}
.header-main .navbar-expand-xs .navbar-nav > .nav-item.dropdown > .dropdown-menu.left {
  left: 0;
  transform: translate(0, 0);
}
.header-main .navbar-expand-xs .navbar-nav > .nav-item.dropdown > .dropdown-menu.right {
  left: auto;
  right: 0;
  transform: translate(0, 0);
}
.header-main .navbar-expand-xs .navbar-nav > .nav-item.dropdown > .dropdown-menu > li {
  position: relative;
}
.header-main .navbar-expand-xs .navbar-nav > .nav-item.dropdown .dropdown-menu .dropdown {
  position: relative;
}
.header-main .navbar-expand-xs .navbar-nav > .nav-item.dropdown .dropdown-menu .dropdown > .dropdown-item {
  position: relative;
}
.header-main .navbar-expand-xs .navbar-nav > .nav-item.dropdown .dropdown-menu .dropdown > .dropdown-item:after {
  content: "\f054";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  color: currentColor;
  margin-left: 8px;
  display: inline-block;
  font-size: 11px;
  border: none;
  position: absolute;
  top: 8px;
  right: 10px;
}
.header-main .navbar-expand-xs .navbar-nav > .nav-item.dropdown .dropdown-menu > .dropdown:hover > .dropdown-menu-sub {
  display: block;
}
.header-main .navbar-expand-xs .navbar-nav > .nav-item.dropdown .dropdown-menu > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub {
  display: block;
}
.header-main .navbar-expand-xs .navbar-nav > .nav-item.dropdown .dropdown-menu > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub {
  display: block;
}
.header-main .navbar-expand-xs .navbar-nav > .nav-item.dropdown .dropdown-menu > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub > ul > .dropdown:hover > .dropdown-menu-sub {
  display: block;
}
.header-main .navbar-expand-xs .navbar-nav > .nav-item.dropdown .dropdown-menu-sub {
  position: absolute;
  top: 0;
  left: 100%;
}
.header-main .navbar-expand-xs .navbar-nav > .nav-item.dropdown .dropdown-menu-sub.right {
  left: auto;
  right: 100%;
}
.header-main .navbar-expand-xs .navbar-nav > .nav-item.dropdown:hover > .dropdown-menu {
  display: block;
}
.header-main .navbar-expand-xs .navbar-nav > .nav-item.dropdown .mob-menu {
  display: none;
}
.header-main .navbar-expand-xs .navbar-nav > .nav-item.dropdown-full {
  position: static;
}
.header-main .navbar-expand-xs .navbar-nav > .nav-item .dropdown-menu {
  display: none;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05), 0 1.5rem 2.2rem rgba(0, 0, 0, 0.1);
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 0;
}
.header-main.header-fluid .navbar-expand-xs {
  padding-left: 35px;
  padding-right: 35px;
}
@media (max-width: 1399.98px) {
  .header-main .navbar-expand-xxl {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .header-main .navbar-expand-xxl .navbar-collapse {
    max-height: 70vh;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #ffffff;
  }
  .header-main .navbar-expand-xxl .navbar-nav .nav-item {
    margin: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
  }
  .header-main .navbar-expand-xxl .navbar-nav .nav-item > .nav-link {
    padding: 15px;
    color: #0f0d1d;
  }
  .header-main .navbar-expand-xxl .navbar-nav .nav-item .open-menu-parent > a {
    color: #065D8F;
  }
  .header-main .navbar-expand-xxl .navbar-nav .nav-item .dropdown-menu {
    background: #ffffff;
    margin: 0;
    border-radius: 0;
    padding: 0;
    border-top: 1px solid #eff2f7;
  }
  .header-main .navbar-expand-xxl .navbar-nav .nav-item .dropdown-menu .dropdown-item {
    padding: 15px;
  }
  .header-main .navbar-expand-xxl .navbar-nav .nav-item .dropdown-menu li {
    position: relative;
    border-bottom: 1px solid #eff2f7;
  }
  .header-main .navbar-expand-xxl .navbar-nav .nav-item .dropdown-menu li:last-child {
    border-bottom: none;
  }
  .header-main .navbar-expand-xxl .navbar-nav .nav-item .dropdown-menu .dropdown-menu .dropdown-menu {
    padding-left: 10px;
  }
  .header-main .navbar-expand-xxl .navbar-nav .nav-item .dropdown-menu .dropdown-menu li {
    position: relative;
  }
}
@media (max-width: 1199.98px) {
  .header-main .navbar-expand-xl {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .header-main .navbar-expand-xl .navbar-collapse {
    max-height: 70vh;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #ffffff;
  }
  .header-main .navbar-expand-xl .navbar-nav .nav-item {
    margin: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
  }
  .header-main .navbar-expand-xl .navbar-nav .nav-item > .nav-link {
    padding: 15px;
    color: #0f0d1d;
  }
  .header-main .navbar-expand-xl .navbar-nav .nav-item .open-menu-parent > a {
    color: #065D8F;
  }
  .header-main .navbar-expand-xl .navbar-nav .nav-item .dropdown-menu {
    background: #ffffff;
    margin: 0;
    border-radius: 0;
    padding: 0;
    border-top: 1px solid #eff2f7;
  }
  .header-main .navbar-expand-xl .navbar-nav .nav-item .dropdown-menu .dropdown-item {
    padding: 15px;
  }
  .header-main .navbar-expand-xl .navbar-nav .nav-item .dropdown-menu li {
    position: relative;
    border-bottom: 1px solid #eff2f7;
  }
  .header-main .navbar-expand-xl .navbar-nav .nav-item .dropdown-menu li:last-child {
    border-bottom: none;
  }
  .header-main .navbar-expand-xl .navbar-nav .nav-item .dropdown-menu .dropdown-menu .dropdown-menu {
    padding-left: 10px;
  }
  .header-main .navbar-expand-xl .navbar-nav .nav-item .dropdown-menu .dropdown-menu li {
    position: relative;
  }
}
@media (max-width: 991.98px) {
  .header-main .navbar-expand-lg {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .header-main .navbar-expand-lg .navbar-collapse {
    max-height: 70vh;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #ffffff;
  }
  .header-main .navbar-expand-lg .navbar-nav .nav-item {
    margin: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
  }
  .header-main .navbar-expand-lg .navbar-nav .nav-item > .nav-link {
    padding: 15px;
    color: #0f0d1d;
  }
  .header-main .navbar-expand-lg .navbar-nav .nav-item .open-menu-parent > a {
    color: #065D8F;
  }
  .header-main .navbar-expand-lg .navbar-nav .nav-item .dropdown-menu {
    background: #ffffff;
    margin: 0;
    border-radius: 0;
    padding: 0;
    border-top: 1px solid #eff2f7;
  }
  .header-main .navbar-expand-lg .navbar-nav .nav-item .dropdown-menu .dropdown-item {
    padding: 15px;
  }
  .header-main .navbar-expand-lg .navbar-nav .nav-item .dropdown-menu li {
    position: relative;
    border-bottom: 1px solid #eff2f7;
  }
  .header-main .navbar-expand-lg .navbar-nav .nav-item .dropdown-menu li:last-child {
    border-bottom: none;
  }
  .header-main .navbar-expand-lg .navbar-nav .nav-item .dropdown-menu .dropdown-menu .dropdown-menu {
    padding-left: 10px;
  }
  .header-main .navbar-expand-lg .navbar-nav .nav-item .dropdown-menu .dropdown-menu li {
    position: relative;
  }
}
@media (max-width: 767.98px) {
  .header-main .navbar-expand-md {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .header-main .navbar-expand-md .navbar-collapse {
    max-height: 70vh;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #ffffff;
  }
  .header-main .navbar-expand-md .navbar-nav .nav-item {
    margin: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
  }
  .header-main .navbar-expand-md .navbar-nav .nav-item > .nav-link {
    padding: 15px;
    color: #0f0d1d;
  }
  .header-main .navbar-expand-md .navbar-nav .nav-item .open-menu-parent > a {
    color: #065D8F;
  }
  .header-main .navbar-expand-md .navbar-nav .nav-item .dropdown-menu {
    background: #ffffff;
    margin: 0;
    border-radius: 0;
    padding: 0;
    border-top: 1px solid #eff2f7;
  }
  .header-main .navbar-expand-md .navbar-nav .nav-item .dropdown-menu .dropdown-item {
    padding: 15px;
  }
  .header-main .navbar-expand-md .navbar-nav .nav-item .dropdown-menu li {
    position: relative;
    border-bottom: 1px solid #eff2f7;
  }
  .header-main .navbar-expand-md .navbar-nav .nav-item .dropdown-menu li:last-child {
    border-bottom: none;
  }
  .header-main .navbar-expand-md .navbar-nav .nav-item .dropdown-menu .dropdown-menu .dropdown-menu {
    padding-left: 10px;
  }
  .header-main .navbar-expand-md .navbar-nav .nav-item .dropdown-menu .dropdown-menu li {
    position: relative;
  }
}
@media (max-width: 575.98px) {
  .header-main .navbar-expand-sm {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .header-main .navbar-expand-sm .navbar-collapse {
    max-height: 70vh;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #ffffff;
  }
  .header-main .navbar-expand-sm .navbar-nav .nav-item {
    margin: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
  }
  .header-main .navbar-expand-sm .navbar-nav .nav-item > .nav-link {
    padding: 15px;
    color: #0f0d1d;
  }
  .header-main .navbar-expand-sm .navbar-nav .nav-item .open-menu-parent > a {
    color: #065D8F;
  }
  .header-main .navbar-expand-sm .navbar-nav .nav-item .dropdown-menu {
    background: #ffffff;
    margin: 0;
    border-radius: 0;
    padding: 0;
    border-top: 1px solid #eff2f7;
  }
  .header-main .navbar-expand-sm .navbar-nav .nav-item .dropdown-menu .dropdown-item {
    padding: 15px;
  }
  .header-main .navbar-expand-sm .navbar-nav .nav-item .dropdown-menu li {
    position: relative;
    border-bottom: 1px solid #eff2f7;
  }
  .header-main .navbar-expand-sm .navbar-nav .nav-item .dropdown-menu li:last-child {
    border-bottom: none;
  }
  .header-main .navbar-expand-sm .navbar-nav .nav-item .dropdown-menu .dropdown-menu .dropdown-menu {
    padding-left: 10px;
  }
  .header-main .navbar-expand-sm .navbar-nav .nav-item .dropdown-menu .dropdown-menu li {
    position: relative;
  }
}
.header-main .navbar-expand-sm {
  padding-top: 10px;
  padding-bottom: 10px;
}
.header-main .navbar-expand-sm .navbar-collapse {
  max-height: 70vh;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #ffffff;
}
.header-main .navbar-expand-sm .navbar-nav .nav-item {
  margin: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
}
.header-main .navbar-expand-sm .navbar-nav .nav-item > .nav-link {
  padding: 15px;
  color: #0f0d1d;
}
.header-main .navbar-expand-sm .navbar-nav .nav-item .open-menu-parent > a {
  color: #065D8F;
}
.header-main .navbar-expand-sm .navbar-nav .nav-item .dropdown-menu {
  background: #ffffff;
  margin: 0;
  border-radius: 0;
  padding: 0;
  border-top: 1px solid #eff2f7;
}
.header-main .navbar-expand-sm .navbar-nav .nav-item .dropdown-menu .dropdown-item {
  padding: 15px;
}
.header-main .navbar-expand-sm .navbar-nav .nav-item .dropdown-menu li {
  position: relative;
  border-bottom: 1px solid #eff2f7;
}
.header-main .navbar-expand-sm .navbar-nav .nav-item .dropdown-menu li:last-child {
  border-bottom: none;
}
.header-main .navbar-expand-sm .navbar-nav .nav-item .dropdown-menu .dropdown-menu .dropdown-menu {
  padding-left: 10px;
}
.header-main .navbar-expand-sm .navbar-nav .nav-item .dropdown-menu .dropdown-menu li {
  position: relative;
}

.footer .footer-links {
  margin: 0;
}
.footer .footer-links li + li {
  padding-top: 10px;
}
.footer .footer-top {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media (min-width: 768px) {
  .footer .footer-top {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
@media (min-width: 992px) {
  .footer .footer-top {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
.footer .footer-border {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.footer .footer-border.dark {
  border-top: 1px solid rgba(15, 13, 29, 0.1);
}