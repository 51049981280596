@each $color, $value in $px-grays {
	.text-#{$color} {
		color: $value; 
	}	
}

.text-white-65 {
	color: rgba($px-white, 0.65) !important;
}


/*	SVG fill
---------------------------*/
.svg_icon {
	[fill]:not([fill=none]) {
		fill: $px-svg-color;	
	}
}
.svg_img {
	polygon,
	path,
	circle {
	  fill: $px-svg-color;
	}
}