img {
	max-width: 100%;
}


.object-fit {
	-o-object-fit: cover;
 	object-fit: cover;
}



@include media-breakpoint-up(lg) {
	.img-lg-120 {
		max-width: 120%;
	}

	.img-lg-140 {
		max-width: 140%;
	}
}

@include media-breakpoint-up(md) {
	.img-md-120 {
		max-width: 120%;
	}

	.img-md-140 {
		max-width: 140%;
	}	
}