/* particles-box
---------------------------*/
.particles-box {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    opacity: 0.2;
    pointer-events: none;
}

/* Effect Section
------------------------*/
.effect-section {
    position: relative;
    overflow: hidden;
    .svg-bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        pointer-events: none;
    }
    .svg-top {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        pointer-events: none;
    }
    .svg-effect-1 {
        position: absolute;
        bottom: 0;
        left: 0;
        pointer-events: none;
        right: 0;
        width: 60vw;
    }
}


.divider-right {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    pointer-events: none;
    svg {
        position: absolute;
        top: -50%;
        bottom: -50%;
        left: 0;
        height: 200%;
    }
}

.divider-left {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    pointer-events: none;
    text-align: right;
    svg {
        position: absolute;
        top: -50%;
        bottom: -50%;
        right: 0;
        height: 200%;
    }
}

.effect-radius-bg {
    position: absolute;
    top: 58%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index:-1;
    > div {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 23rem;
      height: 23rem;
      border-radius: 100%;
      background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(227, 221, 246, 0.1)), color-stop(65%, rgba(227, 221, 246, 0.2)), to(rgba(227, 221, 246, 0.1)));
      background-image: -webkit-linear-gradient(top, rgba(227, 221, 246, 0.1) 0%, rgba(227, 221, 246, 0.2) 65%, rgba(227, 221, 246, 0.1) 100%);
      background-image: -o-linear-gradient(top, rgba(227, 221, 246, 0.1) 0%, rgba(227, 221, 246, 0.2) 65%, rgba(227, 221, 246, 0.1) 100%);
      background-image: linear-gradient(-180deg, rgba(227, 221, 246, 0.1) 0%, rgba(227, 221, 246, 0.2) 65%, rgba(227, 221, 246, 0.1) 100%);
      z-index: -1;
    }

    .radius-1 {
      -webkit-transform: translate(-50%, -50%) scale(0.67);
      -ms-transform: translate(-50%, -50%) scale(0.67);
      transform: translate(-50%, -50%) scale(0.67);
    }

    .radius-2 {
      -webkit-transform: translate(-50%, -50%) scale(1.05);
      -ms-transform: translate(-50%, -50%) scale(1.05);
      transform: translate(-50%, -50%) scale(1.05);
    }

    .radius-3 {
      -webkit-transform: translate(-50%, -50%) scale(1.63);
      -ms-transform: translate(-50%, -50%) scale(1.63);
      transform: translate(-50%, -50%) scale(1.63);
    }

    .radius-4 {
      -webkit-transform: translate(-50%, -50%) scale(2.1);
      -ms-transform: translate(-50%, -50%) scale(2.1);
      transform: translate(-50%, -50%) scale(2.1);
    }

    .radius-x {
      will-change: transform;
      -webkit-animation: pulsate 3s infinite;
      animation: pulsate 3s infinite;
    }
  }


@-webkit-keyframes pulsate {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(0.5);
    transform: translate(-50%, -50%) scale(0.5)
  }

  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(4);
    transform: translate(-50%, -50%) scale(4)
  }

}

@keyframes pulsate {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(0.5);
    transform: translate(-50%, -50%) scale(0.5)
  }

  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(4);
    transform: translate(-50%, -50%) scale(4)
  }

}


/* After 50
------------------------*/
.after-50 {
    position: relative;
    &:after {
        content:"";
        position:absolute;
        bottom: 0;
        left: 0;
        width: 50px;
        height: 2px;
        .text-center & {
            right: 0;
            margin: auto;
        }
    }
}


/* mouse
------------------------*/
.mouse {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
    margin: auto;
    display: inline-block;
    width: 26px;
    height: 46px;
    border-radius: 10rem;
    border: 2px solid currentColor;
    &:after {
        content: "";
    	translate: -50%, 0;
        position: absolute;
        display: block;
        top: 25%;
        left: 50%;
        width: 6px;
        height: 6px;
        background: currentColor;
        border-radius: 50%;
        -webkit-animation: mouse 2s linear infinite;
        -moz-animation: mouse 2s linear infinite;
        animation: mouse 2s linear infinite;
    }
}


@keyframes mouse {
  0% {
    opacity: 1;
    translate: -50%, 0;
  }
  100% {
    opacity: 0;
    translate: -50%,20px;
  }
}

/* Phone Frame
------------------------*/
.phone-frame {
    position: relative;
    z-index: 3;
    display: inline-block;
    .pf-inner {
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 72%;
        height: 84%;
        margin: 10% 0 0 14%;
        border-radius: 5%;
    }
}

@include media-breakpoint-up(lg) {
	.sticky-lg-top-header {
		top: 100px;
	}
}


@keyframes pulse-border {
    0% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(2);
        transform: scale(2);
        opacity: 0;
    }
}

.after-shadow {
    z-index: 1;
    position: relative;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 50%;
        animation: pulse-border 1500ms ease-out infinite;
        pointer-events: none;
        background: currentColor;
        z-index: -1;
    }
}