.bg-cover {
	background-size: cover;
}
.bg-right-center {
    background-position: center right;
}

.bg-no-repeat {
	background-repeat: no-repeat;
}

.bg-center {
	background-position: center;
}

.bg-black {
	background: #000;
}

@each $color, $value in $px-grays {
	.bg-#{$color} {
		background-color: $value !important; 
	}	
}

@each $color, $value in $px-theme-colors {
	.bg-#{$color}-light {
		background-color: rgba($value, 0.2); 
	}	
}

@each $color, $value in $px-theme-colors {
	.#{$color}-after {
		&:after {
			background-color: $value; 
		}
	}	
}

@each $color, $value in $px-theme-colors {
	.bg-#{$color}-gradient {
		background: linear-gradient(180deg, $value 50%, darken($value, 10%) 100%) repeat-x !important;
	}
}

@each $color, $value in $px-theme-colors {
	.bg-#{$color}-gradient-opacity {
		background: linear-gradient(180deg, rgba($value, .20) 30%, darken($value, 10%) 100%) repeat-x !important;
	}
}

