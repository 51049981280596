﻿html {
    height: 100%;
}

body {
    min-height: 100%;

    p {
        color: $px-gray-800 !important;
    }

    label {
        color: $px-gray-800 !important;
        font-weight: 500 !important;
    }
}

thead {
    color: #043B5C;
    font-weight: 700;

    th {
        color: #043B5C;
    }
}

.text-validation {
    font-size: 10px;
    color: firebrick;
}

/***COVID MESSAGE ***/

.h-350 {
    height: 350px !important;
    overflow: hidden;
    margin-bottom: 10px;
}

.mb-25 {
    margin-bottom: 25px;
}
/*------------------------------------
  Go To v1
------------------------------------*/
[class*="u-go-to"] {
    display: none;
}

.u-go-to-v1 {
    width: 3.57143rem;
    height: 3.57143rem;
    display: block;
    background-color: rgba(255, 255, 255, 0.7);
    color: #a49da6;
    border-radius: 50%;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    transition: .3s ease-out;
    z-index: 11;
}

.u-go-to-v1 i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.u-go-to-v1:hover, .u-go-to-v1:focus:hover {
    text-decoration: none;
    color: #fff;
    background-color: #cecece;
}

.u-go-to-v1:focus {
    text-decoration: none;
    color: #a49da6;
    background-color: rgba(255, 255, 255, 0.7);
}

@media (max-width: 575px) {
    .u-go-to-v1 {
        -webkit-transform: scale(0.8, 0.8);
        transform: scale(0.8, 0.8);
    }
}

/*------------------------------------
Tables v3
------------------------------------*/
.dataTables_scroll {
    border-bottom: 1px solid #e1eaea;
}

.dataTables_scroll [class*="u-table--v3"] {
    margin-bottom: -1px;
}

.dataTables_scroll .mCSB_outside + .mCSB_scrollTools {
    top: 0;
    right: 0;
    background-color: #fff;
    border: 1px solid #e1eaea;
    margin-bottom: -1px;
}

.dataTables_scroll .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 8px;
    background-color: #bac9c9;
}

.dataTables_scroll .mCSB_scrollTools .mCSB_draggerRail {
    display: none;
}

.dataTables_scroll td:last-child,
.dataTables_scroll th:last-child {
    padding-right: 34px;
}

.dataTables_scrollHead,
.dataTables_scrollFoot {
    overflow: visible !important;
}

[class*="u-table--v3"] {
    margin-bottom: 0;
}

[class*="u-table--v3"] th,
[class*="u-table--v3"] td {
    color: inherit;
    vertical-align: middle;
    padding: 1rem;
    border-top-color: #e1eaea !important;
    border-bottom-color: #e1eaea !important;
    border-left-color: #e1eaea !important;
    border-right-color: #e1eaea !important;
}

[class*="u-table--v3"] th:first-child, [class*="u-table--v3"] th.g-first-child,
[class*="u-table--v3"] td:first-child,
[class*="u-table--v3"] td.g-first-child {
    border-left: 1px solid;
}

[class*="u-table--v3"] th:last-child, [class*="u-table--v3"] th.g-last-child,
[class*="u-table--v3"] td:last-child,
[class*="u-table--v3"] td.g-last-child {
    border-right: 1px solid;
}

[class*="u-table--v3"] thead th {
    font-weight: 400;
    border-bottom: none;
}

[class*="u-table--v3"] td {
    font-weight: 300;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

[class*="u-table--v3"] .opened + tr {
    background-color: #f5f9f9;
}

[class*="u-table--v3"] tr:last-child td {
    border-bottom: 1px solid;
}

[class*="u-table--v3"] .thead-light th,
[class*="u-table--v3"] .thead-light td,
[class*="u-table--v3"] .tfoot-light th,
[class*="u-table--v3"] .tfoot-light td {
    color: #53585e;
    background-color: #f5f9f9;
    border-color: #f5f9f9;
}

[class*="u-table--v3"].table-hover tbody tr:hover {
    background-color: rgba(245, 249, 249, 0.4);
}

[class*="u-table--v3"].table-striped tbody tr:nth-of-type(2n+1) {
    background-color: #f5f9f9;
}

[class*="u-table--v3"] tfoot.u-tfoot-after-thead {
    display: table-header-group;
}

.u-table--v3--bordered th,
.u-table--v3--bordered td {
    border-left: 1px solid;
}

.u-table--v3--borderless th,
.u-table--v3--borderless td {
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border: none;
}

.u-table--v3--borderless th:first-child, .u-table--v3--borderless th.g-first-child,
.u-table--v3--borderless td:first-child,
.u-table--v3--borderless td.g-first-child {
    border-left: none;
}

.u-table--v3--borderless th:last-child, .u-table--v3--borderless th.g-last-child,
.u-table--v3--borderless td:last-child,
.u-table--v3--borderless td.g-last-child {
    border-right: none;
}

.u-table--v3--borderless tr:last-child td {
    border-bottom: none;
}

.u-table--v3--row-border-bottom td {
    border-bottom: 1px solid;
    border-bottom-color: #e1eaea !important;
}

.u-table--v3--row-border-bottom tr:last-child td {
    border-bottom: 1px solid;
    border-bottom-color: #e1eaea !important;
}

/*------------------------------------
  Tables v4
------------------------------------*/
[class*="u-table--v4"] {
    margin-bottom: 0;
}

[class*="u-table--v4"] th,
[class*="u-table--v4"] td {
    color: inherit;
    vertical-align: middle;
    padding: 1.42857rem;
    border: none;
}

[class*="u-table--v4"] th:first-child, [class*="u-table--v4"] th.g-first-child,
[class*="u-table--v4"] td:first-child,
[class*="u-table--v4"] td.g-first-child {
    text-align: center;
}

[class*="u-table--v4"] th {
    font-weight: 400;
    border-bottom: none !important;
}

[class*="u-table--v4"] td {
    background-color: #f5f9f9;
    font-weight: 300;
    border-bottom: 2px solid #fff;
    padding-top: 1.14286rem;
    padding-bottom: 1.14286rem;
}

.u-table--v4--bordered th,
.u-table--v4--bordered td {
    border-left: 1px solid;
}

.icon-up-position {
    display: inline-block;
    position: fixed;
    bottom: 15px;
    left: 15px;
}

#skip a {
    display: block;
    position: absolute;
    left: -999px;
    top: -999px;
}

#skip a:focus {
    left: 0;
    top: 0;
    padding: 3px;
    background: #ffc;
    border: 1px solid #990000;
}