/*------------------
*   Tab style 01
-------------------*/
.tab-style-1 {
	.nav {
		border-bottom: 1px solid $px-gray-200;

		@include media-breakpoint-down(md) {
			margin-bottom: 15px;
		}
	}

	.nav-item {
		margin: 0 10px;

		@include media-breakpoint-down(md) {
			margin: 0 5px;
		}

		a {
			color: $px-dark;
			position: relative;
			padding: 12px 15px;
			text-align: center;
			border-bottom: none;
			width: 100%;
			display: block;

			@include media-breakpoint-down(md) {
				padding: 10px 12px;
			}

			&:after {
				content: "";
				position: absolute;
				bottom: -1px;
				left: 0;
				width: 0;
				height: 1px;
				background: $px-primary;
				transition: ease-in-out all 0.55s;
			}

			&.active {
				color: $px-primary;

				&:after {
					width: 100%;
				}
			}
		}

		.icon {
			@include media-breakpoint-up(md) {
				margin-bottom: 8px;
			}

			svg {
				height: 38px;
				width: 38px;

				@include media-breakpoint-down(md) {
					height: 30px;
					width: 30px;
				}
			}
		}

		span {
			font-weight: 700;
			font-size: 16px;
			width: 100%;
			display: block;

			@include media-breakpoint-down(md) {
				display: none;
			}
		}
	}

	.tab-pane {
		padding-top: 20px;
	}
}

/*------------------
*   Tab style 02
-------------------*/
.tab-style-2 {
	.nav {
		margin-bottom: 15px;
		border: none;

		.nav-item {
			margin-right: 18px;

			a {
				padding: 8px 0;
				font-weight: 600;
				color: $px-dark;
				position: relative;
				display: block;

				&:after {
					content: "";
					position: absolute;
					bottom: 0;
					left: 0;
					width: 0px;
					transition: ease-in-out all 0.55s;
					height: 2px;
					background: $px-primary;
				}

				&.active {
					color: $px-primary;

					&:after {
						width: 100%;
					}
				}
			}
		}
	}
}


/*------------------
*   Tab style 03
-------------------*/
.tab-style-3 {
	.nav {
		background: $white;
		border: none;

		a {
			display: block;
			padding: 15px 10px 18px;
			line-height: normal;
			position: relative;
			color: $body-color;

			&:after,
			&:before {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				transition: ease-in-out all 0.25s;
				margin: auto;
			}

			&:after {
				width: 0px;
				height: 3px;
				background: $px-primary;
			}

			&:before {
				width: 20px;
				background: $white;
				border-radius: 50%;
				z-index: 1;
				height: 20px;
				bottom: -8px;
				box-shadow: $box-shadow;
			}

			&.active {
				color: $px-primary;

				&:after {
					width: 100%;
				}
			}
		}

		.tab-icon {
			margin-bottom: 10px;
			i {
				font-size: 32px;
			}
			svg {
				width: 35px;
				height: 35px;
			}
		}

		span {
			font-size: 16px;
			font-weight: 500;
			color: $px-dark;
			@include media-breakpoint-down(md) {
				display: none;
			}
		}
	}

	.tab-content {
		padding-top: 30px;
	}
}

/*------------------
*   Tab style 04
-------------------*/
.tab-style-4 {
	.nav {
		margin-bottom: 15px;
		border-bottom: 2px solid $px-gray-200;

		.nav-item {
			.tb-icon {
				display: inline-block;
				vertical-align: middle;
				font-size: 30px;
				color: $px-primary;
			}

			span {
				margin-left: 10px;
				vertical-align: middle;

				@include media-breakpoint-down(sm) {
					display: none;
				}
			}

			a {
				padding: 15px 0;
				font-weight: 600;
				color: $px-dark;
				position: relative;
				display: block;

				&:after {
					content: "";
					position: absolute;
					bottom: -2px;
					left: auto;
					right: 0;
					width: 0px;
					transition: ease-in-out all 0.55s;
					height: 3px;
					background: $px-primary;
				}

				&.active {
					&:after {
						left: 0;
						right: auto;
						width: 100%;
					}
				}
			}
		}
	}
}

/*------------------
*   Tab style 05
-------------------*/
.tab-style-5 {
	.nav-tabs {
		border: none;

		.nav-item {
			margin: 5px;

			>a {
				position: relative;
				padding: 15px 10px;
				font-weight: 600;
				color: $px-dark;
				border: none;
				border-radius: 10px;
				background: $white;
				border: 1px solid $px-gray-200;
				background: $px-white;

				&.active {
					color: $white;
					background: $px-primary;
				}
			}
		}
	}
}

/*------------------
*   Tab style 05
-------------------*/
.tab-style-6  {
    .nav-tabs {
        border: none;
        .nav-item {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
            h6 {
                font-weight: 700;
                font-size: 18px;
                color: $px-dark;
            }
            p {
                margin: 0;
            }
            > a {
                position: relative;
                padding: 20px;
                color: $px-body-color;
                border: none;
                border-radius: 5px;
                background: $px-white;
                border:1px solid $px-gray-200; 
                [class*="only-icon"] {
                    color: $px-primary;
                }
                &.active {
                    color: rgba($px-white, 0.8);
                    background: $px-primary;
                    [class*="only-icon"] {
                        color: $px-white;
                    }
                    h6 {
                        color: $px-white;
                    }
                }
            }
        }
    }
}