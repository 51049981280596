/*---------------------------
 Portfolio Start
------------------------------*/
.portfolio-content {
    .grid-item {
        float: left;
    }

}

/*Portfolio Filter*/
.portfolio-filter-01 {
    padding-top: 15px;
    padding-bottom: 15px;
    .filter {
        li {
            cursor: pointer;
            margin: 0 15px;
            color: $px-dark;
            position: relative;
            padding: 5px 0;
            font-weight: 500;
            font-size: 16px;
            line-height: normal;
            &:after {
                content: "";
                width: 0px;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                height: 2px;
                background: $px-primary;
                position: absolute;
                transition: ease all 0.55s;
            }

            &:last-child {
                margin-right: 0;
            }

            &:first-child {
                margin-left: 0;
            }


            &.active {
                &:after {
                    width: 100%;
                }
            }
        }
    }
}
