.after-percentage {
	&:after {
		content:"%";
	}
}
.pie_chart_in {
	position: relative;
	display: inline-block;
	vertical-align: top;
	.middle {
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
}