.form-group {
	margin-bottom: 1rem;
}

.rd-mailform {
	.has-error {
		.invalid-feedback {
			display: block;
		}
	}
	.snackbars {
		border: 1px solid $px-gray-200;
		background: $px-gray-200;
	    border-radius: 3px;
	    font-size: 14px;
	    font-weight: 500;
	    margin-top: 20px;
	    display: none;
	    &.active {
	    	display: block;
	    }
	    &.error {
    	    border: 1px solid lighten($px-danger, 5%);
		    background: lighten($px-danger, 2%);
		    color: $px-danger;
	    }
	    &.success {
    	    border: 1px solid lighten($px-success, 5%);
		    background: lighten($px-success, 2%);
		    color: $px-success;
	    }
		p {
			margin: 0;
			position: relative;
			display: flex;
			align-items: center;
			.s-icon {
				width: 40px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				font-size: 16px;
			}
		}
	}
}