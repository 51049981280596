.skill-lt {
	&:not(:last-child) {
		margin-bottom: 30px;
	}
	h6 {
	    font-size: 16px;
		margin: 0 0 10px;
	} 
	.skill-bar {
		position: relative;
		background: #eee;
		box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
		.skill-bar-in {
			width: 0px;
			transition: cubic-bezier(0.4, 0, 1, 1) all 0.95s;
			height: 5px;
			position: relative;
			span {
			    position: absolute;
			    right: 0;
			    top: -30px;
			    font-size: 11px;
			    background: $px-dark;
			    border-radius: 3px 3px 0 3px;
			    padding: 2px 7px;
			    font-weight: 600;
			    color: $px-white;
			    &:after {
			    	content: '';
				    width: 0;
				    height: 0;
				    border-top: 6px solid $px-dark;
				    border-left: 8px solid transparent;
				    position: absolute;
				    right: 6px;
				    bottom: -6px;
			    }
			}
		}
	}
	&.md {
		.skill-bar {
			.skill-bar-in {	
				height: 8px;
			}
		}
	}

	&.lg {
		.skill-bar {
			.skill-bar-in {	
				height: 10px;
			}
		}
	}
	&.light {
		.skill-bar {
			background: rgba($px-black, .5);
		}
	}
}