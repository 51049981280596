@use "sass:list";
@use "sass:math";

// Brand / Logo
$px-logo-width: 6.7rem !default;

// Color System
//$px-primary:        #724cf9 !default;
/*$px-primary: #396bea !default;
*/
$px-primary: #065D8F !default;

$px-secondary: #6c757d !default;
/*$px-success:        #2dca8c !default;
*/
$px-success: #707D27 !default;
$px-danger: #ff5c75 !default;
$px-warning: #ff9f1c !default;
$px-info: #50b5ff !default;

$px-dark: #0f0d1d !default;
$px-light: #f1f6fd !default;

$px-black: #000 !default;
$px-white: #ffffff !default;

$px-gray-100: #f7f7ff !default;
$px-gray-200: #eff2f7 !default;
$px-gray-300: #e2e8f0 !default;
$px-gray-400: #cbd5e0 !default;
$px-gray-500: #a0aec0 !default;
$px-gray-600: #3a3a3a !default;
$px-gray-700: #333333 !default;
$px-gray-800: #101010 !default;
$px-gray-900: #080808 !default;

// Body
//
// Settings for the `<body>` element.
$px-body-color: #726f84 !default;
$px-line-height-base: 1.7 !default;
$px-font-weight-base: 400 !default;

// scss-docs-start theme-colors-map
$px-theme-colors: ( "primary": $px-primary, "secondary": $px-secondary, "success": $px-success, "info": $px-info, "warning": $px-warning, "danger": $px-danger, "light": $px-light, "black": $px-black, "dark": $px-dark ) !default;

// fusv-disable
$px-grays: ( "gray-100": $px-gray-100, "gray-200": $px-gray-200, "gray-300": $px-gray-300, "gray-400": $px-gray-400, "gray-500": $px-gray-500, "gray-600": $px-gray-600, "gray-700": $px-gray-700, "gray-800": $px-gray-800, "gray-900": $px-gray-900 ) !default;

// SVG
$px-svg-color: currentColor !default;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$px-min-contrast-ratio: 1.8 !default;

$px-yiq-text-dark: $px-dark;
$px-yiq-text-light: $px-white;
$px-yiq-contrasted-threshold: 173;

//- Added Extra Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 1rem !default;
$spacers: ( 0: 0, 1: math.div($spacer, 4), 2: math.div($spacer, 2), 3: $spacer, 4: $spacer * 1.5, 5: $spacer * 2, 6: $spacer * 2.5, 7: $spacer * 3, 8: $spacer * 3.5, 9: $spacer * 4, 10: $spacer * 5, 11: $spacer * 6, 12: $spacer * 7, ) !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$px-font-family-sans-serif: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", 'Noto Color Emoji' !default;

$px-font-size-base: 0.93rem !default; // Assumes the browser default, typically `16px`
$px-font-size-sm: $px-font-size-base * .875 !default;
$px-font-size-lg: $px-font-size-base * 1.1 !default;

$px-headings-font-weight: 600 !default;
$px-headings-color: $px-dark !default;
$px-headings-color-light: $px-white !default;
$px-headings-font-family: "Poppins", sans-serif !default;
$px-headings-line-height: 1.3 !default;

// scss-docs-start display-headings
// scss-docs-start display-headings
$display-font-sizes: ( 1: 5rem, 2: 4.5rem, 3: 4rem, 4: 3.5rem, 5: 3rem, 6: 2.5rem, 7: 2rem, 8: 1.5rem, ) !default;

$px-display-font-weight: 600 !default;
$px-display-margin-bottom: 1.5rem !default;

$px-lead-font-size: $px-font-size-base * 1.15 !default;
$px-lead-font-weight: 400 !default;

$px-font-weight-lighter: lighter !default;
$px-font-weight-light: 300 !default;
$px-font-weight-normal: 400 !default;
$px-font-weight-semi-bold: 600 !default;
$px-font-weight-bold: 700 !default;
$px-font-weight-bolder: bolder !default;

$px-mark-bg: linear-gradient(120deg,rgba($px-primary,.4) 0,rgba($px-primary,.4) 100%) !default;

// Links
//
// Style anchor elements.
$px-link-decoration: none !default;
$px-link-hover-decoration: null !default;

$px-light-link-color: rgba($px-white, .6) !default;
$px-light-link-color-hover: $px-white !default;
$px-dark-link-color: $px-body-color !default;
$px-dark-link-color-hover: $px-dark !default;

// Navs
$px-nav-link-padding-y: .5rem !default;
$px-nav-tabs-border-color: $px-gray-300 !default;
$px-nav-tabs-link-hover-border-color: transparent transparent $px-primary !default;
$px-nav-tabs-link-active-bg: transparent !default;
$px-nav-tabs-link-active-border-color: transparent transparent $px-primary !default;
$px-nab-tabs-link-color: $px-body-color !default;

$px-nav-link-disabled-color: $px-gray-500 !default;
$px-nav-link-color: #5a5b75 !default;
$px-nav-link-active-color: $px-gray-800 !default;
$px-nav-link-hover-color: $px-gray-800 !default;

// Navbar

$px-navbar-padding-y: 0 !default;
$px-navbar-nav-link-padding-x: .75rem !default;

$px-header-link-font-size: 1rem !default;
$px-header-link-transform: none !default;
$px-header-link-height: 60px !default;
$px-header-font-weight: 500 !default;

// Header Dark Light Color
$px-navbar-dark-color: $px-white !default;
$px-navbar-dark-hover-color: rgba($px-white, .75) !default;
$px-navbar-dark-active-color: rgba($px-white, .75) !default;

$px-navbar-light-color: $px-dark !default;
$px-navbar-light-hover-color: $px-primary !default;
$px-navbar-light-active-color: $px-primary !default;

// Dropdowns
//
// Dropdown menu container and contents.
$px-dropdown-link-color: $px-dark !default;
$px-dropdown-link-hover-color: $px-primary !default;
$px-dropdown-link-hover-bg: transparent !default;

$px-dropdown-link-active-color: $px-primary !default;
$px-dropdown-link-active-bg: transparent !default;

$px-dropdown-item-padding-y: 0.4rem !default;
$px-dropdown-header-color: $px-dark !default;

// Dropdown Size
$px-dropdown-menu-md: 22rem !default;
$px-dropdown-menu-lg: 35rem !default;
$px-dropdown-menu-xl: 42rem !default;

// This variable affects the `.h-*` and `.w-*` classes.
$px-sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$px-sizes: map-merge( ( 5: 5%, 10: 10%, 15: 15%, 25: 25%, 30: 30%, 35: 35%, 40: 40%, 45: 45%, 50: 50%, 55: 55%, 60: 60%, 65: 65%, 75: 75%, 80: 80%, 85: 85%, 90: 90%, 95: 95%, 100: 100%, auto: auto ), $px-sizes );

// Section
$px-section-padding-y: 6.25rem !default;
$px-section-lg-padding-y: 5rem !default;
$px-section-md-padding-y: 3rem !default;

// Section Heading
$px-section-heading-bottom-margin: 2.8125rem !default;

// Components
//
// Define common padding and border radius sizes and more.

$border-width: 1px !default;
$border-widths: ( 0: 0, 1: 1px, 2: 2px, 3: 3px, 4: 4px, 5: 5px, 6: 6, 7: 7px, 8: 8px, 9: 9px, 10: 10px ) !default;

$px-box-shadow-sm: 0 1px 5px 0 rgba($px-black, 0.15) !default;
$px-box-shadow-sm-hover: 0 3px 8px 0 rgba($px-black, 0.15) !default;
$px-box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.05), 0 0.5rem 1.2rem rgba(0, 0, 0, 0.1) !default;
$px-box-shadow-hover: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.05), 0 0.625rem 1.5rem rgba(0, 0, 0, 0.15) !default;
$px-box-shadow-lg: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05), 0 1.5rem 2.2rem rgba(0, 0, 0, 0.1) !default;
$px-box-shadow-lg-hover: 0 0.5rem 1.2rem rgba(0, 0, 0, 0.1), 0 2rem 3rem rgba(0, 0, 0, 0.15) !default;

// Cards
$px-card-spacer-x: 1.75rem !default;
$px-card-spacer-y: 1.75rem !default;
$px-card-border-width: 0 !default;
$px-card-border-color: $px-gray-200 !default;
$px-card-border-radius: .3rem !default;
$px-card-box-shadow: $px-box-shadow-sm !default;
$px-card-cap-bg: transparent !default;
$px-card-cap-padding-y: math.div($px-card-spacer-y, 2) !default;
$px-card-cap-padding-x: $px-card-spacer-x !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$px-enable-negative-margins: true !default;

// Avatar
$px-avatar-width: 3.125rem !default;
$px-avatar-height: $px-avatar-width !default;

$px-avatar-width-xxl: 9rem !default;
$px-avatar-height-xxl: $px-avatar-width-xxl !default;

$px-avatar-width-xl: 6rem !default;
$px-avatar-height-xl: $px-avatar-width-xl !default;

$px-avatar-width-lg: 4rem !default;
$px-avatar-height-lg: $px-avatar-width-lg !default;

$px-avatar-width-sm: 2.4375rem !default;
$px-avatar-height-sm: $px-avatar-width-sm !default;

$px-avatar-font-size: 1rem !default;
$px-avatar-font-size-lg: 1.25rem !default;
$px-avatar-font-size-sm: .75rem !default;

$px-avatar-circle: 50% !default;
$px-avatar-rounded: .375rem !default;
$px-avatar-rounded-sm: .2rem !default;
$px-avatar-rounded-lg: .375rem !default;

$px-avatar-group-margin: -1.25rem !default;
$px-avatar-child-size: 0.9rem !default;
$px-avatar-child-border-color: $px-white !default;
$px-avatar-child-bottom: - math.div($px-avatar-child-size, 2) !default;

// Accordion
$px-accordion-button-focus-border-color: none !default;
$px-accordion-button-focus-box-shadow: none !default;

$px-accordion-button-color: $px-dark !default;

$px-accordion-border-color: $px-gray-200 !default;
$px-accordion-button-active-bg: $px-white !default;
$px-accordion-button-active-color: $px-primary !default;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
$px-btn-font-weight: 500 !default;

$px-input-btn-padding-y: .65rem !default;
$px-input-btn-padding-x: 1.25rem !default;

$px-input-btn-padding-y-sm: .5rem !default;
$px-input-btn-padding-x-sm: 1.25rem !default;
$px-btn-line-height-sm: 1.2 !default;

$px-input-btn-padding-y-lg: 1rem !default;
$px-input-btn-padding-x-lg: 1.875rem !default;
$px-input-btn-font-size-lg: 1rem !default;

$px-btn-font-weight: 500 !default;

$px-input-btn-focus-box-shadow: 0 0 0 0 transparent !default;

// Breadcrumbs
$px-breadcrumb-bg: transparent !default;
$px-breadcrumb-divider-color: $px-gray-600 !default;
$px-breadcrumb-border-color: $px-gray-300 !default;

// List group
$px-list-group-border-color: $px-gray-200 !default;
$px-list-group-item-padding-y: .80rem !default;

// List group
$px-list-group-border-color: $px-gray-200 !default;
$px-list-group-item-font-size: 0.85rem !default;
$px-list-group-active-color: $px-dark !default;
$px-list-group-active-bg: $px-gray-100 !default;
$px-list-group-active-border-color: $px-gray-200 !default;
$px-list-group-disabled-color: $px-gray-500 !default;

// Dropdowns
//
// Dropdown menu container and contents.

$px-dropdown-min-width: 15rem !default;
$px-dropdown-border-width: 0 !default;